import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Container,
  Grid,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import moment from "moment";
import Spinner from "../../../components/src/Spinner.web";
import OfferDetail from "./OfferDetail.web";
import SpecialOfferController, { Props } from "./SpecialOfferController.web";
import WhatsappIcon from "../../../components/src/WhatsappIcon";

const  { baseURL } = require("../../../framework/src/config");

// Customizable Area End

const offerBox={
  boxShadow:"0 3px 6px 0 rgba(0, 0, 0, 0.16)",
  position:"relative",
  display:"flex",
  flexDirection:"column",
  backgroundColor:"#FFF",
  borderBottomLeftRadius:"20px",
  borderBottomRightRadius:"20px",
} as const;

const validBoxStyle={
  backgroundColor:"rgb(21, 24, 23)",
  position:"absolute",
  top:0,
  left:0,
  fontSize:"14px",
  fontWeight:600,
  color:"white",
  padding:"5px 20px",
  borderBottomRightRadius:"20px"
} as const;

const titleStyle = {
  display: "-webkit-box",
  overflow: "hidden",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis"
} as const;

const descriptionStyle={
  height:"45px",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  fontSize:"15px",
  marginBottom:"20px"
} as const;

const CustomButton = styled(Button)({
  fontFamily: 'ITC Avant Garde Gothic Std',
  border:"1.5px solid black",
  borderRadius: "20px",
  fontWeight: "bold",
  textTransform: "none",
  padding:"5px 25px",
});

export default class SpecialOffer extends SpecialOfferController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine Start DefaultContainer
      <div className="special-offer common">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} /> }
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url('./assets/special-offer.png')`}}>
          <div className="content">
            <h2 className="title">{this.state.detail?.title ?? "Special Offers"}</h2>
            <h5 className="sub-title">{this.state.detail?.sub_title ?? "Take Advantage of our Special Offers Before they Expire"}</h5>
          </div>
          <div>
            <Box position="absolute" right={{xs:"20px",sm:"30px",md:"50px"}} bottom={{xs:"20px",sm:"30px",md:"50px"}}>
              <img src={"assets/images/whatapp.png"} className="whatapp-img"/>
            </Box>
          </div>
        </div>
        <div className="second-section">
          <Box width="80%" margin="auto">
              <Box width="100%">
                {this.state.isOfferDetailPage ?
                  <OfferDetail {...this.props} offerDetail={this.state.offerDetail} goBack={this.goBack}/>
                  : <Grid container spacing={5} style={{display:"flex",justifyContent:"center"}}>
                    {window.scrollTo({ top: 0, behavior: "smooth" })}
                      {this.state.specialOfferArr.length !== 0 ? this.state.specialOfferArr.map((item:any,index:number)=>{
                        return <Grid item xs={12} sm={6} lg={4} key={index}>
                          <div style={offerBox}>
                            {item?.attributes?.valid_upto && <div style={validBoxStyle}>Valid Until {moment(item?.attributes?.valid_upto).format("MMMM DD")}</div>}
                            <img src={baseURL + item?.attributes?.special_offer_image} alt="not found" style={{width:"100%",height:"200px",objectFit:"cover"}}/>
                            <Box padding={{xs:"20px 25px",lg:"20px",xl:"25px 35px"}}>
                              <Box fontSize={{xs:"20px",xl:"27px"}} height={{xs:"58px", lg:"82px"}} fontWeight="700" marginBottom="15px" style={titleStyle}>
                                {item?.attributes?.title}
                              </Box>
                              <div style={descriptionStyle}>{item?.attributes?.description}</div>
                              <div style={{display:"flex",justifyContent:"center"}}>
                                <CustomButton onClick={()=> this.setState({ offerDetail: item.attributes, isOfferDetailPage: true })}>Learn More</CustomButton>
                              </div>
                            </Box>
                          </div>
                        </Grid> 
                      })
                      :<div>No Special Offer Found!</div>
                  }
                </Grid>
              }
              </Box>
            </Box>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
