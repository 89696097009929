import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  loading: boolean;
  seconds: number;
  otpError: string;
  isButtonClicked: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordOTP extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resendOtpApiCallId: string = "";
  verifyOtpApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      loading: false,
      seconds: 60,
      otpError: "",
      isButtonClicked: false,
    };
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.verifyOtpApiCallId) {
          if (responseJson != null) {
            if (responseJson && responseJson.data) {
              localStorage.removeItem("pin");
              this.props.navigation.navigate("ConfirmPassword");
            }else if(responseJson && responseJson.errors){
              if(responseJson?.errors[0]?.pin){
                toast.error(responseJson?.errors[0]?.pin);
              }else if(responseJson?.errors[0]?.phone){
                toast.error(responseJson?.errors[0]?.phone);
              }else if(responseJson?.errors[0]?.token){
                toast.error(responseJson?.errors[0]?.token);
              }
              this.setState({ otpError: "*Invalid otp" });
            }
          }
          this.setState({ loading: false });
        }else if(apiRequestCallId === this.resendOtpApiCallId) {
          if (responseJson != null) {
            if (responseJson && responseJson.data) {
                localStorage.setItem("forgot_pass_token",responseJson?.meta?.token);
                toast.info("Otp sent successfully!.");
                let pin = responseJson?.meta?.otp;
                toast.info(`OTP is ${pin}`);
                localStorage.setItem("pin",pin);
                this.setState({ seconds: 60 },()=>this.startTimer());
            }else{
              toast.error("Something want wrong.");
            }
          }
          this.setState({ loading: false });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  timer: any = null;
  async componentDidMount() {
    window.scrollTo(0,0);
    if(!localStorage.getItem("forgot_pass_token")){
        this.props.navigation.navigate("EmailAccountLoginBlock");
    }
    let pin = localStorage.getItem("pin");
    if(pin){
        toast.info(`OTP is ${pin}`);
    }
    this.startTimer();
  }
   
  startTimer = () =>{
    this.timer = setInterval(this.countDown, 1000);
  }

  countDown = () => {
    if (this.state.seconds > 0) {
      this.setState({
        seconds: this.state.seconds - 1,
      });
    }

    if (this.state.seconds == 0) { 
      clearInterval(this.timer);
    }
  };

  changeOtp = (otp:number) => {
    if(otp.toString().length === 0)
      this.setState({ otpError: "*Please enter otp" });
    else if(otp.toString().length < 6)
      this.setState({ otpError: "*Enter valid otp" });
    else
      this.setState({ otpError: "" });

    this.setState({ otp: otp.toString() });
  }

  resendOtp = async () => {
    this.setState({ loading: true, otp: "", otpError:"" });
    
    const header = {
      "Content-Type": configJSON.resendOtpContentType
    };
    
    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          full_phone_number: localStorage.getItem("full_phone_number")
        }
      }
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOtpUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resendOtpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  verifyOtp = (): boolean => {
    
    this.setState({ isButtonClicked: true });

    if(this.state.otp === ""){
      this.setState({ otpError: "*Please enter otp" });
      return false;
    }
    
    if(this.state.otpError)
      return false;
    
    this.setState({ loading: true });
    
    const header = {
      "Content-Type": configJSON.verifyOtpContentType,
      token: localStorage.getItem("forgot_pass_token")
    };
    
    const httpBody = {
        pin: this.state.otp
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOtpUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.verifyOtpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}
