import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  InputBase,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router";
import * as Yup from "yup";
import { Formik } from "formik";
import Select from "react-select";
import Spinner from "../../../components/src/Spinner.web";
const wpIcon = require("../assets/whatapp.png");
// Customizable Area End

import RequestCallbackController,{ Props } from "./RequestCallbackController.web";
// Customizable Area End

const CustomInputBase = styled(InputBase)({
  borderBottom: "1px solid #BBBCBC",
  padding: "0px",
  fontFamily: 'ITC Avant Garde Gothic Std'
});

const RegisterButton = styled(Button)({
  backgroundColor: "black",
  margin: "20px 0px",
  color: "white",
  borderRadius: "20px",
  fontWeight: "bold",
  fontFamily: 'ITC Avant Garde Gothic Std',
  "&:hover": {
    backgroundColor: "black",
  },
});

const timeStyle = {
  borderBottom:"1px solid #BBBCBC",
  paddingBottom:"6px",
  display:"flex",
  justifyContent:"space-between",
  flexWrap:"wrap"
} as const;

const namePrefix = [
  { label: "MR.", value: "mr" },
  { label: "MRs.", value: "mrs" },
];

const phoneCodeData = [
  { label:"+91", value:"+91" },
  { label:"+971", value:"+971" },
];

const languageData = [
  { label: "Arabic", value: 1 },
  { label: "English", value: 2 },
  { label: "Hindi", value: 3 },
  { label: "Malayalam", value: 4 },
  { label: "Urdu", value: 5 },
];

const vehicleTypeData = [
  { label: "Motor cycle", value: 1 },
  { label: "Light Motor Vehicle", value: 2 },
  { label: "Heavy Truck", value: 3 },
  { label: "Mini Bus", value: 4 },
  { label: "Heavy Bus", value: 5 },
  { label: "Fork lift", value: 6 },
  { label: "Shovel", value: 7 },
];

class requestCallback extends RequestCallbackController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  requestCallbackSchema = () => {
    return Yup.object().shape({
      fullName: Yup.string().required("*Full Name is required"),
      phoneNumber: Yup.string().required("*Phone Number is required")
      .min(9,"*Enter valid phone number"),
      language: Yup.string().required("*Language is required"),
      hour: Yup.string().required("*Please select hour"),
      minute: Yup.string().required("*Please select minute"),
      vehicleType: Yup.string().required("*Vehicle Type is required"),
     });
  };

  render() {
    return (
      <div className="callback common common-form">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} /> }
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url('assets/request-callback.png')`}}>
          <div className="content">
            <h2 className="title">{this.state.detail?.title ?? "Request a Call Back"}</h2>
            <h5 className="sub-title">{this.state.detail?.sub_title ?? "Please leave your detail & we will get back to you soon"}</h5>
          </div>
          <Formik
            enableReinitialize={this.state.readData}
            initialValues={{
              fullName: this.state.fullName,
              phoneNumber: this.state.phoneNumber,
              language: this.state.language,
              hour: this.state.hour,
              minute: this.state.minute,
              vehicleType: this.state.vehicleType,
            }}
            validationSchema={this.requestCallbackSchema}
            onSubmit={(values) => {
              this.submitHandler();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                className="form"
              >{this.state.readData && this.setState({ readData: false })}
                <Grid container className="center">
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <div className="box-field">
                        <Select
                          classNamePrefix="react-select"
                          isSearchable={false}
                          options={namePrefix}
                          value={
                            namePrefix.filter(
                              (option: any) =>
                                option.value === this.state.namePrefix
                            )[0]
                          }
                          onChange={(event: any) =>
                            this.setState({ namePrefix: event.value })
                          }
                        />
                        <div style={{ marginLeft: "20px",width:"100%" }}>
                          <CustomInputBase
                            fullWidth
                            placeholder="Full Name*"
                            value={this.state.fullName}
                            onChange={(event: any) =>{
                              setFieldValue("fullName", event.target.value);
                              this.setState({ fullName: event.target.value })
                            }}
                          />
                        </div>
                      </div>
                          {touched.fullName && errors.fullName && 
                            <div className="text-danger">{errors.fullName}</div>
                          }
                    </Box>
                    <Box mb={2}>
                      <div className="box-field">
                        <Select
                          classNamePrefix="react-select"
                          isSearchable={false}
                          options={phoneCodeData}
                          value={
                            phoneCodeData.filter(
                              (option: any) =>
                                option.value === this.state.phoneCode
                            )[0]
                          }
                          onChange={(event: any) => {this.setState({ phoneCode: event.value })}}
                        />
                        <div style={{ marginLeft: "20px",width:"100%" }}>
                          <CustomInputBase
                            fullWidth
                            placeholder="Phone Number*"
                            value={this.state.phoneNumber}
                            onChange={(event: any) =>{
                              const re = /^[0-9\b]+$/;
                              if ((event.target.value === '' || re.test(event.target.value)) && event.target.value.length <= 10) {
                                setFieldValue("phoneNumber", event.target.value);
                                this.setState({ phoneNumber: event.target.value });
                              }
                            }}
                          />
                        </div>
                      </div>
                      {touched.phoneNumber && errors.phoneNumber && 
                        <div className="text-danger">{errors.phoneNumber}</div>
                      }
                    </Box>
                    <Box mb={2}>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Preferred Language"
                        isSearchable={false}
                        options={languageData}
                        value={
                          languageData.filter(
                            (option: any) =>
                              option.value === this.state.language
                          )[0]
                        }
                        onChange={(event: any) =>{
                          setFieldValue("language", event.value);
                          this.setState({ language: event.value })
                        }}
                      />
                      {touched.language && errors.language && 
                        <div className="text-danger">{errors.language}</div>
                      }
                    </Box>
                    <Box mb={2} mt="22px">
                      <Box style={timeStyle}>
                        <span>Preferred Call Time</span>
                        <Box display="flex">
                          <div style={{marginRight:"20px"}}>
                            <select style={{width:"65px"}} 
                              defaultValue=""
                              onChange={(event:any)=>{
                                setFieldValue("hour",event.target.value);
                                this.setState({ hour: event.target.value });
                            }}>
                              <option value="" disabled>Hour</option>
                              <option value="09">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="01">01</option>
                              <option value="02">02</option>
                              <option value="03">03</option>
                              <option value="04">04</option>
                            </select>
                          </div>
                          <div>
                          <select
                            defaultValue=""
                            onChange={(event:any)=>{
                              setFieldValue("minute",event.target.value);
                              this.setState({ minute: event.target.value });
                            }}>
                              <option value="" disabled>Minutes</option>
                              <option value="00">00</option>
                              <option value="15">15</option>
                              <option value="30">30</option>
                              <option value="45">45</option>
                            </select>
                          </div>
                        </Box>
                      </Box>
                      {touched.hour && touched.minute && errors.hour && errors.minute
                        ?<div className="text-danger">*Please select time</div>
                        :touched.hour && touched.minute && (errors.hour || errors.minute) && <div className="text-danger">{errors.hour ? errors.hour : errors.minute}</div>
                      }
                    </Box>
                    <Box mb={2}>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Vehicle Type"
                        isSearchable={false}
                        options={this.state.vehicleTypeData}
                        value={
                          this.state.vehicleTypeData.filter(
                            (option: any) =>
                              option.value === this.state.vehicleType
                          )[0]
                        }
                        onChange={(event: any) =>{
                          setFieldValue("vehicleType", event.value);
                          this.setState({ vehicleType: event.value })
                        }}
                      />
                      {touched.vehicleType && errors.vehicleType && 
                        <div className="text-danger">{errors.vehicleType}</div>
                      }
                    </Box>
                    <RegisterButton type="submit" fullWidth>
                      Register Now
                    </RegisterButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Box position="absolute" right={{xs:"20px",md:"30px"}} bottom={{xs:"20px",md:"30px"}}>
            <img src={wpIcon} className="whatapp-img" />
          </Box>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
export default withRouter(requestCallback);
// Customizable Area End
