import moment from "moment";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location:any;
  history:any;
  match:any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  detail:any;
  namePrefix:any;
  fullName: any;
  phoneCode:any;
  phoneNumber: string;
  email:string;
  purpose:string;
  otherPurpose:string;
  date:any;
  readData: boolean;
  loading: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BookAppointmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  bookAppointmentApiCallId: string= "";
  getDetailApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      detail:"",
      namePrefix:"mr",
      fullName: "",
      phoneCode: "+971",
      phoneNumber: "",
      email:"",
      purpose:"File Open/Registration",
      otherPurpose:"",
      date:new Date(),
      readData: false,
      loading: false,
     // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    window.scrollTo(0,0);
    this.getDetails();
    let data = this.props.location.state;
    if(data) {
      this.setState({ phoneCode: data?.code, phoneNumber: data?.phoneNumber, readData:true });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getDetailApiCallId) {
          this.setState({ detail: responseJson?.data?.attributes });
        }else if(apiRequestCallId === this.bookAppointmentApiCallId){
          if (!responseJson.errors) {
            toast.info("Appointment booked successfully!");
          }else{
            toast.error("Something went wrong");
          }
        }
        this.setState({ loading: false });
      }else {
        this.setState({ loading: false });
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start

  submitHandler = (): boolean => {
    toast.info("Coming Soon!");
    return false;
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.bookAppointmentApiContentType,
    };

    const attrs = {
      full_name: this.state.namePrefix + ". " + this.state.fullName,
      full_phone_number: this.state.phoneCode + this.state.phoneNumber,
      email:this.state.email ? this.state.email: undefined,
      purpose: this.state.purpose !== "Other" ? this.state.purpose : undefined,
      other_purpose: this.state.purpose === "Other"? this.state.otherPurpose : undefined,
      date: moment(this.state.date).format("DD-MM-YYYY")
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bookAppointmentApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bookAppointmentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookAppointmentAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getDetails = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_dashboard/page_titles/:id?name=Book_an_appointment"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

}
