import React from "react";
// Customizable Area Start
import moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Select from "react-select";
export const configJSONUrl = require("../../../framework/src/config");
import '../../../web/public/index.css';
import WhatsappIcon from "../../../components/src/WhatsappIcon";
let authToken: any;
export const baseurl = require('../../../../packages/framework/src/config');
// Customizable Area End

import SchedulingController, {
  Props,
  // Customizable Area Start
  // Customizable Area End
} from "./SchedulingController";
import Spinner from "../../../components/src/Spinner.web";


export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    authToken = localStorage.getItem('authToken');
    this.fetchSpecialOffers()
    this.fetchVehiclesData()
    this.getCmsApi()
    this.getCmsApiDoorStep()
    // this.getBackgroundColor()
  }


  fetchSpecialOffers = () => {
    this.setState({ isLoading: true });
    fetch(configJSONUrl.baseURL + "/bx_block_special_offer/special_offers")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong, Please try again');
      })
      .then((json) => {

        if (json.data.length === 0) {
          this.setState({
            isSpecialOffers: false,
          });

        }
        this.setState({
          specialOffers: json.data,
          isLoading: false
        });
      }).catch((error) => {

        this.setState({
          err2: error,
          isLoading: false

        })
      })

  };

  fetchVehiclesData = () => {
    this.setState({ isLoading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",

      }

    }
    fetch(configJSONUrl.baseURL + "/vehicle_types", requestOptions)
      .then((res => {

        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong, Please try again.');
      }))
      .then((json) => {
        this.setState({
          vehicleTypes: json.data,
          isLoading: false
        })

      }).catch((error) => {

        this.setState({
          err: error,
          isLoading: false

        })
      })
  }

  // SwitchCase(props) {

  //   switch (props.value) {

  //     case 'Admin':

  //       return 'You are a Admin.';

  //     case 'Manager':

  //       return 'You are a Manager.';

  //     default:

  //       return 'You are a User';

  //   }

  // }
  getBackgroundImage(id: any) {
    switch (id) {
      case '1':
        return { backgroundImage: "url('assets/images/Car.png')" };

      case '2':
        return { backgroundImage: "url('assets/images/Bike.png')", };

      case '3':
        return { backgroundImage: "url('assets/images/Truck.png')" };

      case '4':
        return { backgroundImage: "url('assets/images/bus.png')" };

      case '5':
        return { backgroundImage: "url('assets/images/fork_lift.png')" };

      case '7':
        return { backgroundImage: "url('assets/images/fork_lift.png')" };
    }
  }



  getIcon(id: any) {
    switch (id) {
      case '1':
        return 'assets/icons/car.png';

      case '2':
        return "assets/icons/bike.png";

      case '3':
        return "assets/icons/truck.png";

      case '4':
        return "assets/icons/bus.png";

      case '5':
        return "assets/icons/fork.png";

      case '7':
        return "assets/icons/fork.png";
    }
  }
  getCssForButton(id: any) {
    switch (id) {

      case '2':
        return { background: "#FFFFFF", color: "rgb(2, 2, 2)" };

      case '3':
        return { background: "#FFFFFF", color: "rgb(2, 2, 2)" };
      case '4':
        return { background: "#000", color: "#fff" };

    }
  }

  getCssForName(id: any) {
    switch (id) {

      case '2':
        return { color: "#FFFFFF" };

      case '3':
        return {
          color: "#FFF"
        }
      case '4':
        return { color: "#000" };

    }
  }

  handleSelect = (e: any) => {
    this.setState({ countryCodeWeb: e.target.value });
  }

  handleChange = (event: any) => {
    let val = event.target.value.replace(/[^0-9]+/g, "");
    this.setState({ phoneNumber: val });

    let length = val.split('').length
    if (length === 9) {
      this.setState({ enableSave: true })
    }
    else {
      this.setState({ enableSave: false })
    }
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    let { err, isLoading } = this.state;
    // const { serviceProviderSchedule } = this.state;
    let countryCode = [
      { label: "+971", value: "+971" },
      { label: "+91", value: "+91" },
    ];
    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      <CommonWrapper>
        <BannerSection style={{ backgroundImage: this.state.CmsData.image ? `url(${this.state.CmsData.image})` : `url("assets/images/home-bg.png")` }} >
          <Container>
            <div style={{ margin: "0px", display: "flex" }} >
              <div className="col-sm-6">
                <div className="banner-heads">
                  <h2>{this.state.CmsData.title ? this.state.CmsData.title : " Learn to Drive with Confidence"} </h2>
                  <p>{this.state.CmsData.sub_title ? this.state.CmsData.sub_title : "Eco drive - The driving institute."}</p>
                </div>
              </div>
            </div>
          </Container>
          <WhatsappIcon />
        </BannerSection>
        <section className="mt80">
          <Container>
            {isLoading && <Spinner spinnerModal={isLoading} />}
            {err ? <h1 style={{ textAlign: "center", fontSize: "22px" }}>{err.message}</h1> :
              <div className="row" >
                <div className="col-sm-12">
                  <div className="vehicle-cards-wrapper">
                    {this.state.vehicleTypes.map((data: any, index: any) => (
                      <div className="vehicle-cards" key={index} style={this.getBackgroundImage(data.id)}>
                        <div className="vehicle-contents">

                          <img className="" src={this.getIcon(data.id)} alt="" />

                          <h4 style={this.getCssForName(data.id)}>{data.attributes.name}</h4>
                          <Link style={this.getCssForButton(data.id)} to="/CourseDetail">Learn more</Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
          </Container>
        </section>
        <section className="mt80">
          <Container className="bg-white">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <img
                  className="register-image img-fluid"
                  src={this.state.CmsDataDoorStep.image ? this.state.CmsDataDoorStep.image : "assets/images/register.png"}
                  alt=""
                />
              </div>
              <div className="col-sm-12 col-md-6 m-auto text-center">
                <div className="register-wrapper">
                  <Select
                    classNamePrefix="react-select"
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        width:"68px !important",
                        height:"43px",
                        borderBottom:"2px solid black !important"
                      }),
                    }}
                    isSearchable={false}
                    options={countryCode}
                    value={
                      countryCode.filter(
                        (option: any) =>
                          option.value === this.state.countryCodeWeb
                        )[0]
                    }
                    onChange={(event: any) => {
                      this.setState({ countryCodeWeb: event.value })
                    }}
                  />
                  {/* <select name="countryCode" className="input_selector" onChange={this.handleSelect} >
                    {countryCode.map((data: any, index: any) => (
                      <option value={data.label} selected={data.label === this.state.countryCodeWeb}>{data.value}</option>
                    ))}
                  </select> */}
                  <div>
                    <input type="text" value={this.state.phoneNumber} onChange={this.handleChange} maxLength={9} placeholder="Phone Number*" style={{height:"100%"}}/>
                  </div>
                </div>

                <Link to={{
                  pathname: "/Appointments",
                  state: { code: this.state.countryCodeWeb, phoneNumber: this.state.phoneNumber }
                }}><button style={{ opacity: this.state.enableSave ? '1' : '0.5' }} className="register-wrapper-btn" disabled={this.state.enableSave ? false : true}>  Register at Your Doorstep</button></Link>
              </div>
            </div>
          </Container>
        </section>
        <section className="mt80">
          <div className="commonHead">
            <h3>SPECIAL OFFERS</h3>
          </div>
          {this.state.isSpecialOffers ?
            <Container>
              {this.state.isLoading && <Spinner spinnerModal={this.state.isLoading} />}
              {this.state.err2 ? <h1 style={{ textAlign: "center", fontSize: "20px" }}>{err.message}</h1> :

                <div className="row">

                  {this.state.specialOffers.map((item: any, index: any) => (
                    <div className="col-sm-6 col-lg-4" key={index}>
                      <Link className="special-offer-link" to="/SpecialOffer">
                        <div className="special-offers-card">
                          <img src={baseurl.baseURL + item.attributes.special_offer_image} alt="" />
                          <Link to="#">Valid Until {moment(item.attributes.valid_upto).format("MMMM DD")}</Link>
                          <div className="offer-titles-card">
                            <h4>{item.attributes.title} </h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              }
            </Container>
            : <h1 style={{ textAlign: "center", fontSize: "18px" }}>No Special Offer Found!</h1>
          }
        </section>
        <section className="mt80">
          <div className="commonHead">
            <h3>WHY CHOOSE ECO DRIVE?</h3>
          </div>
          <div className="imageBg">
            <img src="assets/images/whyEcoDrive.png" alt="" />
          </div>
        </section>
      </CommonWrapper>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const CommonWrapper = styled.main``;
const BannerSection = styled.section`
  background-size: cover;
  width: 100%;
  min-height: 786px;
  position: relative;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Container = styled.div`
  width: 80%;
  margin: auto;
`;




function vehicleTypes(vehicleTypes: any): string | undefined {
  throw new Error("Function not implemented.");
}
// Customizable Area End
