import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  countryCodeSelected: any;
  token: any;
  phoneValue: string;
  phoneError: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotOtpApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,
      countryCodeSelected: "+971",
      token: "",
      phoneValue: "",
      phoneError: "",
     };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    window.scrollTo(0,0);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
    }else if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.forgotOtpApiCallID &&
        this.forgotOtpApiCallID ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (responseJson && !responseJson.errors) {
            localStorage.setItem("forgot_pass_token", responseJson?.meta?.token);
            localStorage.setItem("full_phone_number", this.state.countryCodeSelected + this.state.phoneValue);
            localStorage.setItem("pin", responseJson?.data?.attributes?.pin);
            this.props.navigation.navigate("ForgotPasswordOTP");
        }else if(responseJson?.errors[0]?.otp){
            this.setState({ phoneError: responseJson.errors[0]?.otp });
        }
        this.setState({ loading: false });
    }
  }
  
  forgotPassword = (): boolean => {

    if(this.state.phoneError)
     return false;

    this.setState({ loading: true });
    
    const header = {
      "Content-Type": "application/json",
    };

    const attrs = {
      full_phone_number: this.state.countryCodeSelected + this.state.phoneValue,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };
   
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.forgotOtpApiCallID = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordOtpApiUrl
      );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.forgotPasswordMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}
