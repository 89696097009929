import React from "react";
// Customizable Area Start
import {
  Box,
  Container,
} from "@material-ui/core";
import Spinner from "../../../components/src/Spinner.web";
import PrivacyPolicyController, { Props } from "./PrivacyPolicyController.web";

const  { baseURL } = require("../../../framework/src/config");
// Customizable Area End

export default class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine Start DefaultContainer
      <div className="privacy-policy common">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} /> }
        <div className="first-section pb70" style={{ backgroundImage: this.state.detail?.privacy_background_image ? `url(${baseURL + this.state.detail?.privacy_background_image})` : `url('./assets/privacy.png')`}}>
          <div className="content">
            <h2 className="title">{this.state.detail?.heading ?? "Privacy Policy"}</h2>
            <h5 className="sub-title">{this.state.detail?.sub_heading ?? "A privacy policy is a statement or legal document"}</h5>
          </div>
          <Box position="absolute" right={{xs:"20px",sm:"30px",md:"50px"}} bottom={{xs:"20px",sm:"30px",md:"50px"}}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img"/>
          </Box>
        </div>
        <div className="second-section">
          <Box width="80%" margin="auto">
            <Box width="100%">
              <Box id="content"></Box>
            </Box>
          </Box>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
