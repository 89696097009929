import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
}


export interface S {
    // Customizable Area Start
    loading: boolean;
    data: string;
    documentArr: any[]
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LecturesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            data: "",
            documentArr: [],
            // Customizable Area End
        };
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson && responseJson.data) {
                if (apiRequestCallId === this.paymentMethodApiCallId) {
                    console.log(responseJson, "responseJson")
                    window.open(responseJson.data.attributes.sand_box_url)
                }
            } else {
                this.parseApiErrorResponse(responseJson);
            }
            this.setState({ loading: false });
        }
        this.parseApiCatchErrorResponse(errorReponse);
        // Customizable Area End
    }

    // Customizable Area Start
    paymentMethodApiCallId: string = "";
    successPaymentAPiCallId: string = ""

    BtnClikc = async (data: string) => {
        if (this.state.data === "card") {
            this.getDocument()
        }
        else if (this.state.data === "center") {
            toast.success("Go To Nearest Eco Drive Center");
        }
        else {
            toast.error("Select Any One Payment Method");
        }
    }

    getDocument = () => {
        this.setState({ loading: true });
        const localToken = localStorage.getItem("authToken") as string
        const header = {
            "Content-Type": configJSON.contentTypeApiDocument,
            token: localToken
        };
        const amout = localStorage.getItem("amount") as any
        const id = localStorage.getItem("id") as any
        const httpBody = {
            "amount": amout,
            "course_id": id,
            "currency": "AED",
            "is_full_payment": true,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.paymentMethodApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_payments/payments"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }


    downloadDocument = (e: any, name: string, url: string) => {
        e.stopPropagation();
        let link = document.createElement("a");
        link.href = url;
        link.download = name;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Customizable Area End
}