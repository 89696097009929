import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import OfferDetailController, { Props } from "./OfferDetailController.web";

const  { baseURL } = require("../../../framework/src/config");

// Customizable Area End

const RegisterButton = styled(Button)({
  backgroundColor:"#FFF",
  borderRadius: "20px",
  fontWeight: "bold",
  padding:"5px 25px",
  "&:hover":{
    backgroundColor:"#FFF"   
  }
});

const CustomButton = styled(Button)({
  border:"1.5px solid black",
  borderRadius: "20px",
  textTransform: "none",
  padding:"7px 25px"
});
  
export default class OfferDetail extends OfferDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine Start DefaultContainer
      <div className="offer-detail common">
        <Box marginBottom={{xs:"20px",md:"45px"}}>
          <h4 style={{fontWeight:"bold"}}>{this.props.offerDetail?.title}</h4>
            <p>{this.props.offerDetail?.description}</p>
        </Box>
        <Box marginBottom={{xs:"20px",md:"45px"}}>
          {this.props.offerDetail?.steps_to_follow?.split("\n").map((item:string, index:number)=>
            <>
              {index === 0 && <h4 style={{fontWeight:"bold",marginBottom:"10px"}}>{item}</h4>}
              {index !== 0 && <p style={{marginBottom:"10px"}}>{item}</p>}
            </>
          )}
        </Box>
          <Box position="relative" marginBottom={{xs:"20px",md:"40px"}}>
            <Box height={{xs:"200px",sm:"300px",lg:"400px"}}>
              <img src={baseURL + this.props.offerDetail?.special_offer_image} style={{width:"100%", height:"100%"}}/>
            <Box color="#FFF" position="absolute" bottom={{xs:30,sm:60,lg:100}} left={{xs:20,sm:35}}>
              <h3 style={{fontWeight:"bold"}}>Offer Ends Soon!</h3>
                {this.props.offerDetail?.valid_upto && <p>Register Now before {moment(this.props.offerDetail?.valid_upto).format("MMMM Do")}</p>}
                  <RegisterButton onClick={()=>this.props.navigation.navigate("EmailAccountRegistration")}>
                    Register Now
                  </RegisterButton>
            </Box>
          </Box>
          </Box>
          <Box marginBottom={{xs:"20px",md:"40px"}}>
            {this.props.offerDetail?.terms_and_conditions?.split("\n").map((item:string, index:number)=>
              <>
                {index === 0 && <h4 style={{fontWeight:"bold",marginBottom:"10px"}}>{item}</h4>}
                {index !== 0 && <p style={{marginBottom:"10px"}}>{item}</p>}
              </>
            )}
          </Box>
          <div>
            <CustomButton onClick={()=>this.props.goBack()}>
              <ArrowBackIcon style={{height:"13px",width:"auto"}}/>
                Go Back to Special Offers
              </CustomButton>
          </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
