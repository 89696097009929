Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
exports.placeHolderEmail = "Email";
exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
  
exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";
  
exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
  
exports.errorTitle = "Error";
  
exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
  
exports.contentTypeApiAddDetail = "application/json";
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contentTypeApiSpecialOffer = "application/json";
exports.apiMethodTypeSpecialOffer ="GET";
  
exports.contentTypeApiOfferDetail = "application/json";
exports.apiMethodTypeOfferDetail = "GET";

exports.contentTypeApiDocument = "application/json";
exports.apiMethodTypeDocument = "GET";
