import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";

//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to EcoDriveBusinessAppM!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'Location'}  onPress={() => navigation.navigate("Location")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Maps'}  onPress={() => navigation.navigate("Maps")} />
<CustomTextItem content={'Scheduling'}  onPress={() => navigation.navigate("Scheduling")} />
<CustomTextItem content={'promocodes'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'SocialMediaAccountRegistrationScreen'}  onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
<CustomTextItem content={'social-media-account'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'SocialMediaAccountLoginScreen'}  onPress={() => navigation.navigate("SocialMediaAccountLoginScreen")} />
<CustomTextItem content={'Pushnotifications'}  onPress={() => navigation.navigate("Pushnotifications")} />
<CustomTextItem content={'Appointments'}  onPress={() => navigation.navigate("Appointments")} />
<CustomTextItem content={'MobileAccountLoginBlock'}  onPress={() => navigation.navigate("MobileAccountLoginBlock")} />
<CustomTextItem content={'Dashboard'}  onPress={() => navigation.navigate("Dashboard")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Contactus'}  onPress={() => navigation.navigate("Contactus")} />
<CustomTextItem content={'UserProfileBasicBlock'}  onPress={() => navigation.navigate("UserProfileBasicBlock")} />
<CustomTextItem content={'Analytics'}  onPress={() => navigation.navigate("Analytics")} />
<CustomTextItem content={'Filteritems'}  onPress={() => navigation.navigate("Filteritems")} />
<CustomTextItem content={'Videos'}  onPress={() => navigation.navigate("Videos")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'VisualAnalytics'}  onPress={() => navigation.navigate("VisualAnalytics")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'Couponcodegenerator'}  onPress={() => navigation.navigate("Couponcodegenerator")} />
<CustomTextItem content={'Customform'}  onPress={() => navigation.navigate("Customform")} />
<CustomTextItem content={'Addresses'}  onPress={() => navigation.navigate("Addresses")} />
<CustomTextItem content={'Notifications'}  onPress={() => navigation.navigate("Notifications")} />
<CustomTextItem content={'CfBusinessRules'}  onPress={() => navigation.navigate("CfBusinessRules")} />
<CustomTextItem content={'FeedbackCollection'}  onPress={() => navigation.navigate("FeedbackCollection")} />
<CustomTextItem content={'Payments'}  onPress={() => navigation.navigate("Payments")} />
<CustomTextItem content={'ProjecttaskTracking2'}  onPress={() => navigation.navigate("ProjecttaskTracking2")} />
<CustomTextItem content={'BulkUploading'}  onPress={() => navigation.navigate("BulkUploading")} />
<CustomTextItem content={'Scoring'}  onPress={() => navigation.navigate("Scoring")} />
<CustomTextItem content={'ItemAvailability'}  onPress={() => navigation.navigate("ItemAvailability")} />
<CustomTextItem content={'ProjectTemplates'}  onPress={() => navigation.navigate("ProjectTemplates")} />
<CustomTextItem content={'QuestionBank'}  onPress={() => navigation.navigate("QuestionBank")} />
<CustomTextItem content={'LanguageOptions'}  onPress={() => navigation.navigate("LanguageOptions")} />
<CustomTextItem content={'LanguageSupport'}  onPress={() => navigation.navigate("LanguageSupport")} />
<CustomTextItem content={'Library2'}  onPress={() => navigation.navigate("Library2")} />
<CustomTextItem content={'Referrals'}  onPress={() => navigation.navigate("Referrals")} />
<CustomTextItem content={'SplitPayments'}  onPress={() => navigation.navigate("SplitPayments")} />
<CustomTextItem content={'ReviewAndApproval'}  onPress={() => navigation.navigate("ReviewAndApproval")} />
<CustomTextItem content={'UserGroups'}  onPress={() => navigation.navigate("UserGroups")} />
<CustomTextItem content={'OpenApi'}  onPress={() => navigation.navigate("OpenApi")} />
<CustomTextItem content={'ServicespecificSettingsadmin'}  onPress={() => navigation.navigate("ServicespecificSettingsadmin")} />
<CustomTextItem content={'AdminConsole3'}  onPress={() => navigation.navigate("AdminConsole3")} />
<CustomTextItem content={'Leaderboard'}  onPress={() => navigation.navigate("Leaderboard")} />
<CustomTextItem content={'AcceptPrepayments2'}  onPress={() => navigation.navigate("AcceptPrepayments2")} />
<CustomTextItem content={'LoyaltySystem2'}  onPress={() => navigation.navigate("LoyaltySystem2")} />
<CustomTextItem content={'FullTeamVisibility2'}  onPress={() => navigation.navigate("FullTeamVisibility2")} />
<CustomTextItem content={'RolesPermissions2'}  onPress={() => navigation.navigate("RolesPermissions2")} />
<CustomTextItem content={'UploadMedia2'}  onPress={() => navigation.navigate("UploadMedia2")} />
<CustomTextItem content={'PledgeTracking'}  onPress={() => navigation.navigate("PledgeTracking")} />
<CustomTextItem content={'PeopleManagement2'}  onPress={() => navigation.navigate("PeopleManagement2")} />
<CustomTextItem content={'AutomaticCheckoutCalculation2'}  onPress={() => navigation.navigate("AutomaticCheckoutCalculation2")} />
<CustomTextItem content={'VideoLibrary'}  onPress={() => navigation.navigate("VideoLibrary")} />
<CustomTextItem content={'FormApprovalWorkflow'}  onPress={() => navigation.navigate("FormApprovalWorkflow")} />
<CustomTextItem content={'Documentation'}  onPress={() => navigation.navigate("Documentation")} />
<CustomTextItem content={'EmailNotifications'}  onPress={() => navigation.navigate("EmailNotifications")} />
<CustomTextItem content={'ShareCalendar'}  onPress={() => navigation.navigate("ShareCalendar")} />
<CustomTextItem content={'RealtimeUpdates'}  onPress={() => navigation.navigate("RealtimeUpdates")} />
<CustomTextItem content={'HamburgerMenu'}  onPress={() => navigation.navigate("HamburgerMenu")} />
<CustomTextItem content={'StoreCredits'}  onPress={() => navigation.navigate("StoreCredits")} />
<CustomTextItem content={'TaskAllocator'}  onPress={() => navigation.navigate("TaskAllocator")} />
<CustomTextItem content={'TaskGroups'}  onPress={() => navigation.navigate("TaskGroups")} />
<CustomTextItem content={'TaskList'}  onPress={() => navigation.navigate("TaskList")} />
<CustomTextItem content={'TimesheetManagement'}  onPress={() => navigation.navigate("TimesheetManagement")} />
<CustomTextItem content={'AdvancedSearch'}  onPress={() => navigation.navigate("AdvancedSearch")} />
<CustomTextItem content={'AssessmentTest'}  onPress={() => navigation.navigate("AssessmentTest")} />
<CustomTextItem content={'CertificationTracking'}  onPress={() => navigation.navigate("CertificationTracking")} />
<CustomTextItem content={'ContentManagement'}  onPress={() => navigation.navigate("ContentManagement")} />
<CustomTextItem content={'CustomisableUserProfiles'}  onPress={() => navigation.navigate("CustomisableUserProfiles")} />
<CustomTextItem content={'DashboardGuests'}  onPress={() => navigation.navigate("DashboardGuests")} />
<CustomTextItem content={'PaymentAdmin2'}  onPress={() => navigation.navigate("PaymentAdmin2")} />
<CustomTextItem content={'TeachersDashboard8'}  onPress={() => navigation.navigate("TeachersDashboard8")} />
<CustomTextItem content={'CfLogicMappingAlgorithm'}  onPress={() => navigation.navigate("CfLogicMappingAlgorithm")} />
<CustomTextItem content={'CfCustomizedVouchersCreation'}  onPress={() => navigation.navigate("CfCustomizedVouchersCreation")} />
<CustomTextItem content={'CfCardsSpecificDiscounts2'}  onPress={() => navigation.navigate("CfCardsSpecificDiscounts2")} />
<CustomTextItem content={'CfCommissionManagement2'}  onPress={() => navigation.navigate("CfCommissionManagement2")} />
<CustomTextItem content={'CfRtaIntegration2'}  onPress={() => navigation.navigate("CfRtaIntegration2")} />
<CustomTextItem content={'CfEid'}  onPress={() => navigation.navigate("CfEid")} />
<CustomTextItem content={'CollectTransactionFees'}  onPress={() => navigation.navigate("CollectTransactionFees")} />
<CustomTextItem content={'AccountTransfer'}  onPress={() => navigation.navigate("AccountTransfer")} />
<CustomTextItem content={'Playlist4'}  onPress={() => navigation.navigate("Playlist4")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;