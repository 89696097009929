import React from "react";
// Customizable Area Start
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { HiArrowCircleRight } from "react-icons/hi";
import PaymentsController, { Props } from "./PaymentsController";
import Spinner from "../../../components/src/Spinner.web";
const { baseURL } = require("../../../framework/src/config");
import "./Payments.css";

let courseId: string | null;
let authToken: any;

// Customizable Area End
export default class Payments extends PaymentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getCMSDetails();
    courseId = localStorage.getItem('id');
    let courseName = localStorage.getItem('name');
    authToken = localStorage.getItem('authToken');
    let paymentData = JSON.parse(localStorage.getItem("myObject") || '{}');
    this.setState({
      courseName: courseName,
      fullNetAmount: Number(paymentData.Fees),
      youSaved: paymentData.Save,
    },
      () => this.fetchFullPaymentDetail());
  }

  fetchFullPaymentDetail = () => {
    this.setState({ isLoading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "token": authToken
      }
    }

    fetch(baseURL + `/bx_block_splitpayments/full_payment?subscription_plan_id=${courseId}&promo_code=DEMO`, requestOptions)
      .then(((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong, Please try again.');
      }))
      .then((json) => {
        let discount = json.discount;
        let netAmount = this.state.courseName === "Regular" || this.state.courseName === "Flexi" ? json?.full_payment?.reg_total_amount : json?.full_payment_after_vat;
        let vatAmount = this.state.courseName === "Regular" || this.state.courseName === "Flexi" ? json?.full_payment?.reg_vat_on_eco : json?.vat;
        let payableAmount = this.state.courseName === "Regular" || this.state.courseName === "Flexi" ? json?.full_payment?.total_amount_after_vat : json?.amount_payable;

        this.setState({
          youSaved: discount,
          fullNetAmount: Number(netAmount),
          fullVatAmount: Number(vatAmount),
          totalPayableAmount: Number(payableAmount),
          isLoading: false,
        });
      }).catch((error) => {
        this.setState({
          err: error,
          isLoading: false
        })
      });
  }

  fetchPartialPaymentDetail = () => {
    this.setState({ isLoading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "token": authToken
      }
    }

    fetch(baseURL + `/bx_block_splitpayments/partial_payment?subscription_plan_id=${courseId}`, requestOptions).then(((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('Something went wrong, Please try again.');
    }))
      .then((json) => {
        let data = Object.entries(json).map((item: any, index: number) => {
          let optionName = item[0].replace(/_/g, " ");
          let amount = this.state.courseName === "Regular" || this.state.courseName === "Flexi" ? item[1].reg_total_amount : item[1];
          let vat = this.state.courseName === "Regular" || this.state.courseName === "Flexi" ? item[1].reg_vat_on_eco : item[1];
          let total = this.state.courseName === "Regular" || this.state.courseName === "Flexi" ? item[1].total_amount_after_vat : item[1];

          return {
            id: index,
            name: optionName,
            amount: Number(amount),
            vat: Number(vat),
            total: Number(total),
            isChecked: false,
          }
        });

        this.setState({
          programCategory: data,
          isLoading: false,
        });

      }).catch((error) => {
        this.setState({
          err: error,
          isLoading: false
        });
      })
  }

  fetchPayableAmount = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "token": authToken
      }
    }

    let checkedCount = 0;
    let url = `/bx_block_splitpayments/amount_payable?subscription_plan_id=${courseId}`;
    this.state.programCategory.map((item: any) => {
      if (item.isChecked) {
        checkedCount += 1;
        url += `&program_category_id[]=${item.id + 1}`;
      }
    });

    if (checkedCount === 0) {
      this.setState({
        totalPayableAmount: 0,
        partialNetAmount: 0,
        partialVatAmount: 0,
      });
      return;
    }

    fetch(baseURL + url, requestOptions).then(((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('Something went wrong, Please try again.');
    }))
      .then((json) => {
        this.setState({
          partialNetAmount: Number(json.amount),
          partialVatAmount: Number(json.vat),
          totalPayableAmount: Number(json.amount_payable),
        })
      }).catch((error) => {
        this.setState({
          err: error,
        })
      })
  }

  changePaymentOption = (option: string) => {
    this.setState({ paymentOption: option });

    if (option === "fullPayment") {
      this.setState({ totalPayableAmount: this.state.fullNetAmount + this.state.fullVatAmount });
    } else {
      this.setState({ totalPayableAmount: this.state.partialNetAmount + this.state.partialVatAmount });
    }

    if (option !== "fullPayment" && this.state.programCategory.length === 0) {
      this.fetchPartialPaymentDetail();
    }
  }

  partialCheckBoxChange = (data: any) => {
    let category = [...this.state.programCategory];
    let netAmount = this.state.partialNetAmount;
    let vatAmount = this.state.partialVatAmount;
    let courseName = this.state.courseName;

    if (data.isChecked) {
      category = category.map((item: any) => {
        if (item.id >= data.id && item.isChecked) {
          if (courseName === "Regular" || courseName === "Flexi") {
            netAmount -= item.amount;
            vatAmount -= item.vat;
          }
          return { ...item, isChecked: false };
        }
        return item;
      });
    } else {
      for (let i = 0; i < category.length; i++) {
        if (category[i].id < data.id && !category[i].isChecked) {
          return;
        }
      }
      category[data.id].isChecked = true;
      if (courseName === "Regular" || courseName === "Flexi") {
        netAmount += category[data.id].amount;
        vatAmount += category[data.id].vat;
      }
    }
    this.setState({
      programCategory: category,
      partialNetAmount: netAmount,
      partialVatAmount: vatAmount,
    }, () => {
      if (courseName === "Lumpsum" || courseName === "VIP") {
        this.fetchPayableAmount();
      } else {
        this.setState({ totalPayableAmount: netAmount + vatAmount });
      }
    });
  }

  nextPageNavigation = () => {
    let vat = this.state.fullVatAmount.toFixed(2);
    let net = this.state.fullNetAmount.toFixed(2);
    let count = 0;
    if(this.state.paymentOption === "partialPayment"){
      vat = this.state.partialVatAmount.toFixed(2);
      net = this.state.partialNetAmount.toFixed(2);
      count = 0;
      this.state.programCategory.map((item: any) => {
        if (item.isChecked) {
          count++;
        }
      });;
    }
    
    localStorage.setItem("vat", vat);
    localStorage.setItem("net", net);
    localStorage.setItem("count",count.toString());
    localStorage.setItem("amount", this.state.totalPayableAmount.toFixed(2));
    this.props.navigation.navigate("PaymentsMethod");
  }

  getCMSDetails = () => {
    this.setState({ isLoading: true });
    const fetchUrl: any = `${baseURL}/bx_block_dashboard/page_titles/:id?name=Payments`;

    fetch(fetchUrl)
      .then((response) => response.json())
      .then((ResponseJson) => {
        this.setState({
          detail: ResponseJson?.data?.attributes,
          isLoading: false
        });
      }).catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  // Customizable Area End

  render() {
    let { err } = this.state;
    return (
      // Customizable Area Start
      <CommonWrapper className="document common">
        {this.state.isLoading && <Spinner spinnerModal={this.state.isLoading} />}
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url('assets/images/vehicle_detail.png')` }}>
          <div>
            <div className="content">
              <h2 className="title">{this.state.courseName}</h2>
              <h5 className="sub-title">{this.state.fullNetAmount.toFixed(2)} AED</h5>
            </div>
          </div>
          <Box position="absolute" right={{ xs: "20px", sm: "30px", md: "50px" }} bottom={{ xs: "20px", sm: "30px", md: "50px" }}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
        <section className="mt80 pb60">
          <Container>
            {err ? <h1 style={{ textAlign: "center", fontSize: "22px" }}>{err.message}</h1> :
              <div className="row">
                <div className="col-sm-12">
                  <div className="stretch-box mb50">
                    <div className="news-detail-box">
                      <h2>Payment Module</h2>
                      <span className="custom_opacity_07">
                        Select any option from below
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="payment-block full">
                          <div className="fullpayment-block">
                            <div className="payment-stretch-block">
                              <label
                                htmlFor="fullpayment"
                                className="payment_label"
                              >
                                Full Payment
                              </label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                id="fullpayment"
                                name="fullpayment"
                                value={this.state.fullNetAmount}
                                checked={this.state.paymentOption === "fullPayment" ? true : false}
                                onChange={() => this.changePaymentOption("fullPayment")}
                              />
                              <label htmlFor="fullpayment" />
                            </div>
                          </div>
                          <p className="payment_p"> {this.state.fullNetAmount.toFixed(2)} AED</p>
                          {/* <p className="title-yousave">You save</p> */}
                          {/* <span className="payment_p"> {this.state.youSaved ?? "00.00"} AED</span> */}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="payment-block">
                          <div className="fullpayment-block">
                            <div className="payment-stretch-block">
                              <label
                                htmlFor="partialpayment"
                                className="payment_label"
                              >
                                {" "}
                                Partial Payment
                              </label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                id="partialpayment"
                                name="partialpayment"
                                checked={this.state.paymentOption === "partialPayment" ? true : false}
                                onChange={() => this.changePaymentOption("partialPayment")}
                              />
                              <label htmlFor="partialpayment" />
                            </div>
                          </div>
                          <p className="payment_p">{this.state.fullNetAmount.toFixed(2)} AED</p>
                          <p className="title-yousave">
                            You can pay for multiple stage
                          </p>
                          {this.state.paymentOption === "partialPayment" && this.state.programCategory.map((item: any, index: number) => {
                            return (
                              <div className="partial-payments-block" key={index}>
                                <div className="partial-payments-wrapper" key={index}>
                                  <input type="checkbox" className="checkbox-custom" name={item.name} onChange={() => this.partialCheckBoxChange(item)} value={item.amount} checked={item.isChecked} />
                                  <label className="checkbox-custom-label"> {item.name}</label>
                                </div>
                                <h6 className="partial-amount">AED {item.amount.toFixed(2)}</h6>
                              </div>
                            )
                          })}
                        </div>
                        {this.state.paymentOption !== "" &&
                          <div>
                            <div className="payment-detail">
                              <h3>Payment Details</h3>
                              <div className="item">
                                <p>Net Amount</p>
                                <p className="amount">AED {" "}
                                  {this.state.paymentOption === "fullPayment"
                                    ? this.state.fullNetAmount.toFixed(2)
                                    : this.state.partialNetAmount.toFixed(2)
                                  }
                                </p>
                              </div>
                              <div className="item">
                                <p>5% vat is Applicable for Driving Fees</p>
                                <p className="amount">AED {" "}
                                  {this.state.paymentOption === "fullPayment"
                                    ? this.state.fullVatAmount.toFixed(2)
                                    : this.state.partialVatAmount.toFixed(2)
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="payment-block">
                              <div className="fullpayment-block">
                                <div className="payment-stretch-block">
                                  <label
                                    className="label_styling"
                                  >
                                    AED {this.state.totalPayableAmount.toFixed(2)}
                                  </label>
                                  <br />
                                  <span className="span_style">Amount Payable</span>
                                </div>
                                <div className="circle1">
                                  <div className="circle2">
                                    <HiArrowCircleRight className={this.state.totalPayableAmount === 0 ? "navigation-link" : "navigation-link2"} onClick={() => {
                                      this.nextPageNavigation()
                                    }} />
                                  </div>
                                </div>
                              </div>
                              <div />
                            </div>
                            <div className="paybutton_div">
                              <button
                                className="pay_button"
                                style={{ opacity: this.state.totalPayableAmount === 0 ? '0.5' : '1', cursor: this.state.totalPayableAmount === 0 ? "default" : "pointer" }}
                                disabled={this.state.totalPayableAmount ? false : true}
                                onClick={() => this.nextPageNavigation()}
                              >
                                Pay Now
                              </button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </Container>
        </section>
      </CommonWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CommonWrapper = styled.main``;
const Container = styled.div`
  width: 80%;
  margin: auto;
`;

// Customizable Area End
