import React, { Component } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import styled from "styled-components";
import { BsCheckCircleFill } from "react-icons/bs";
import { RiVisaLine } from "react-icons/ri";
import { VscCreditCard } from "react-icons/vsc";
import { AiOutlineStar } from "react-icons/ai";
import { Button, Box, Dialog, DialogActions,DialogTitle } from "@material-ui/core";
import { withRouter } from "react-router";
import PaymentMethodController, { Props } from "./PaymentMethodController";
import WhatsappIcon from "../../../components/src/WhatsappIcon";
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from "@stripe/react-stripe-js"

// const stripePromis = loadStripe(
//     'pk_test_51KyFpyHdR4l61Wz0Chmlm4Q80ZG7tPcn7AU9OlFR3eG1MR0TotsDVtqUUEr3XZxMR2lE1vmApPCFGNTq9IP2dHqA00jJApUKvv'
// );
class PaymentMethod extends PaymentMethodController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  onButtonClick = () => {
    this.setState({ open: false });
    this.props.navigation.navigate("Home");
    localStorage.clear();
  }

  render() {
    return (
      <CommonWrapper className="document common">
        <div
          className="first-section"
          style={{
            backgroundImage: this.state.CmsData.image
              ? `url(${this.state.CmsData.image})`
              : `url('assets/payment-method.png')`,
          }}
        >
          <div>
            <div className="content">
              <h2 className="title">
                {this.state.CmsData.title
                  ? this.state.CmsData.title
                  : "Payment method"}
              </h2>
              <h5 className="sub-title">
                {this.state.CmsData.sub_title
                  ? `${this.state.CmsData.sub_title}`
                  : "Select any payment method from below"}
              </h5>
            </div>
          </div>
          <Box
            position="absolute"
            right={{ xs: "20px", sm: "30px", md: "50px" }}
            bottom={{ xs: "20px", sm: "30px", md: "50px" }}
          >
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
        <section className="mt80 pb60">
          <Container>
            <div className="row">
              <div className="col-sm-12">
                {/*
                                 <div className="stretch-box mb30">
                                    <div className="news-detail-box">
                                        <h2>Payment Module</h2>
                                        <span>Select any option from below</span>
                                    </div>
                                </div> 
                                */}
                <form autoComplete="off">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="payment-block">
                        <div>
                          <div
                            className="payment-stretch-block"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "50px",
                            }}
                          >
                            <div>
                              <RiVisaLine style={{ fontSize: "70px" }} />
                            </div>
                            <div>
                              <input
                                checked={this.state.data === "card"}
                                onChange={(e: any) => {
                                  if (e.target.checked === false) {
                                    this.setState({ ...this.state, data: "" });
                                  } else {
                                    this.setState({
                                      ...this.state,
                                      data: e.target.value,
                                    });
                                  }
                                }}
                                type="checkbox"
                                name="check"
                                style={{
                                  display: "block",
                                  width: "50px",
                                  height: "30px",
                                }}
                                value="card"
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className="title">Pay by Card</h4>
                        <h6 className="cost-payment">
                          You can pay with credit or debit cards
                        </h6>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="payment-block">
                        <div>
                          <div
                            className="payment-stretch-block"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "50px",
                            }}
                          >
                            <VscCreditCard style={{ fontSize: "70px" }} />
                            <div>
                              <input
                                checked={this.state.data === "center"}
                                onChange={(e: any) => {
                                  if (e.target.checked === false) {
                                    this.setState({ ...this.state, data: "" });
                                  } else {
                                    this.setState({
                                      ...this.state,
                                      data: e.target.value,
                                    });
                                  }
                                }}
                                type="checkbox"
                                name="check"
                                style={{
                                  display: "block",
                                  width: "50px",
                                  height: "30px",
                                }}
                                value="center"
                              />
                            </div>
                          </div>
                        </div>
                        <h4 className="title">Pay at Center</h4>
                        <h6 className="cost-payment">Meet and Pay</h6>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          onClick={() => this.BtnClikc(this.state.data)}
                          className="PayBtn"
                        >
                          Procees to Pay
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Container>
        </section>
        <Dialog
          open={this.state.open}
          onClose={()=>{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"  style={{borderRadius:"20px",padding:"20px"}}>
            Hello, You may proceed to your nearest branch to pay and complete the registration process. Thank You!
          </DialogTitle>
          <DialogActions style={{justifyContent:"center"}}>
            <Button onClick={this.onButtonClick} style={{backgroundColor:"black",color:"white"}}>
                Ok
            </Button>
          </DialogActions>
        </Dialog>
      </CommonWrapper>
    );
  }
}

export default PaymentMethod;

const CommonWrapper = styled.main``;
const BannerSection = styled.section`
  background-size: cover;
  width: 100%;
  min-height: 650px;
  position: relative;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Container = styled.div`
  width: 80%;
  margin: auto;
  .title {
    font-size: 26px;
    color: white;
    font-weight: "500";
  }
  .PayBtn {
    width: 30%;
    background-color: #161616;
    border-radius: 25px;
    color: #fff;
    padding: 10px;
  }
  .PayBtn:hover {
    background-color: white;
    color: #161616 !important;
    box-shadow: 0px 0px 0px 1px #161616;
    span {
      color: #161616;
    }
  }
  @media only screen and (max-width: 430px) {
    .PayBtn {
      width: 100%;
    }
  }
`;
