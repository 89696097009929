import React, { Component } from 'react'
import { BlockComponent } from '../../../framework/src/BlockComponent';
import styled from "styled-components";
import { BsCheckCircleFill } from "react-icons/bs"
import { RiVisaLine } from 'react-icons/ri'
import { MdDownloadForOffline } from 'react-icons/md'
import { VscCreditCard } from 'react-icons/vsc'
import { AiOutlineStar, AiFillCheckCircle, AiOutlineCheck } from "react-icons/ai"
import { Button, Box, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { withRouter } from 'react-router';
import SuccessPaymentController, { Props } from './SuccessPaymentController.web';
import WhatsappIcon from '../../../components/src/WhatsappIcon';
import moment from "moment";
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from "@stripe/react-stripe-js"


// const stripePromis = loadStripe(
//     'pk_test_51KyFpyHdR4l61Wz0Chmlm4Q80ZG7tPcn7AU9OlFR3eG1MR0TotsDVtqUUEr3XZxMR2lE1vmApPCFGNTq9IP2dHqA00jJApUKvv'
// );
class SuccessPayment extends SuccessPaymentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const myObject = localStorage.getItem("myObject") as any
        const myObjectData = JSON.parse(myObject) as any
        const Count = localStorage.getItem("count") as any
        return (
            <CommonWrapper className="document common">
                <div className="first-section" style={{ backgroundImage: `url('/assets/images/vehicle_detail.png')` }}>
                    <div>
                        <div className="content">
                            <h2 className="title">Invoice</h2>
                            <h5 className="sub-title">Student</h5>
                        </div>
                    </div>
                    <Box position="absolute" right={{ xs: "20px", sm: "30px", md: "50px" }} bottom={{ xs: "20px", sm: "30px", md: "50px" }}>
                        <img src={"assets/images/whatapp.png"} className="whatapp-img" />
                    </Box>
                </div>
                <section className="mt80 pb60">
                    <Container>
                        <div className="row">
                            <Dialog
                                open={this.state.modelState}
                                onClose={this.modelClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    Thank you contacting Eco drive. We have your details and we will get back to you!
                                </DialogTitle>
                                <DialogActions>
                                    <Button onClick={this.BtnClick}>Download Again</Button>
                                    <Button onClick={this.modelClose} autoFocus>
                                        Ok
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <div className="col-sm-12">

                                <div className="stretch-box mb30">
                                    <div className="news-detail-box">
                                        <h2>Practical Training</h2>
                                        <span>Stage-1, Stage - 2 to 3, Night, Initial driving Test</span>
                                    </div>
                                </div>

                                <form autoComplete="off">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="blackBox">
                                                <div>
                                                    <div>
                                                        <span className="title">Invoice Id:153435</span>
                                                    </div>
                                                    <div>
                                                        <span className="cost-payment">22 Feb 2022 08:15PM </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='Paid'><AiOutlineCheck /> <span className='PaidText'>Paid</span> </div>
                                                </div>
                                            </div>
                                            <div className="mainBlock">
                                                <div className='FristDiv' >
                                                    <h5>Transaction type:</h5>
                                                    <h5 style={{ textTransform: "capitalize" }} >{this.state?.Transactiontype ? this.state?.Transactiontype : "-"}</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Amount Paid:</h5>
                                                    <h5>AED {localStorage.getItem("amount") as any ? localStorage.getItem("amount") as any : "-"}</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Licence Type:</h5>
                                                    <h5>{JSON.parse(localStorage.getItem("sendItem") as any).licence_type ? JSON.parse(localStorage.getItem("sendItem") as any).licence_type : "-"}</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Course Type:</h5>
                                                    <h5>Regular</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Date &#38; Time:</h5>
                                                    <h5>{moment(this.state.InvoiceDate).format("DD-MMM-YYYY H:MM A")}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mainBlock">
                                                <div className='FristDiv' >
                                                    <h5>S.no</h5>
                                                    <h5>01</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Stage</h5>
                                                    <h5>Registration</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Qty</h5>
                                                    <h5>{Count}</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Price</h5>
                                                    <h5>{localStorage.getItem("amount") as any ? localStorage.getItem("amount") as any : "-"}</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Amount</h5>
                                                    <h5>{this.state.netAmount ? this.state.netAmount : "-"}</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Discount</h5>
                                                    <h5>{myObjectData.Save ? myObjectData.Save : "0"}</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Net Amount</h5>
                                                    {/* <h5>{this.state.netAmount ? this.state.netAmount : "-"}</h5> */}
                                                    <h5>{localStorage.getItem("net") as any}</h5>
                                                </div>
                                                <div className='FristDiv' >
                                                    <h5>Vat @ 5%</h5>
                                                    <h5>{localStorage.getItem("vat") as any}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button onClick={this.BtnClick} endIcon={<MdDownloadForOffline />} className="PayBtn">Download Invoice</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Container>
                </section >
            </CommonWrapper >
        )
    }
}

export default SuccessPayment;

const CommonWrapper = styled.main({
    '& .mainBlock': {
        borderRadius: '15px',
        color: '#fff',
        border: "1px solid grey",
        marginBottom: '30px',
        'div:nth-child(even)': {
            background: '#d3d3d394',
            borderRadius: "8px"
        }
    },
    '& .FristDiv': {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        padding: '5px 20px',
        h5: {
            color: "grey",
            fontSize: "14px"
        },
        'h5:nth-child(even)': {
            fontWeight: "600",
            color: "black"
        },
        '@media only screen and (max-width: 430px)': {
            padding: '10px 10px',
        }
    },
    '& .blackBox': {
        padding: '20px',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        background: '#161616',
        borderRadius: '15px',
        color: '#fff',
        marginBottom: '18px',
    },
    '& .Paid': {
        display: 'flex',
        alignItems: "center"
    },
    '& .PaidText': {
        paddingLeft: "5px"
    }
})
const BannerSection = styled.section`
background-image: url('/assets/images/vehicle_detail.png');
background-size: cover;
width: 100%;
min-height: 650px;
position: relative;
display:flex;
flex-flow: column;
flex-wrap:wrap;
justify-content:space-evenly;
`;

const Container = styled.div`
width: 80%;
margin: auto;
.title{
    font-size: 16px;
    color:white;
    font-weight: "500";
}
.PayBtn{
    width: 30%;
    background-color: #161616;
    border-radius: 25px;
    color:#fff;
    padding: 10px;
}
.OkBtn{
    background-color: #161616 !important;
    border-radius: 25px;
    color:#fff;
    padding: 10px;
}
.PayBtn:hover{
    background-color: white;
    color: #161616;
    box-shadow: 0px 0px 0px 1px #161616;
    span{
        color:#161616
    }
}
@media only screen and (max-width: 430px) {
  .PayBtn{
      width: 100%;
  }
}
`;
