import React, { Component } from 'react';
import { withRouter } from 'react-router';
class RightNabar extends Component {

  constructor(props) {
    super()
    this.state = {
      countryCode: [
        { label:"+91", value:"+91" },
        { label:"+971", value:"+971" },
      ],
      selectedCode: '+971',
      phoneNumber: '',
      enableSave: false
    }

  }

  handleChange = (event) => { 
    let val = event.target.value.replace(/[^0-9]+/g, "");
    this.setState({ phoneNumber: val });
    let length = val.split('').length
    if (length === 9 || length === 10) {
      this.setState({ enableSave: true })
    }
    else {
      this.setState({ enableSave: false })
    }
  }
  handleSelect = (event) => {
    let val = event.target.value;
    this.setState({ selectedCode: val })
  }

  navigate = () => {
    this.props.history.push({
      pathname: '/RequestCallback',
      state: { code: this.state.selectedCode, phoneNumber: this.state.phoneNumber }
    })

    this.setState({ phoneNumber: '', enableSave:false, selectedCode:"+971" })
  }


  render() {
    return (
      <div className="bottomHeaderRight">
        <div className="phone-input-wrapper">
          <select name="countryCode" className="input_selector" value={this.state.selectedCode} onChange={this.handleSelect}>
            {this.state.countryCode.map((data, index) => (
              <option key={index} value={data.value} >{data.value}</option>
            ))}
          </select>
          <input type="text" className='phoneNumber_placeholder' placeholder="Phone Number*" value={this.state.phoneNumber} maxLength={10} onChange={this.handleChange} />
          <button className="request_callback_btn" style={{ opacity: this.state.enableSave ? '1' : '0.5', pointerEvents: this.state.enableSave ? '' : 'none' }} onClick={this.navigate}>Request a Call Back</button>
        </div>
      </div>
    )
  }
}

export default withRouter(RightNabar);