import React from "react";
// Customizable Area Start
import styled from "styled-components";
import { MdKeyboardArrowRight } from "react-icons/md"
import { MdKeyboardArrowLeft } from "react-icons/md"

// Customizable Area End

import LoyaltySystem2Controller, {
  Props,
  configJSON,
} from "./LoyaltySystem2Controller";

export default class LoyaltySystem2 extends LoyaltySystem2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getCmsApi()
    window.scrollTo(0, 0);
    // Customizable Area End
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <CommonWrapper >
        <BannerSection style={{ backgroundImage: this.state.CmsData.image ? `url(${this.state.CmsData.image})` : "url('assets/images/news_events.png')" }}>
          <Container>
            <div className="row">
              <div className="col-sm-6">
                <div className="banner-heads">
                  <h2> {this.state.CmsData.title ? this.state.CmsData.title : "News and Events"}</h2>
                  <p>{this.state.CmsData.sub_title ? this.state.CmsData.sub_title : "Browse Through our Latest News & Events"}</p>
                </div>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </Container>
        </BannerSection>
        <section className="mt80 pb60">
          <Container>
            <div className="row">
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Title Here</h2>
                    <span>Posted: 21 May 2022</span>
                  </div>
                  <div className="light-btn">
                    <a href="#"> <MdKeyboardArrowLeft /> Go Back</a>
                  </div>
                </div>
                <div className="news-detail-banner mb30">
                  <img src="assets/images/news-detail.png" alt="" />
                </div>
                <p className="pera-news-detail mb30">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in rhoncus tellus, sed pellentesque nulla. Cras in turpis non dolor rutrum convallis eget ut dolor. Nulla consectetur accumsan ligula, sed cursus mauris viverra ut. Morbi convallis justo vitae libero convallis, nec facilisis nisi iaculis. Vestibulum vel elit orci.</p>
                <p className="pera-news-detail mb30">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in rhoncus tellus, sed pellentesque nulla. Cras in turpis non dolor rutrum convallis eget ut dolor. Nulla consectetur accumsan ligula, sed cursus mauris viverra ut. Morbi convallis justo vitae libero convallis, nec facilisis nisi iaculis. Vestibulum vel elit orci.</p>
                <p className="pera-news-detail mb30">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in rhoncus tellus, sed pellentesque nulla. Cras in turpis non dolor rutrum convallis eget ut dolor. Nulla consectetur accumsan ligula, sed cursus mauris viverra ut. Morbi convallis justo vitae libero convallis, nec facilisis nisi iaculis. Vestibulum vel elit orci.</p>
                <div className="stretch-box mb30">
                  <div className="light-btn">
                    <a href="#"> <MdKeyboardArrowLeft /> Previous</a>
                  </div>
                  <div className="light-btn">
                    <a href="#"> Next <MdKeyboardArrowRight /></a>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </CommonWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CommonWrapper = styled.main`
`
const BannerSection = styled.section`
background-size: cover;
width: 100%;
min-height: 650px;
position: relative;
display:flex;
flex-flow: column;
flex-wrap:wrap;
justify-content:space-evenly;
`;

const Container = styled.div`
width: 80%;
margin: auto;
`;

// Customizable Area End
