import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import styled from "styled-components";
import '../assets/Language.css';
export const configJSONUrl = require("../../../framework/src/config");
import Spinner from "../../../components/src/Spinner.web";
import WhatsappIcon from "../../../components/src/WhatsappIcon";
let authToken: any;
let newObj:any;
const  { baseURL } = require("../../../framework/src/config");
// Customizable Area End

import LanguageOptionsController, {
  Props,
  configJSON,
} from "./LanguageOptionsController";




export default class LanguageOptions extends LanguageOptionsController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // this.state = { hasError: false };



    // Customizable Area Ends
  }

  // Customizable Area Start

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getCMSDetails();
    authToken = localStorage.getItem('authToken');
    this.setState({ courseId: new URLSearchParams(location.search).get('id') })
    this.setState({ isLoading: true })
    this.fetchCourses()
  }
  setId = () => {
  // newObj = { fee : this.state.coursesTypeDetails.Fees , payOnly : this.state.coursesTypeDetails.Payonly , save : this.state.coursesTypeDetails.Save }
    // console.log(JSON.parse(newObj.payOnly))
    localStorage.setItem("myObject", JSON.stringify(this.state.coursesTypeDetails))
    localStorage.setItem("name",this.state.coursesTypeDetails.name);
    localStorage.setItem("id", this.state.courseId);
    localStorage.setItem("fees",this.state.coursesTypeDetails.Fees)
    this.props.navigation.navigate("Payments")
  }

  fetchCourses = () => {
    this.setState({ isLoading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "token": authToken
      }

    }
    // try{
    fetch(configJSONUrl.baseURL + `/courses`,requestOptions).then((res => {

      if (res.ok) {
        return res.json();
      }
      throw new Error('Something went wrong, Please try again.');
    }))
      .then((json) => {

        const selectedData = json.data
        newObj=json.data
        let selectedCourse = selectedData.filter((x: any) => x.id === this.state.courseId);

        if (selectedCourse.length > 0) {
          let infoArray: any[] = [];
          let name = localStorage.getItem("name");
          if(name === "Regular" || name === "Flexi"){
            selectedCourse[0].attributes = {
              ...selectedCourse[0]?.attributes,
              Fees: selectedCourse[0]?.attributes?.Fees.reg_total_amount
            }
          }

          this.setState({ coursesTypeDetails: selectedCourse[0].attributes });
          selectedCourse[0].attributes.information.data.forEach((detail: any) => {
            infoArray.push(detail)
          })
          this.setState({ courseTypeInformation: infoArray })
          this.setState({
            isLoading: false

          })

        }
      }).catch((error) => {

        this.setState({
          err: error,
          isLoading: false

        })
      })
  }

  getCMSDetails = () => {
    this.setState({ isLoading: true });

    let name = localStorage.getItem("name");
    const fetchUrl: any = `${baseURL}/bx_block_dashboard/page_titles/:id?name=${name}_course`
    
    fetch(fetchUrl)
      .then((response) => response.json())
      .then((ResponseJson) => {
        this.setState({
          detail: ResponseJson?.data?.attributes,
          isLoading: false
        });
      }).catch((error) =>{
        this.setState({ isLoading: false });
      });
  }
 

  // Customizable Area End

  render() {
    let { err, isLoading } = this.state;
    return (
      // Customizable Area Start
        <CommonWrapper className="document common">
        {isLoading && <Spinner spinnerModal={isLoading} />}
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url('assets/courses.png')`}}>
          <div className="content">
            <h2 className="title">{this.state.coursesTypeDetails?.name}</h2>
            <h5 className="sub-title">Fees: {Number(this.state.coursesTypeDetails.Fees ?? 0).toFixed(2)}</h5>
            <h3 style={{fontWeight:"bold",marginTop:"20px"}}>{this.state.coursesTypeDetails?.detail}</h3>
          </div>
          <Box position="absolute" right={{xs:"20px",sm:"30px",md:"50px"}} bottom={{xs:"20px",sm:"30px",md:"50px"}}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img"/>
          </Box>
        </div>
        <section className="mt80 pb30">
          <Container>
            {err ? <h1 style={{ textAlign: "center" }}>{err.message}</h1> :
              <div className=" row new_things">
                <div className="col-sm-8 m-auto">
                  <div className="row">
                    {this.state.courseTypeInformation.map((data: any, index: any) => (
                      <div className="col-sm-6" key={index}>
                        <div className="subscription-plan-blocks">
                          <div className="subscription-title-block">
                            <div className="list-circle"></div>
                            <h4>{data.attributes.info_title}</h4>
                          </div>
                          <span>{data.attributes.info_detail}</span>
                        </div>
                      </div>
                    ))}
                    {this.state.coursesTypeDetails.name ? <LoginButton style={{ fontWeight: 600 }} className="col-sm-6 col-md-4" onClick={() => {
                      this.setId()
                    }}>  Choose {this.state.coursesTypeDetails.name}  </LoginButton> : null}

                    {/* <div className="col-sm-6">
                    <div className="subscription-plan-blocks">
                      <div className="subscription-title-block">
                          <div className="list-circle"></div>
                          <h4>12 Hours training per day</h4>
                      </div>
                      <span>Max 3 days a week with professional instructor</span>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="subscription-plan-blocks">
                      <div className="subscription-title-block">
                          <div className="list-circle"></div>
                          <h4>12 Hours training per day</h4>
                      </div>
                      <span>Max 3 days a week with professional instructor</span>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="subscription-plan-blocks">
                      <div className="subscription-title-block">
                          <div className="list-circle"></div>
                          <h4>12 Hours training per day</h4>
                      </div>
                      <span>Max 3 days a week with professional instructor</span>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            }
          </Container>
        </section>
      </CommonWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CommonWrapper = styled.main`
`

const BannerSection = styled.section`
background-image: url('assets/images/news_events.png');
background-size: cover;
width: 100%;
min-height: 650px;
position: relative;
display:flex;
flex-flow: column;
flex-wrap:wrap;
justify-content:space-evenly;
`;

const Container = styled.div`
width: 80%;
margin: auto;
`;
const LoginButton = styled.button`
width: 100%;
outline: 0;
border: 0;
border-radius: 30px;
height: 50px;
color: #fff;
background: #151817;
font-weight: 500;
font-size: 18px;
`;
// Customizable Area End
