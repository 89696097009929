import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components"; 
import { boolean } from 'yup';
const  { baseURL } = require("../../framework/src/config.js");

export interface state {
  iconToggle:any;
  detail:any[];
}

export default class WhatsappIcon extends Component <{}, {iconToggle:any, detail:any[]}> {
  
    constructor(props:state) {
      super(props);
      this.state = {
        iconToggle:false,
        detail:[]
      }
    }

    componentDidMount(){
      this.getWhatsAppList();
    }

    getWhatsAppList = () => {
      const fetchUrl = `${baseURL}/bx_block_dashboard/social_infos`
      fetch(fetchUrl)
        .then((response) => response.json())
        .then((responseJson) => {
          if(responseJson?.data){
            this.setState({
              detail: responseJson?.data
            })
          }
        }).catch((error) =>{
          console.log(error);
      });
    }

    render() {
      return (
        <WhatsApp>
          {this.state.iconToggle ? 
            <QuickBox className="box_item mobile_view_box">
            {this.state.detail?.length !== 0 && 
              <ul style={{border: "1px solid #999", listStyle: "none", width: "362px"}}>
                {this.state.detail?.map((item:any,index:number)=>{
                  return <li key={index}>
                      <span><img src={baseURL+item?.attributes?.whatsup_icon} /></span>
                      <Link style={{textDecoration:"none",color:"#151817"}} to={item?.attributes?.whatsup_link}>{item?.attributes?.whatsup_name}</Link>
                    </li>
                  })
                }
              </ul>
            }
            </QuickBox> : null}
            <Icon className='mobile_view_icon' onClick={()=>{
              this.setState({iconToggle:!this.state.iconToggle})
            }}></Icon>
        </WhatsApp>
      );
    }
}

const WhatsApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  right: 0px;
`;

const QuickBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  z-index:999;
`;

const Icon = styled.div`
  color: black;
  cursor:pointer;
  display: flex;
  justify-content: flex-end;
  background-image: url(assets/icons/whatsapp.svg);
  background-repeat: no-repeat;  
  width: 90px;
  height: 90px;
  margin: 50px;
`;
