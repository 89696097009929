import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Checkbox,
  InputBase,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import DatePicker from "react-datepicker";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import Spinner from "../../../components/src/Spinner.web";
import "react-datepicker/dist/react-datepicker.css";
// Customizable Area End
//@ts-ignore
import Select from "react-select";
// import { Dropdown } from "react-native-material-dropdown";
import Icon from "react-native-vector-icons/AntDesign";

import AddressManagementController, {
  Props,
  configJSON,
  AdressTypeData,
} from "./AddressManagementController";

export default class AddAddress extends AddressManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0,0);
    this.getDetails();
    this.getCountryList();
    // this.getVehicleType();
    this.setVehicleTypes();
    this.setOccupation();
  }

  addressSchema = () => {
    return Yup.object().shape({
      address: Yup.string().required("*Address is required"),
      occupation: Yup.string().required("*Occupation is required"),
      otherOccupation:Yup.string().when("occupation",
        (value:any, schema:any) =>{ 
          if(value === "Other"){
            return schema.required("*Other Occupation is required")
          }
        }
      ),
      visaProfession: Yup.string().required("*Visa Profession is required"),
      otherVisaProfession:Yup.string().when("visaProfession",
        (value:any, schema:any) =>{ 
          if(value === "Other"){
            return schema.required("*Other Visa Profession is required")
          }
        }
      ),
      validDrivingLicence: Yup.string().required("*Select any option"),
      licenceType: Yup.string().when("validDrivingLicence",
        (value:any, schema:any) =>{
          if(value === "yes"){
            return schema.required("*Licence Type is required")
          }
        }
      ),
      vehicleGear: Yup.string().when("validDrivingLicence",
        (value:any, schema:any) =>{
          if(value === "yes"){
            return schema.required("*Vehicle Gear is required")
          }
        }
      ),
      validFrom: Yup.date().when("validDrivingLicence",
        (value:any, schema:any) =>{
          if(value === "yes"){
            return schema.required("*Please select date")
          }
        }
      ),
      validTo: Yup.date().when(["validDrivingLicence","validFrom"],
        (value:any,validFrom:any,schema:any) =>{
          if(value === "yes"){
            if(validFrom){
              return schema.min(new Date(validFrom),"*Valid To not less then valid from")
              .required("*Please select date")
            }
            return schema.required("*Please select date")
          }
        }
      ),
      validLMVDrivingLicence: Yup.string().required("*Select any option"),
      country: Yup.string().when("validLMVDrivingLicence",
        (value:any, schema:any) =>{
          if(value === "yes"){
            return schema.required("*Please select country")
            .nullable("*Please select country")
          }
        }
      ),
      lvmLicenceType: Yup.string().when("validLMVDrivingLicence",
        (value:any, schema:any) =>{
          if(value === "yes"){
            return schema.required("*Licence Type is required")
          }
        }
      ),
      lvmVehicleGear: Yup.string().when("validLMVDrivingLicence",
        (value:any, schema:any) =>{
          if(value === "yes"){
            return schema.required("*Vehicle Gear is required")
          }
        }
      ),
      validFrom2: Yup.date().when("validLMVDrivingLicence",
        (value:any, schema:any) =>{
          if(value === "yes"){
            return schema.required("*Please select date")
          }
        }
      ),
      validTo2: Yup.date().when(["validLMVDrivingLicence","validFrom2"],
        (value:any,validFrom2:any,schema:any) =>{
          if(value === "yes"){
            if(validFrom2){
              return schema.min(new Date(validFrom2),"*Valid To not less then valid from")
              .required("*Please select date")
            }
            return schema.required("*Please select date")
          }
        }
      ),
      termAndConditionChecked: Yup.boolean().oneOf(
        [true],
        "*Accept the term & conditions"
      ),
    });
  };

  setOccupation = () => {
    let occupation = localStorage.getItem("occupation");
    if(occupation){
      const obj = occupations.find(item=>item.label === occupation);
      !!obj ? this.setState({ occupation: occupation, readData:true })
      : this.setState({ occupation: "Other", otherOccupation: occupation, readData: true });
    }
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      <div className="address common common-form">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} /> }
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url("assets/images/home-bg.png")` }} >
          <div className="content">
            <h2 className="title">{this.state.detail?.title ?? "Join Eco Drive"}</h2>
            <h5 className="sub-title">{this.state.detail?.sub_title ?? "Eco Drive - The driving institute."}</h5>
          </div>
          <Formik
            enableReinitialize={this.state.readData}
            initialValues={{
              address: this.state.address,
              occupation: this.state.occupation,
              otherOccupation: this.state.otherOccupation,
              visaProfession: this.state.visaProfession,
              otherVisaProfession: this.state.otherOccupation,
              validDrivingLicence: this.state.validDrivingLicence,
              licenceType: this.state.licenceType,
              vehicleGear: this.state.vehicleGear,
              validFrom: this.state.validFrom,
              validTo: this.state.validTo,
              validLMVDrivingLicence: this.state.validLMVDrivingLicence,
              lvmLicenceType: this.state.lvmLicenceType,
              lvmVehicleGear: this.state.lvmVehicleGear,
              country: this.state.country,
              validFrom2: this.state.validFrom2,
              validTo2: this.state.validTo2,
              termAndConditionChecked: this.state.termAndConditionChecked,
            }}
            validationSchema={this.addressSchema}
            onSubmit={(values) => {
              this.submitHandler();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                className="form"
              >{this.state.readData && this.setState({ readData: false })}
                <Grid container spacing={7} className="grid-padding">
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyAI-3_mUD0DZbUP4NHw_dbMtwK-Rv6vyhE"
                        debounce={500}
                        selectProps={{
                          isClearable:true,
                          classNamePrefix:"react-select",
                          placeholder: "Address*",
                          noOptionsMessage: () => null,
                          onChange: async (event: any) => {
                            setFieldValue("address", event?.value?.description ?? "");
                            this.setState({ address : event?.value?.description ?? "" });
                          },
                        }}
                      />
                      {touched.address && errors.address && (
                        <div className="text-danger">{errors.address}</div>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box mb={2}>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Occupation*"
                        isSearchable={false}
                        options={occupations}
                        value={
                          occupations.filter(
                            (option: any) =>
                              option.value === this.state.occupation
                          )[0]
                        }
                        onChange={(event: any) =>{
                          setFieldValue("occupation", event.value);
                          this.setState({ occupation: event.value })
                        }}
                      />
                      {touched.occupation && errors.occupation && 
                        <div className="text-danger">{errors.occupation}</div>
                      }
                    </Box>
                    {this.state.occupation === "Other" && 
                      <Box mb={2}>
                        <CustomInputBase
                          fullWidth
                          placeholder="Other Occupation*"
                          value={this.state.otherOccupation}
                          onChange={(event: any) =>{
                            setFieldValue("otherOccupation", event.target.value);
                            this.setState({ otherOccupation : event.target.value })
                          }}
                        />
                        {touched.otherOccupation && errors.otherOccupation && 
                          <div className="text-danger">{errors.otherOccupation}</div>
                        }
                      </Box>
                    }
                    <Box mb={1}>
                      <Typography style={{fontFamily: 'ITC Avant Garde Gothic Std'}}>
                        Do you have valid Dubai Driving Licence?*
                      </Typography>
                     <div>
                      <RadioGroup
                        row
                        aria-labelledby="gender"
                        name="validDrivingLicence"
                        className="radio-btn"
                        value={this.state.validDrivingLicence}
                        onChange={(event: any) => {
                          setFieldValue("validDrivingLicence", event.target.value);
                          this.setState({ validDrivingLicence:event.target.value });
                        }}
                        style={{ fontWeight: "bold" }}
                      >
                        <FormControlLabel
                        style={{marginRight:"45px"}}
                          value={"yes"}
                          control={<Radio style={{ color: "black" }} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={"no"}
                          control={<Radio style={{ color: "black" }} />}
                          label="No"
                        />
                      </RadioGroup>
                     </div>
                      {touched.validDrivingLicence && errors.validDrivingLicence && 
                        <div className="text-danger">{errors.validDrivingLicence}</div>
                      }
                    </Box>
                    {this.state.validDrivingLicence === "yes" &&
                      <>
                        <Box mb={2}>
                          <Select
                            classNamePrefix="react-select"
                            placeholder="Licence Type*"
                            isSearchable={false}
                            options={this.state.vehicleTypeData}
                            value={
                              this.state.vehicleTypeData.filter(
                                (option: any) =>
                                  option.value === this.state.licenceType
                              )[0]
                            }
                            onChange={(event: any) =>{
                              if(this.state.vehicleGear){
                                let gear = event.value === "Car" ? this.carGearOption[0].value : "Manual";
                                this.setState({ vehicleGear: gear });
                                setFieldValue("vehicleGear", gear);
                              }
                              this.setState({ licenceType: event.value });
                              setFieldValue("licenceType", event.value);
                            }}
                          />
                          {touched.licenceType && errors.licenceType && 
                            <div className="text-danger">{errors.licenceType}</div>
                          }
                        </Box>
                        <Box mb={3}>
                          <Select
                            classNamePrefix="react-select"
                            placeholder="Vehicle Gear*"
                            isSearchable={false}
                            options={this.state.licenceType === "Car" ? this.carGearOption : this.manualOption}
                            value={
                              this.vehicleGearData.filter(
                                (option: any) =>
                                  option.value === this.state.vehicleGear
                              )[0]
                            }
                            onChange={(event: any) =>{
                              setFieldValue("vehicleGear", event.value);
                              this.setState({ vehicleGear: event.value })
                            }}
                          />
                          {touched.vehicleGear &&errors.vehicleGear && 
                            <div className="text-danger">{errors.vehicleGear}</div>
                          }
                        </Box>
                        <Box mb={3}>
                          <DatePicker
                            selected={this.state.validFrom}
                            onChange={(date: any) => {
                              setFieldValue("validFrom", date);
                              this.setState({ validFrom : date });
                              if(date.toString() === this.state.validTo.toString())
                                this.setState({ validToErr: true });
                              else
                                this.setState({ validToErr: false });
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            maxDate={new Date()}
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Valid From*"
                          />
                          {touched.validFrom && errors.validFrom && (
                            <div className="text-danger">
                              {errors.validFrom}
                            </div>
                          )}
                        </Box>
                        <Box mb={3}>
                          <DatePicker
                            selected={this.state.validTo}
                            onChange={(date: any) => {
                              setFieldValue("validTo", date);
                              this.setState({ validTo : date });
                              if(date.toString() === this.state.validFrom.toString())
                                this.setState({ validToErr: true });
                              else
                                this.setState({ validToErr: false });
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Valid To*"
                          />
                          {touched.validTo && errors.validTo &&
                            <div className="text-danger">
                              {errors.validTo}
                            </div>
                          }
                          {this.state.submitClick && this.state.validToErr &&
                            <div className="text-danger">
                              *Valid to and valid from date not be same
                            </div>
                          }
                        </Box>
                        <Box mb={2}>
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            id="uaeLicencePicture"
                            onChange={(event: any) =>
                              this.setState({
                                uaeLicencePicture: event.target.files[0],
                                isEmiratePicture: true
                              },()=>this.image_upload_Api())
                            }
                          />
                          <label htmlFor="uaeLicencePicture" style={{width:"100%",margin:"0px"}}>
                            <UploadButton>
                              <div style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                <div>
                                  <div style={{textAlign:"start"}}>Upload Emirate Licence Picture</div>
                                  <div style={{color:"#BDBFBE",textAlign:"start", fontWeight:"normal",fontSize:"12px"}}>Browse from your Computer</div>
                                </div>
                                <MdOutlineDriveFolderUpload style={{width:"30px",height:"30px"}}/>
                              </div>
                            </UploadButton>
                          </label>
                        </Box>
                      </>
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box mb={2}>
                      <Select
                        classNamePrefix="react-select"
                        placeholder="Visa Profession*"
                        options={visaProfessionType}
                        value={
                          visaProfessionType.filter(
                            (option: any) =>
                              option.value === this.state.visaProfession
                          )[0]
                        }
                        onChange={(event: any) =>{
                          setFieldValue("visaProfession", event.value);
                          this.setState({ visaProfession: event.value })
                        }}
                      />
                      {touched.visaProfession && errors.visaProfession && 
                        <div className="text-danger">{errors.visaProfession}</div>
                      }
                    </Box>
                    <Box mb={2}>
                    {this.state.visaProfession === "Other" &&
                      <> 
                        <CustomInputBase
                          fullWidth
                          placeholder="Other Visa Profession*"
                          value={this.state.otherVisaProfession}
                          onChange={(event: any) =>{
                            setFieldValue("otherVisaProfession", event.target.value);
                            this.setState({ otherVisaProfession : event.target.value })
                          }}
                        />
                        <Box>
                          {touched.otherVisaProfession && errors.otherVisaProfession && 
                            <div className="text-danger">{errors.otherVisaProfession}</div>
                          }
                        </Box>
                      </>
                    }
                    </Box>
                    <Box mb={1}>
                      <Typography style={{fontFamily: 'ITC Avant Garde Gothic Std'}}>
                        Do you have valid Another Country Driving Licence?*
                      </Typography>
                     <div>
                      <RadioGroup
                        row
                        aria-labelledby="validLMVDrivingLicence"
                        name="validLMVDrivingLicence"
                        className="radio-btn"
                        value={this.state.validLMVDrivingLicence}
                        onChange={(event: any) => {
                          setFieldValue("validLMVDrivingLicence", event.target.value);
                          this.setState({ validLMVDrivingLicence:event.target.value });
                        }}
                        style={{ fontWeight: "bold" }}
                      >
                        <FormControlLabel
                          style={{marginRight:"45px"}}
                          value={"yes"}
                          control={<Radio style={{ color: "black" }} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={"no"}
                          control={<Radio style={{ color: "black" }} />}
                          label="No"
                        />
                      </RadioGroup>
                     </div>
                      {touched.validLMVDrivingLicence && errors.validLMVDrivingLicence && 
                        <div className="text-danger">{errors.validLMVDrivingLicence}</div>
                      }
                    </Box>
                    {this.state.validLMVDrivingLicence === "yes" &&
                      <>
                        <Box mb={2}>
                          <Select
                            classNamePrefix="react-select"
                            placeholder="Driving Licence Issuer Country*"
                            options={this.state.dataSource}
                            value={
                              this.state.dataSource.filter(
                                (option: any) =>
                                  option.value === this.state.country
                              )[0]
                            }
                            onChange={(event: any) =>{
                              setFieldValue("country", event.value);
                              this.setState({ country: event.value })
                            }}
                          />
                          {touched.country && errors.country && 
                            <div className="text-danger">{errors.country}</div>
                          }
                        </Box>
                        <Box mb={2}>
                          <Select
                            classNamePrefix="react-select"
                            placeholder="Licence Type*"
                            isSearchable={false}
                            options={this.state.allVehicleTypeData}
                            value={
                              this.state.allVehicleTypeData.filter(
                                (option: any) =>
                                  option.value === this.state.lvmLicenceType
                              )[0]
                            }
                            onChange={(event: any) =>{
                              if(this.state.lvmVehicleGear){
                                let gear = event.value === "Car" ? "Automatic" : "Manual";
                                this.setState({ lvmVehicleGear : gear });
                                setFieldValue("lvmVehicleGear", gear);
                              }
                              this.setState({ lvmLicenceType: event.value});
                              setFieldValue("lvmLicenceType", event.value);
                            }}
                          />
                          {touched.lvmLicenceType && errors.lvmLicenceType && 
                            <div className="text-danger">{errors.lvmLicenceType}</div>
                          }
                        </Box>
                        <Box mb={3}>
                          <Select
                            classNamePrefix="react-select"
                            placeholder="Vehicle Gear*"
                            isSearchable={false}
                            options={this.state.lvmLicenceType === "Car" ? this.vehicleGearData : this.manualOption}
                            value={
                              this.vehicleGearData.filter(
                                (option: any) =>
                                  option.value === this.state.lvmVehicleGear
                              )[0]
                            }
                            onChange={(event: any) =>{
                              setFieldValue("lvmVehicleGear", event.value);
                              this.setState({ lvmVehicleGear: event.value })
                            }}
                          />
                          {touched.lvmVehicleGear &&errors.lvmVehicleGear && 
                            <div className="text-danger">{errors.lvmVehicleGear}</div>
                          }
                        </Box>
                        <Box mb={3}>
                          <DatePicker
                            selected={this.state.validFrom2}
                            onChange={(date: any) => {
                              setFieldValue("validFrom2", date);
                              this.setState({ validFrom2 : date });
                              if(date.toString() === this.state.validTo2.toString())
                                this.setState({ validTo2Err: true });
                              else
                                this.setState({ validTo2Err: false });
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            maxDate={new Date()}
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Valid From*"
                          />
                          {touched.validFrom2 && errors.validFrom2 && (
                            <div className="text-danger">
                              {errors.validFrom2}
                            </div>
                          )}
                        </Box>
                        <Box mb={3}>
                          <DatePicker
                            selected={this.state.validTo2}
                            onChange={(date: any) => {
                              setFieldValue("validTo2", date);
                              this.setState({ validTo2 : date });
                              if(date.toString() === this.state.validFrom2.toString())
                                this.setState({ validTo2Err: true });
                              else
                                this.setState({ validTo2Err: false });
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Valid To*"
                          />
                          {touched.validTo2 && errors.validTo2 && (
                            <div className="text-danger">
                              {errors.validTo2}
                            </div>
                          )}
                          {this.state.submitClick && this.state.validTo2Err &&
                            <div className="text-danger">
                              *Valid to and valid from date not be same
                            </div>
                          }
                        </Box>
                        <Box mb={2}>
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            id="anotherLicencePicture"
                            onChange={(event: any) =>
                              this.setState({
                                anotherLicencePicture: event.target.files[0],
                                isEmiratePicture: false
                              },()=>this.image_upload_Api())
                            }
                          />
                          <label htmlFor="anotherLicencePicture" style={{width:"100%",margin:"0px"}}>
                            <UploadButton>
                              <div style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                <div>
                                  <div style={{textAlign:"start"}}>Upload Another Country Licence</div>
                                  <div style={{color:"#BDBFBE",textAlign:"start", fontWeight:"normal",fontSize:"12px"}}>Browse from your Computer</div>
                                </div>
                                <MdOutlineDriveFolderUpload style={{width:"30px",height:"30px"}}/>
                              </div>
                            </UploadButton>
                          </label>
                        </Box>
                      </>
                    }
                    <div>
                      <Box display="flex">
                      <FormControlLabel
                        style={{ marginLeft:"-12px",marginTop:"-4px"}}
                        control={
                          <Checkbox
                            style={{ color:"black" }}
                            checked={this.state.termAndConditionChecked}
                            onChange={(event: any) => {
                              setFieldValue("termAndConditionChecked",event.target.checked);
                              this.setState({ termAndConditionChecked: event.target.checked });
                            }}
                          />
                        }
                        label="All terms & conditions are accepted*"
                         />
                      </Box>
                      {touched.termAndConditionChecked && errors.termAndConditionChecked && (
                        <div className="text-danger">
                          {errors.termAndConditionChecked}
                        </div>
                      )}
                    </div>
                    <Box>
                      <ContinueButton type="submit" fullWidth onClick={()=> this.setState({ submitClick:true })}>
                        Continue
                      </ContinueButton>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
let occupations = [
  { label: "Student", value: "Student" },
  { label: "Salaried", value:  "Salaried" },
  { label: "Self Employed", value: "Self Employed" },
  { label: "Housewife", value: "Housewife" },
  { label: "Other", value: "Other" },
];

let visaProfessionType = [
  { label: "Accountant", value: "Accountant" },
  { label: "Advisor", value: "Advisor" },
  { label: "Anchor", value: "Anchor" },
  { label: "Auctioneer", value: "Auctioneer" },
  { label: "Auditor", value: "Auditor" },
  { label: "Commercial Agent", value: "Commercial Agent" },
  { label: "Computer programmer", value: "Computer programmer" },
  { label: "Consultant", value: "Consultant" },
  { label: "Contractor", value: "Contractor" },
  { label: " Daiah", value: "Daiah" },
  { label: "Director", value: "Director" },
  { label: "Doctor", value: "Doctor" },
  { label: "General Practitioner", value: "General Practitioner" },
  { label: "Economic Analyst/Researcher", value: "Economic Analyst/Researcher" },
  { label: "Editor", value: "Editor" },
  { label: "Engineer", value: "Engineer" },
  { label: "Expert", value: "Expert" },
  { label: "Financial Analyst Researcher", value: "Financial Analyst Researcher" },
  { label: "Free business", value: "Free Business" },
  { label: "Geologist", value: "Geologist" },
  { label: "Guide", value: "Guide" },
  { label: "Historian", value: "Historian" },
  { label: "Hostess", value: "Hostess" },
  { label: "Imam", value: "Imam" },
  { label: "Instructor", value: "Instructor" },
  { label: "Investor", value: "Investor" },
  { label: "Journalist", value: "Journalist" },
  { label: "Judge", value: "Judge" },
  { label: "Lawyer", value: "Lawyer" },
  { label: "Legalist", value: "Legalist" },
  { label: "Manager", value: "Manager" },
  { label: "Marriage Official", value: "Marriage Official" },
  { label: "Military Personnel", value: "Military Personnel" },
  { label: "Muezzin", value: "Muezzin" },
  { label: "Mufti", value: "Mufti" },
  { label: "Noukhaza", value: "Noukhaza" },
  { label: "Nurse", value: "Nurse" },
  { label: "Orator", value: "Orator" },
  { label: "Other", value: "Other" },
  { label: "Owner of Land Property", value: "Owner of Land Property" },
  { label: "Owner a Partner of a Business Establishment", value: "Owner a Partner of a Business Establishment" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "Producer", value: "Producer" },
  { label: "Professor", value: "Professor" },
  { label: "Program Planner", value: "Program Planner" },
  { label: "Prosecutor", value: "Prosecutor" },
  { label: "Reciter", value: "Reciter" },
  { label: "Shipmaster", value: "Shipmaster" },
  { label: "Speaker", value: "Speaker" },
  { label: "Specialist", value: "Specialist" },
  { label: "Sports Player", value: "Sports Player" },
  { label: "Sports Referee", value: "Sports Referee" },
  { label: "Storekeeper", value: "Storekeeper" },
  { label: "Surgeon", value: "Surgeon" },
  { label: "Teacher", value: "Teacher" },
  { label: "Trader", value: "Trader" },
  { label: "Trainer", value: "Trainer" },
  { label: "Treasurer", value: "Treasurer" },
  { label: "Tour guide", value: "Tour Guide" },
  { label: "Pilot", value: "Pilot" },
  { label: "Writer", value: "Writer" },
  { label: "Cabin Crew", value:"Cabin Crew"}
];

// let licenceTypeData = [
//   { label: "Car", value: "Car"},
//   { label: "Bike", value: "Bike" },
//   { label: "Truck", value: "Truck" },
//   { label: "Bus", value: "Bus" },
//   { label: "Forklift", value: "Forklift" }
// ];

const addressStyle = {
  width: "100%",
  border: "none",
  borderBottom:"1px solid #BBBCBC",
  paddingBottom: "6px",
  paddingLeft:"4px",
  outline: "none",
} 

const CustomInputBase = styled(InputBase)({
  borderBottom: "1px solid #BBBCBC",
  padding: "0px",
  fontFamily: 'ITC Avant Garde Gothic Std'
});

const UploadButton = styled(Box)({
  backgroundColor: "black",
  color: "white",
  cursor:"pointer",
  padding:"0px 20px", 
  display:"flex",
  height:"95px",
  borderRadius: "15px",
  fontSize:"16px",
  fontWeight: "bold",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "black",
  },
});

const ContinueButton = styled(Button)({
  backgroundColor: "black",
  margin: "20px 0px",
  color: "white",
  borderRadius: "20px",
  fontSize:"16px",
  fontWeight: "bold",
  textTransform:"none",
  fontFamily: 'ITC Avant Garde Gothic Std',
  "&:hover": {
    backgroundColor: "black",
  },
});

// Customizable Area End