import React from "react";
// Customizable Area Start
import { StyleSheet, Dimensions, Platform } from "react-native";
import styled from "styled-components";
import {HiOutlineLocationMarker} from "react-icons/hi"
import {CgArrowsHAlt} from "react-icons/cg"
import {WiTime9} from "react-icons/wi"
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <CommonWrapper>
      <BannerSection>
        <Container>
          <div className="row">
            <div className="col-sm-6">
              <div className="banner-heads">
                <h2>Locate an Eco Drive</h2>
                <h2>Branch near you</h2>
              </div>
            </div>
            <div className="col-sm-6">
            </div>
          </div>
        </Container>
      </BannerSection>
      <section>
        <ContainerFull>
            <div className="row margin0">
              <div className="col-sm-6 padding0">
                <div className="location-contents">
                  <div className="branch-content">
                    <h4>Select a Branch</h4>
                    <select>
                      <option>International City (Main Centre)</option>
                      <option>International City (Main Centre)</option>
                      <option>International City (Main Centre)</option>
                    </select>
                  </div>
                  <div className="location-city-content">
                    <h4>International City (Main Centre)</h4>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="location-cards">
                          <div className="location-icons">
                            <HiOutlineLocationMarker />
                          </div>
                          <h5>Location</h5>
                          <p>Location
                            Dubai Industrial City
                            P.O. Box. 450202
                            Dubai - UAE</p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="location-cards">
                          <div className="location-icons">
                            <WiTime9/>
                          </div>
                          <h5>Working Hours</h5>
                          <p>Monday - Friday
                             9am - 5pm</p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="location-cards">
                          <div className="location-icons">
                            <CgArrowsHAlt/>
                          </div>
                          <h5>Distance to Location</h5>
                          <p>20km</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="directions-content">
                  <div className="phone-input-wrapper">
                    <select>
                      <option>+971</option>
                      <option>+91</option>
                    </select>
                    <input type="text" placeholder="Phone Number*"/>
                  </div>
                  <a href="#">Send Directions Now</a>
                </div>
              </div>
              <div className="col-sm-6 padding0">
                <iframe className="location-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.9689764440054!2d77.36260885017299!3d28.57069429354266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef2aaa33267d%3A0xbaea53b76b6aa181!2sTimes%20Tribute!5e0!3m2!1sen!2sin!4v1627633887480!5m2!1sen!2sin"></iframe>
              </div>
            </div>
        </ContainerFull>
      </section>
      </CommonWrapper>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const CommonWrapper = styled.main`
`

const BannerSection = styled.section`
background-image: url('assets/images/location-banner.png');
background-size: cover;
width: 100%;
min-height: 650px;
position: relative;
display:flex;
flex-flow: column;
flex-wrap:wrap;
justify-content:space-evenly;
`;

const Container = styled.div`
width: 80%;
margin: auto;
`;

const ContainerFull = styled.div`
width: 100%;
margin: auto;
`;


// Customizable Area End
