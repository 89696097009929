import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  password: string;
  confirmPassword: string;
  isShowPassword: boolean;
  isShowConfirmPassword: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordOTP extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  confirmPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      loading: false,
      password: "",
      confirmPassword: "",
      isShowPassword: true,
      isShowConfirmPassword: true
    };
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.confirmPasswordApiCallId) {
          if (responseJson != null) {
            if (responseJson && responseJson.data) {
              localStorage.removeItem("forgot_pass_token");
              localStorage.removeItem("full_phone_number");
              toast.info("Password changed successfully!");
              setTimeout(()=>{
                this.props.navigation.navigate("EmailAccountLoginBlock");
              },1000);
            }else if(responseJson && responseJson.errors){
              if(responseJson?.errors[0]?.token){
                toast.error(responseJson?.errors[0]?.token);
              }
            }else if(responseJson.status === 500){
              toast.error("Something went wrong!");
            }
          }
          this.setState({ loading: false });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0,0);
    if(!localStorage.getItem("forgot_pass_token")){
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
  }

  resetPasswordApi = async () => {
    this.setState({ loading: true });
    
    const header = {
      "Content-Type": configJSON.confirmPasswordContentType,
      token: localStorage.getItem("forgot_pass_token")
    };
    
    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          create_password: this.state.password,
          confirm_password: this.state.confirmPassword
        }
      }
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.confirmPasswordApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.confirmPasswordUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.confirmPasswordMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
