// App.js - WEB
import React, { Component } from 'react';
// import { View } from "react-native";
import firebase from 'firebase';
// import { connect } from 'react-firebase'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
// import { ModalContainer } from "react-router-modal";
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import Footer from '../../components/src/footer';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import CfCommissionManagement2 from '../../blocks/CfCommissionManagement2/src/CfCommissionManagement2';
import RealtimeUpdates from '../../blocks/RealtimeUpdates/src/RealtimeUpdates';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import CfLogicMappingAlgorithm from '../../blocks/CfLogicMappingAlgorithm/src/CfLogicMappingAlgorithm';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Location from '../../blocks/location/src/Location';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import AssessmentTest from '../../blocks/AssessmentTest/src/AssessmentTest';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import LanguageOptions from '../../blocks/LanguageOptions/src/LanguageOptions';
import HamburgerMenu from '../../blocks/HamburgerMenu/src/HamburgerMenu';
import FeedbackCollection from '../../blocks/FeedbackCollection/src/FeedbackCollection';
import AcceptPrepayments2 from '../../blocks/AcceptPrepayments2/src/AcceptPrepayments2';
import Leaderboard from '../../blocks/Leaderboard/src/Leaderboard';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP.web';
import ConfirmPassword from '../../blocks/forgot-password/src/ConfirmPassword.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Library2 from '../../blocks/Library2/src/Library2';
import CfCustomizedVouchersCreation from '../../blocks/CfCustomizedVouchersCreation/src/CfCustomizedVouchersCreation';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import Home from '../../blocks/scheduling/src/Home.web';
import CfCardsSpecificDiscounts2 from '../../blocks/CfCardsSpecificDiscounts2/src/CfCardsSpecificDiscounts2';
import CfBusinessRules from '../../blocks/CfBusinessRules/src/CfBusinessRules';
import ShareCalendar from '../../blocks/ShareCalendar/src/ShareCalendar';
import LoyaltySystem2 from '../../blocks/LoyaltySystem2/src/LoyaltySystem2';
import Appointments from '../../blocks/appointmentmanagement/src/BookAppointment.web';
import AddAppointment from '../../blocks/appointmentmanagement/src/AddAppointment';
import OpenApi from '../../blocks/OpenApi/src/OpenApi';
import Documentation from '../../blocks/Documentation/src/Documentation';
import PeopleManagement2 from '../../blocks/PeopleManagement2/src/PeopleManagement2';
import Contactus from '../../blocks/contactus/src/Contactus.web';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import UserGroups from '../../blocks/UserGroups/src/UserGroups';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import CertificationTracking from '../../blocks/CertificationTracking/src/CertificationTracking';
import TaskAllocator from '../../blocks/TaskAllocator/src/TaskAllocator';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Maps from '../../blocks/maps/src/Maps';
import SplitPayments from '../../blocks/SplitPayments/src/SplitPayments';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import AccountTransfer from '../../blocks/AccountTransfer/src/AccountTransfer';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import ProjectTemplates from '../../blocks/ProjectTemplates/src/ProjectTemplates';
import CourseDetail from '../../blocks/ProjectTemplates/src/CourseDetail.web';
import TeachersDashboard8 from '../../blocks/TeachersDashboard8/src/TeachersDashboard8';
import TimesheetManagement from '../../blocks/TimesheetManagement/src/TimesheetManagement';
import CfRtaIntegration2 from '../../blocks/CfRtaIntegration2/src/CfRtaIntegration2';
import TaskGroups from '../../blocks/TaskGroups/src/TaskGroups';
import AutomaticCheckoutCalculation2 from '../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2';
import ProjecttaskTracking2 from '../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2';
import ItemAvailability from '../../blocks/ItemAvailability/src/ItemAvailability';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import Couponcodegenerator from '../../blocks/couponcodegenerator/src/Couponcodegenerator';
import Videos from '../../blocks/videos/src/Videos';
import Scoring from '../../blocks/Scoring/src/Scoring';
import CfEid from '../../blocks/CfEid/src/CfEid';
import TaskList from '../../blocks/TaskList/src/TaskList';
import StoreCredits from '../../blocks/StoreCredits/src/StoreCredits';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import Playlist4 from '../../blocks/Playlist4/src/Playlist4';
import Payments from '../../blocks/Payments/src/Payments';
import PaymentsMethod from '../../blocks/info-page/src/PaymentMethod.web';
import SuccessPayment from '../../blocks/info-page/src/SuccessPayment.web';
import Lectures from '../../blocks/dashboard/src/Lectures.web';
import MockTest from '../../blocks/QuestionBank/src/MockTest.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import ServicespecificSettingsadmin from '../../blocks/ServicespecificSettingsadmin/src/ServicespecificSettingsadmin';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import Referrals from '../../blocks/Referrals/src/Referrals';
import DashboardGuests from '../../blocks/DashboardGuests/src/DashboardGuests';
import FullTeamVisibility2 from '../../blocks/FullTeamVisibility2/src/FullTeamVisibility2';
import QuestionBank from '../../blocks/QuestionBank/src/QuestionBank';
import AllCourses from '../../blocks/QuestionBank/src/AllCourses.web';
import LanguageSupport from '../../blocks/LanguageSupport/src/LanguageSupport';
import CollectTransactionFees from '../../blocks/CollectTransactionFees/src/CollectTransactionFees';
import VideoLibrary from '../../blocks/VideoLibrary/src/VideoLibrary';
import Analytics from '../../blocks/analytics/src/Analytics';
import Customform from '../../blocks/customform/src/Customform';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import PledgeTracking from '../../blocks/PledgeTracking/src/PledgeTracking';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Addresses from '../../blocks/addressmanagement/src/Addresses';
import AddAddress from '../../blocks/addressmanagement/src/AddAddress';
import Notifications from '../../blocks/notifications/src/Notifications';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Document from '../../blocks/info-page/src/Document.web';
import SpecialOffer from '../../blocks/info-page/src/SpecialOffer.web';
import PrivacyPolicy from '../../blocks/info-page/src/PrivacyPolicy.web';
import HelpAndFAQ from '../../blocks/info-page/src/HelpAndFAQ.web';
import RequestCallback from '../../blocks/email-account-registration/src/RequestCallback.web';
import About from '../../blocks/info-page/src/About.web';
import MyBookings from '../../blocks/dashboard/src/MyBookings.web';
import 'react-toastify/dist/ReactToastify.css';

const routeMap = {
  Home: {
    component: Home,
    path: '/',
    exact: true
  },
  AllCourses:{
    component: AllCourses,
    path:"/AllCourses"
  },
  MyBookings: {
    component: MyBookings,
    path: '/MyBookings'
  },
  Document: {
    component: Document,
    path: '/Documents'
  },
  SpecialOffer: {
    component: SpecialOffer,
    path: '/SpecialOffer'
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy'
  },
  HelpAndFAQ: {
    component: HelpAndFAQ,
    path: '/FAQ'
  },
  RequestCallback: {
    component: RequestCallback,
    path: '/RequestCallback'
  },
  About: {
    component: About,
    path: '/About'
  },
  ConfirmPassword: {
    component: ConfirmPassword,
    path: '/ConfirmPassword'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  CfCommissionManagement2: {
    component: CfCommissionManagement2,
    path: '/CfCommissionManagement2'
  },
  RealtimeUpdates: {
    component: RealtimeUpdates,
    path: '/RealtimeUpdates'
  },
  SuccessPayment: {
    component: SuccessPayment,
    path: '/bx_block_payments/SuccessPayment'
  },
  PaymentsMethod: {
    component: PaymentsMethod,
    path: '/PaymentsMethod'
  },
  Lectures: {
    component: Lectures,
    path: '/Lectures'
  },
  Lectures: {
    component: MockTest,
    path: '/MockTest'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  CfLogicMappingAlgorithm: {
    component: CfLogicMappingAlgorithm,
    path: '/CfLogicMappingAlgorithm'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: '/CourseTypeDetails'
  },
  HamburgerMenu: {
    component: HamburgerMenu,
    path: '/HamburgerMenu'
  },
  FeedbackCollection: {
    component: FeedbackCollection,
    path: '/FeedbackCollection'
  },
  AcceptPrepayments2: {
    component: AcceptPrepayments2,
    path: '/AcceptPrepayments2'
  },
  Leaderboard: {
    component: Leaderboard,
    path: '/Leaderboard'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Library2: {
    component: Library2,
    path: '/Library2'
  },
  CfCustomizedVouchersCreation: {
    component: CfCustomizedVouchersCreation,
    path: '/CfCustomizedVouchersCreation'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  CfCardsSpecificDiscounts2: {
    component: CfCardsSpecificDiscounts2,
    path: '/CfCardsSpecificDiscounts2'
  },
  CfBusinessRules: {
    component: CfBusinessRules,
    path: '/CfBusinessRules'
  },
  ShareCalendar: {
    component: ShareCalendar,
    path: '/ShareCalendar'
  },
  LoyaltySystem2: {
    component: LoyaltySystem2,
    path: '/News&EventsDetails'
  },
  Appointments: {
    component: Appointments,
    path: '/Appointments'
  },
  AddAppointment: {
    component: AddAppointment,
    path: '/AddAppointment'
  },
  OpenApi: {
    component: OpenApi,
    path: '/OpenApi'
  },
  Documentation: {
    component: Documentation,
    path: '/Documentation'
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: '/PeopleManagement2'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  UserGroups: {
    component: UserGroups,
    path: '/UserGroups'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  CertificationTracking: {
    component: CertificationTracking,
    path: '/CertificationTracking'
  },
  TaskAllocator: {
    component: TaskAllocator,
    path: '/TaskAllocator'
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Maps: {
    component: Maps,
    path: '/LocationDetails'
  },
  SplitPayments: {
    component: SplitPayments,
    path: '/SplitPayments'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  AccountTransfer: {
    component: AccountTransfer,
    path: '/AccountTransfer'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  CourseDetail: {
    component: CourseDetail,
    path: '/CourseDetail'
  },
  TeachersDashboard8: {
    component: TeachersDashboard8,
    path: '/TeachersDashboard8'
  },
  TimesheetManagement: {
    component: TimesheetManagement,
    path: '/TimesheetManagement'
  },
  CfRtaIntegration2: {
    component: CfRtaIntegration2,
    path: '/CfRtaIntegration2'
  },
  TaskGroups: {
    component: TaskGroups,
    path: '/TaskGroups'
  },
  AutomaticCheckoutCalculation2: {
    component: AutomaticCheckoutCalculation2,
    path: '/AutomaticCheckoutCalculation2'
  },
  ProjecttaskTracking2: {
    component: ProjecttaskTracking2,
    path: '/ProjecttaskTracking2'
  },
  ItemAvailability: {
    component: ItemAvailability,
    path: '/CoursesTypes'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  Couponcodegenerator: {
    component: Couponcodegenerator,
    path: '/Couponcodegenerator'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  Scoring: {
    component: Scoring,
    path: '/Scoring'
  },
  CfEid: {
    component: CfEid,
    path: '/CfEid'
  },
  TaskList: {
    component: TaskList,
    path: '/News&Events'
  },
  StoreCredits: {
    component: StoreCredits,
    path: '/StoreCredits'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  Playlist4: {
    component: Playlist4,
    path: '/Playlist4'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  ServicespecificSettingsadmin: {
    component: ServicespecificSettingsadmin,
    path: '/ServicespecificSettingsadmin'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  Referrals: {
    component: Referrals,
    path: '/Referrals'
  },
  DashboardGuests: {
    component: DashboardGuests,
    path: '/DashboardGuests'
  },
  FullTeamVisibility2: {
    component: FullTeamVisibility2,
    path: '/FullTeamVisibility2'
  },
  QuestionBank: {
    component: QuestionBank,
    path: '/QuestionBank'
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: '/LanguageSupport'
  },
  CollectTransactionFees: {
    component: CollectTransactionFees,
    path: '/CollectTransactionFees'
  },
  VideoLibrary: {
    component: VideoLibrary,
    path: '/VideoLibrary'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  PledgeTracking: {
    component: PledgeTracking,
    path: '/PledgeTracking'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Addresses: {
    component: Addresses,
    path: '/Addresses'
  },
  AddAddress: {
    component: AddAddress,
    path: '/AddAddress'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  }
  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "*/AlertWeb",
  //   modal: false
  // }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 576,
          md: 768,
          lg: 992,
          xl: 1200
        }
      }
    });

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <>
        <ToastContainer
          autoClose={3000}
          position="top-right"
          className="toast-container"
          toastClassName="dark-toast"
        />
        <TopNav />
        <ThemeProvider theme={theme}>
          {WebRoutesGenerator({ routeMap })}
        </ThemeProvider>
        <Footer />
      </>
    );
  }
}

export default App;
