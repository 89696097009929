import React from "react";
// Customizable Area Start
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Box } from "@material-ui/core";
import WhatsappIcon from "../../../components/src/WhatsappIcon";
export const baseurl = require('../../../../packages/framework/src/config');
export const configJSONUrl = require("../../../framework/src/config");
// Customizable Area End

import TaskListController, {
  Props,
  configJSON,
} from "./TaskListController";

export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getCmsApi()
    this.fetchSpecialOffers()
    window.scrollTo(0, 0);
    // Customizable Area End
  }
  fetchSpecialOffers = () => {
    this.setState({ isLoading: true });
    fetch(configJSONUrl.baseURL + "bx_block_news_and_event/news_and_events/4")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong, Please try again');
      })
      .then((json) => {
        const data = json.data
        console.log(data)

      }).catch((error) => {


      })

  };



  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <CommonWrapper className="document common">
        <div className="first-section" style={{ backgroundImage: this.state.CmsData.image ? `url(${this.state.CmsData.image})` : "url('assets/images/news_events.png')" }}>
          <div>
            <div className="content">
              <h2 className="title">{this.state.CmsData.title ? this.state.CmsData.title : "News and Events"}</h2>
              <h5 className="sub-title">{this.state.CmsData.sub_title ? this.state.CmsData.sub_title : "Browse Through our Latest News & Events"}</h5>
            </div>
          </div>
          <Box position="absolute" right={{ xs: "20px", sm: "30px", md: "50px" }} bottom={{ xs: "20px", sm: "30px", md: "50px" }}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
        <section className="mt80 pb30">
          <Container>
            <div className="row">
              <div className="col-sm-12">
                <ul className="news-navs nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a className="nav-link active" id="pills-news-tab" data-toggle="pill" href="#pills-news" role="tab" aria-controls="pills-news" aria-selected="true">News</a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="pills-events-tab" data-toggle="pill" href="#pills-events" role="tab" aria-controls="pills-events" aria-selected="false">Events</a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="pills-videos-tab" data-toggle="pill" href="#pills-videos" role="tab" aria-controls="pills-videos" aria-selected="false">Videos</a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-news" role="tabpanel" aria-labelledby="pills-news-tab">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news1.png" />
                          <div className="news-events-text">
                            <h4>News Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news2.png" />
                          <div className="news-events-text">
                            <h4>News Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news3.png" />
                          <div className="news-events-text">
                            <h4>News Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news1.png" />
                          <div className="news-events-text">
                            <h4>News Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news2.png" />
                          <div className="news-events-text">
                            <h4>News Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news3.png" />
                          <div className="news-events-text">
                            <h4>News Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="pills-events" role="tabpanel" aria-labelledby="pills-events-tab">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news1.png" />
                          <div className="news-events-text">
                            <h4>Events Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news2.png" />
                          <div className="news-events-text">
                            <h4>Events Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news3.png" />
                          <div className="news-events-text">
                            <h4>Events Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news1.png" />
                          <div className="news-events-text">
                            <h4>Events Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news2.png" />
                          <div className="news-events-text">
                            <h4>Events Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news3.png" />
                          <div className="news-events-text">
                            <h4>Events Title One</h4>
                            <p>Short byline here…Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.</p>
                            <Link to="/News&EventsDetails">Read More</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="pills-videos" role="tabpanel" aria-labelledby="pills-videos-tab">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news1.png" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news2.png" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news3.png" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news1.png" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news2.png" />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="news-events-card">
                          <img src="assets/images/news3.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </CommonWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CommonWrapper = styled.main`
.nav-item {
  margin-bottom: 10px;
}
        `

const BannerSection = styled.section`
        background-size: cover;
        width: 100%;
        min-height: 717px;
        position: relative;
        display:flex;
        flex-flow: column;
        flex-wrap:wrap;
        justify-content:space-evenly;
        `;

const Container = styled.div`
        width: 80%;
        margin: auto;
        `;

// Customizable Area End
