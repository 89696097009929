import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiShuffle } from "react-icons/bi";
import ItemAvailabilityController, {
  Props,
  configJSON
} from "./ItemAvailabilityController";
import Spinner from "../../../components/src/Spinner.web";
export const configJSONUrl = require("../../../framework/src/config");
import WhatsappIcon from '../../../components/src/WhatsappIcon';
let authToken: any;

const  { baseURL } = require("../../../framework/src/config");
// Customizable Area End

export default class ItemAvailability extends ItemAvailabilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    authToken = localStorage.getItem('authToken');
    window.scrollTo(0, 0);
    this.getCmsDetail();
    this.fetchCourses();
  }

  fetchCourses = () => {
    this.setState({ isLoading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "token": authToken
      }
    }
    
    fetch(configJSONUrl.baseURL + '/courses',requestOptions).then((res => {
      if (res.ok) {
        return res.json();
      }
      throw new Error('Something went wrong, Please try again.');
    }))
      .then((json) => {
        this.setState({ CoursesTypes: json.data,
          isLoading: false
        })

      }).catch((error) => {

        this.setState({
          err: error,
          isLoading: false
        })
      })
  }

  getCmsDetail = () => {
    const fetchUrl: any = `${baseURL}/bx_block_dashboard/page_titles/:id?name=course_type`
    fetch(fetchUrl)
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          detail: responseJson?.data?.attributes,
          isLoading: false
        })
      }).catch((error) =>{
        this.setState({ isLoading: false });
      });
  }

  setCourseName = (name:string) => {
    localStorage.setItem("name",name);
  }
  // Customizable Area End

  render() {
    let { err, isLoading } = this.state;
    return (
      // Customizable Area Start 
      <CommonWrapper className="document common">
        {isLoading && <Spinner spinnerModal={isLoading} />}
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url('assets/images/news_events.png')`}}>
          <div>
            <div className="content">
              <h2 className="title">{this.state.detail?.title ?? "Courses"}</h2>
              <h5 className="sub-title">{this.state.detail?.sub_title ?? "Available courses"}</h5>
            </div>
           </div>
           <Box position="absolute" right={{xs:"20px",sm:"30px",md:"50px"}} bottom={{xs:"20px",sm:"30px",md:"50px"}}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img"/>
          </Box>
        </div>
        <section className="mt80 pb30">
          <Container>
          {err ? <h1 style={{ textAlign: "center",fontSize:"22px" }}>{err.message}</h1> :
            <div className="row">
              <div className="col-sm-12 m-auto">
                <div className="row">
                  {this.state.CoursesTypes.map((data: any, index: any) => (
                    <div className="col-sm-6 col-lg-6" key={index}>
                      <div className="courses-cards">
                        {data?.attributes?.icon
                          ? <img src={baseURL + data?.attributes?.icon} style={{height:"33px",marginBottom:"40px"}} />
                          : <BiShuffle />
                        }
                        <h3>{data.attributes.name}</h3>
                        <h6>{data.attributes.detail}</h6>
                        <p>Fees: AED {" "}
                          {data?.attributes?.name === "Regular" || data?.attributes?.name === "Flexi"
                            ? Number(data?.attributes?.Fees?.reg_total_amount).toFixed(2)
                            : Number(data?.attributes?.Fees).toFixed(2)
                          }
                        </p>
                        <Link to={`/CourseTypeDetails?id=${data.id}`} ><button onClick={()=>this.setCourseName(data?.attributes?.name)}>View Details</button></Link>
                      </div>
                    </div>
                    ))}
                </div>
              </div>
            </div>
          }
          </Container>
        </section>
      </CommonWrapper>
      // Customizable Area End 
    );
  }
}

// Customizable Area Start
const CommonWrapper = styled.main`
`
const BannerSection = styled.section`
background-image: url('assets/images/news_events.png');
background-size: cover;
width: 100%;
min-height: 650px;
position: relative;
display:flex;
flex-flow: column;
flex-wrap:wrap;
justify-content:space-evenly;
`;

const Container = styled.div`
max-width: 80%;
margin: auto;
`;
// Customizable Area End
