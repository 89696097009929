import React from "react";

// import {
//   Container,
//   Box,
//   Input,
//   Button,
//   InputLabel,
//   Typography,
//   InputAdornment,
//   IconButton,
//   // Customizable Area Start
//   // Customizable Area End
// } from "@material-ui/core";

// Customizable Area Start
import styled from "styled-components";
import {BsCheckCircleFill} from "react-icons/bs"
import {AiOutlineStar} from "react-icons/ai";
import { Link } from "react-router-dom";
// import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import Visibility from "@material-ui/icons/Visibility";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#fff",
//       contrastText: "#fff",
//     },
//   },
//   typography: {
//     h6: {
//       fontWeight: 500,
//     },
//     subtitle1: {
//       margin: "20px 0px",
//     },
//   },
// });
// Customizable Area End

import ProjectTemplatesController, {
  Props,
  configJSON,
} from "./ProjectTemplatesController";
import WhatsappIcon from "../../../components/src/WhatsappIcon";

export default class ProjectTemplates extends ProjectTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <CommonWrapper>
        <BannerSection>
          <Container>
            <div className="row">
              <div className="col-sm-6">
                <div className="banner-heads">
                  <h2>Motor Car</h2>
                  <p>Detailed information of motor car driving course</p>
                </div>
              </div>
              <div className="col-sm-6">
              </div>
            </div>
          </Container>
          <WhatsappIcon />
        </BannerSection>
        <section className="mt80 pb60">
          <Container>
            <div className="row">
              <div className="col-sm-12">
                <p className="pera-news-detail mb30">Let’s get you started with our motorcycle course, whether you are looking to pick up a new hobby or a more flexible mode of commute.</p>
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Lets Get Started: Your Motorcycle License</h2>
                  </div>
                </div>
                <p className="pera-news-detail mb30"><b>1. </b> Students who complete this motorcycle driving course are permitted to drive automatic and manual transmission motorcycles.</p>
                <p className="pera-news-detail mb30"><b>2. </b> Learn balancing, control and positioning of your vehicle on roads. Further hone your skills by learning turning and cornering at high speed curves on highways and winding roads.</p>
                <p className="pera-news-detail mb30"><b>3. </b> Fresh learner? Enroll for 20 hours to be eligible for the final test.</p>
                <p className="pera-news-detail mb30"><b>4. </b> Students are required to be dressed appropriately with safety shoes, full length sleeves shirt/t-shirt and full trousers, while riding.</p>
                <p className="pera-news-detail mb30"><b>5. </b> Have a valid two-year-old motorcycle license from your home country? You need to attend only 10 hours of lessons provided that the bike license is attested from the home country embassy/consulate if in handwritten and/or in book format.</p>
              </div>
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Let’s get organized: documents & requirements for motorcycle driving license in dubai</h2>
                  </div>
                </div>
                <p className="pera-news-detail mb30"><b>1. </b> Original Emirates ID</p>
                <p className="pera-news-detail mb30"><b>2. </b> Eye test must be completed before RTA file opening.</p>
                <p className="pera-news-detail mb30"><b>3. </b> A valid visa issued in Dubai</p>
                <p className="pera-news-detail mb30"><b>4. </b> Valid original other country motorbike driving license of the same category (if any): License must be legally translated only from Dubai if not printed in English/Arabic. The motorcycle license must be attested from the home country embassy/consulate if in handwritten and/or in book format.</p>
              </div>
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Female learners wanting to take motorcycle driving lessons from a male instructor:</h2>
                  </div>
                </div>
                <p className="pera-news-detail mb30">If you are less than 21 Years of Age then you need to visit our counter to issue No Objection Certificate.</p>
              </div>
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Let’s talk money</h2>
                  </div>
                </div>
                <p className="pera-news-detail mb30">Let’s go driving with our regular or a flexible plan designed keeping you in mind!</p>
              </div>
              <div className="col-sm-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star"><AiOutlineStar /></div>
                  <div className="all-courses-header">
                    <h3>Regular</h3>
                    <p>AED 5752.65</p>
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>All 7 days from Sun - Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 14 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>12 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30 , 20:00 - 23:00</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 140</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <span>RTA Fees - 1,350
                            EDC Fees - 4,193 + 5% VAT
                            Total Fees - 5,752.65</span>
                    </div>
                  </div>
                  <Link style={{textDecoration:"none", color:"#ffff"}} to={'/EmailAccountLoginBlock'}> <button> Choose Flexi</button></Link>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star"><AiOutlineStar /></div>
                  <div className="all-courses-header">
                    <h3>Flexi</h3>
                    <p>AED 5752.65</p>
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>All 7 days from Sun - Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 14 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>12 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30 , 20:00 - 23:00</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 140</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill/>
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <span>RTA Fees - 1,350
                            EDC Fees - 4,193 + 5% VAT
                            Total Fees - 5,752.65</span>
                    </div>
                  </div>
                  <Link style={{textDecoration:"none", color:"#ffff"}} to={'/EmailAccountLoginBlock'}> <button> Choose Flexi</button></Link>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</h2>
                  </div>
                </div>
                <p className="pera-news-detail mb30">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sagittis arcu at orci ultricies, eu euismod ipsum semper. Nunc malesuada tristique tellus a pretium. Pellentesque porttitor tellus in molestie sagittis. Morbi pharetra nunc risus, vitae fringilla eros euismod vel. Donec arcu risus, scelerisque rutrum vehicula sit amet, luctus vel odio.</p>
              </div>
            </div>
          </Container>
        </section>
      </CommonWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CommonWrapper = styled.main`
`
const BannerSection = styled.section`
background-image: url('assets/images/vehicle_detail.png');
background-size: cover;
width: 100%;
min-height: 650px;
position: relative;
display:flex;
flex-flow: column;
flex-wrap:wrap;
justify-content:space-evenly;
`;

const Container = styled.div`
width: 80%;
margin: auto;
`;

// Customizable Area End
