import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import axios from 'axios'
// Customizable Area End

export const configJSON = require("./config");
export const baseurl = require('../../../../packages/framework/src/config');

export interface Props {
    navigation?: any;
    id?: string;
    location: any;
    history: any;
    match: any;
}


export interface S {
    // Customizable Area Start
    loading: boolean;
    data: string;
    documentArr: any[],
    invoicID: any,
    Transactiontype: any,
    AmountPaid: any,
    invoiceAmount: any,
    totalAmount: any,
    totalDiscount: any,
    totalPrice: any,
    netAmount: any,
    accountId: any,
    InvoiceDate: any,
    modelState: any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SuccessPaymentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            loading: false,
            data: "",
            documentArr: [],
            invoicID: "",
            Transactiontype: "",
            AmountPaid: "",
            invoiceAmount: "",
            totalAmount: "",
            totalDiscount: "",
            totalPrice: "",
            netAmount: "",
            modelState: false,
            accountId: "",
            InvoiceDate: ""
            // Customizable Area End
        };
    }

    async componentDidMount() {
        this.getSuccessPayment()
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        window.scrollTo(0, 0);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson && responseJson.data) {
                if (apiRequestCallId === this.successPaymentAPiCallId) {
                    if (responseJson.data.attributes.status === "completed") {
                        toast.success("We Have Received Your Payment");
                        this.setState({
                            accountId: responseJson.data.attributes.account_id,
                            invoiceAmount: responseJson.data.attributes.order_data.amount_total,
                            Transactiontype: responseJson.data.attributes.order_data.payment_method_types[0],
                            netAmount: responseJson.data.attributes.order_data.amount_total,
                            totalAmount: responseJson.data.attributes.order_data.amount_total,
                            InvoiceDate: responseJson.data.attributes.updated_at
                        })
                        console.log(responseJson.data.attributes, "responseJson --- > sucesspayement")
                        console.log(this.state, "responseJson --- > state")
                        setTimeout(() => {
                            console.log(this.state, "responseJsonI --- > state after")
                            this.CreateInvoice()
                        }, 2000);
                    }
                }
                else if (apiRequestCallId === this.InvoiceCreate) {
                    setTimeout(() => {
                        this.setState({
                            invoicID: responseJson.data.id
                        })
                        this.InvoiceShow()
                    }, 2000);
                    console.log(responseJson, "responseJson --- > invoice cretae")
                }
            }
            else {
                this.parseApiErrorResponse(responseJson);
            }
            this.setState({ loading: false });
        }
        this.parseApiCatchErrorResponse(errorReponse);
        // Customizable Area End
    }

    // Customizable Area Start
    InvoiceCreate: string = "";
    successPaymentAPiCallId: string = "";
    ShowInvoice: string = "";
    DownloadString: string = ""

    downloadFile = (blob: any, fileName: any) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 500);
        setTimeout(() => {
            this.setState({
                modelState: true
            })
        }, 2000);
    };
    BtnClick = async () => {
        toast.warning("Please wait");
        const localToken = localStorage.getItem("authToken") as string
        const Courseid = localStorage.getItem("id") as any
        try {
            fetch(`${baseurl.baseURL}/bx_block_invoice/invoices/invoice_pdf?subscription_plan_id=${Courseid}&id=${this.state.invoicID}`, { headers: { token: localToken, "accept": "application/pdf" } })
                .then((response) => response.blob())
                .then((data) => {
                    // console.log(data);
                    console.log(this.state.invoicID, "DATAAPI invoicID")
                    this.downloadFile(data, 'ECO_DRIVE_INVOICE.pdf');
                }).catch((errror) => console.log(errror, "pdf error"));

        } catch (error) {
            console.log(error, "erro")
        }
    }

    modelClose = () => {
        this.setState({
            modelState: false
        })
        this.props.navigation.navigate("Home")
    }

    getSuccessPayment = () => {
        const localToken = localStorage.getItem("authToken") as string
        const header = {
            "Content-Type": "application/json",
            token: localToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.successPaymentAPiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_payments/payments/get_stripe_payment"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    CreateInvoice = () => {
        this.setState({ loading: true });
        const localToken = localStorage.getItem("authToken") as string
        const amout = localStorage.getItem("amount") as any
        const header = {
            "Content-Type": configJSON.contentTypeApiDocument,
            token: localToken
        };
        const SendData = localStorage.getItem("sendItem") as any
        const myObject = localStorage.getItem("myObject") as any
        const vat = localStorage.getItem('vat') as any
        const id = localStorage.getItem("id") as any
        const Count = localStorage.getItem('count') as any
        const ApiData = JSON.parse(SendData)
        const myObjectData = JSON.parse(myObject)
        const net = localStorage.getItem("net") as any
        const httpBody = {
            "data": {
                "attributes": {
                    "licence_type": ApiData.licence_type,
                    "course_type": myObjectData.name,
                    "gear_type": ApiData.gear_type,
                    "transaction_type": this.state.Transactiontype,
                    "stage": "Registration",
                    "company_phone": "97155864821",
                    "trn": "CRP745896",
                    'subscription_plan_id': id,
                    "s_no": "01",
                    "supply_place": "Dubai",
                    "invoice_amount": amout, //--> paymenbl
                    "total_amount": net, //--
                    "total_discount": 0.00,
                    "total_price": net, //---
                    "net_amount": net, //---
                    "vat": vat,
                    "quantity": Count,
                    "account_id": this.state.accountId
                }
            }
        }
        setTimeout(() => {
            console.log(httpBody, "responseJsonI -- > http body")
        }, 2000);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.InvoiceCreate = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_invoice/invoices"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    InvoiceShow = () => {
        const localToken = localStorage.getItem("authToken") as string
        const header = {
            "Content-Type": "application/json",
            token: localToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.ShowInvoice = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_invoice/invoices/${this.state.invoicID}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    downloadDocument = (e: any, name: string, url: string) => {
        e.stopPropagation();
        let link = document.createElement("a");
        link.href = url;
        link.download = name;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Customizable Area End
}