import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Container,
  Grid,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import moment from "moment";
import Spinner from "../../../components/src/Spinner.web";
const fileIcon = require('../assets/Group 237.png');

import DocumentController, { Props } from "./DocumentController.web";
const  { baseURL } = require("../../../framework/src/config");
// Customizable Area End

const documentBox={
  boxShadow:"0 3px 6px 0 rgba(0, 0, 0, 0.16)",
  borderRadius:"15px", 
  backgroundColor:"#FFF", 
  display:"flex",
  flexDirection:"column"
} as const;

const DownloadButton = styled(Button)({
  backgroundColor: "black",
  width:"100%",
  color: "white",
  borderRadius: "20px",
  fontWeight: "bold",
  textTransform: "none",
  fontFamily: 'ITC Avant Garde Gothic Std',
  "&:hover": {
    backgroundColor: "black",
  }
});

const titleStyle = {
  // display: "-webkit-box",
  overflow: "hidden",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis"
} as const;
export default class Document extends DocumentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine Start DefaultContainer
      <div className="documents common">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} /> }
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url('./assets/document.png')`}}>
          <div className="content">
            <h2 className="title">{this.state.detail?.title ?? "Documents"}</h2>
            <h5 className="sub-title">{this.state.detail?.sub_title ?? "Download our Helpful Guides"}</h5>
          </div>
          <Box position="absolute" right={{xs:"20px",sm:"30px",md:"50px"}} bottom={{xs:"20px",sm:"30px",md:"50px"}}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img"/>
          </Box>
        </div>
        <div className="second-section">
          <Box width="80%" margin="auto">
              <Box width="100%">
                <Grid container spacing={5} style={{display:"flex",justifyContent:"center"}}>
                  {!this.state.loading &&
                    <>
                      {this.state.documentArr.length !== 0 ? 
                        this.state.documentArr.map((item:any,index:number)=>{
                          return <Grid item xs={12} sm={6} lg={4} key={index}>
                            <Box style={documentBox} padding={{xs:"30px",lg:"35px"}}>
                              <img src={item?.attributes?.icon ? baseURL+item?.attributes?.icon : fileIcon} style={{height:"40px",width:"40px",marginBottom:"20px"}}/>
                              <Box fontSize={{xs:"20px", xl:"28px"}} fontWeight="700" marginBottom="15px" style={titleStyle}>
                                {item?.attributes?.title}
                              </Box>
                              <div>File Size - {item?.attributes?.file_size}</div>
                              <div style={{marginBottom:"20px"}}>Last Upload: {moment(item?.attributes?.last_upload).format('LL').replace(","," -")}</div>
                              <div>
                                <DownloadButton onClick={(event:any)=>this.downloadDocument(event, item?.attributes?.title, item?.attributes?.document)}>Download</DownloadButton>
                              </div>
                            </Box>
                          </Grid>
                          })
                        :<div>No Document Found!</div>
                      }
                    </>
                  }
                </Grid>
              </Box>
            </Box>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
