import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import { withRouter } from "react-router";
import styled from "styled-components";
import { BsCheckCircleFill } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import { Link } from "react-router-dom";
// Customizable Area End

import AllCoursesController, {
  Props,
} from "./AllCoursesController.web";

class AllCourses extends AllCoursesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    this.getCmsApi();
    // Customizable Area End
  }

  onNavigate = (name:string) =>{
    this.props.history.push({
        pathname: '/CourseDetail',
        state: { name }
      })
  }

  onNavigateToRegister = () => {
    this.props.history.push("/EmailAccountRegistration");
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <CommonWrapper className="allCourse common">
        <div className="first-section" style={{ backgroundImage: this.state.CmsData?.image ? `url(${this.state.CmsData.image})` : `url('assets/images/allcourses.png')`}}>
          <div className="content">
            <h2 className="title">{this.state.CmsData?.heading ?? "All Courses"}</h2>
            <h5 className="sub-title">{this.state.CmsData?.sub_heading ?? "Detailed information of motor car driving course"}</h5>
          </div>
          <Box position="absolute" right={{xs:"20px",sm:"30px",md:"50px"}} bottom={{xs:"20px",sm:"30px",md:"50px"}}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img"/>
          </Box>
        </div>
        <section className="second-section">
          <Container className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Car Course</h2>
                  </div>
                  <div className="light-btn">
                    <button className="more-detail-btn" onClick={()=>this.onNavigate("Car")}>More Details</button>
                  </div>
                </div>
                <p className="pera-news-detail mb30">
                  Gain theoretical knowledge before getting behind the wheel to
                  build a better match between your thoughts and predictions to
                  excel your practical understanding. Attending your theory
                  lessons is a must, without which you are not eligible to move
                  forward with your tests and practical training.
                </p>
                <p className="pera-news-detail mb30">
                  Want to learn from the comfort of your home? You can take
                  these driving lessons through our mobile app. All you need is
                  the Excellence Mobile App and an internet connection. All
                  course presentations will be available on the app for you to
                  refer to whilst studying for your theory test. Our lessons are
                  available in English, Arabic and Urdu for your better
                  understanding.
                </p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Regular</h3>
                    <p>AED 4,794</p>
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>Any 3 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 6 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>48 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 100</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,350</div>
                      <div>EDC Fees - 3,280 + 5% VAT</div>
                      <div>Total Fees - 4,794</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Regular</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Flexi</h3>
                    <p>AED 5,634</p>
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>All 7 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 14 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>12 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30, 20:00 - 23:00</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 140</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,350</div>
                      <div>EDC Fees - 4,080 + 5% VAT</div>
                      <div>Total Fees - 5,634</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Flexi</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Lumpsum</h3>
                    <p>AED 9,534.75</p>
                    {/* <div>(Licence Guaranteed)**</div> */}
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>Any 3 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 6 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>24 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>NA</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,350</div>
                      <div>EDC Fees - 7,795 + 5% VAT</div>
                      <div>Total Fees - 9,534.75</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Lumpsum</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>VIP</h3>
                    <p>AED 15,559.95</p>
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>All 7 days from San-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 56 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>48 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30, 20:00 - 23:00</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 200</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 2,430</div>
                      <div>EDC Fees - 13,000 + 5% VAT</div>
                      <div>Total Fees - 15,559.95</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose VIP</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Motor Bike Course</h2>
                  </div>
                  <div className="light-btn">
                  <button className="more-detail-btn" onClick={()=>this.onNavigate("Motor Bike")}>More Details</button>
                  </div>
                </div>
                <p className="pera-news-detail mb30">
                  Gain theoretical knowledge before getting behind the wheel to
                  build a better match between your thoughts and predictions to
                  excel your practical understanding. Attending your theory
                  lessons is a must, without which you are not eligible to move
                  forward with your tests and practical training.
                </p>
                <p className="pera-news-detail mb30">
                  Want to learn from the comfort of your home? You can take
                  these driving lessons through our mobile app. All you need is
                  the Excellence Mobile App and an internet connection. All
                  course presentations will be available on the app for you to
                  refer to whilst studying for your theory test. Our lessons are
                  available in English, Arabic and Urdu for your better
                  understanding.
                </p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Regular</h3>
                    <p>AED 4,584</p>
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>Any 3 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 6 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>48 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 100</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,350</div>
                      <div>EDC Fees - 3,080 + 5% VAT</div>
                      <div>Total Fees - 4,584</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Regular</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Flexi</h3>
                    <p>AED 5,214</p>
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>All 7 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 14 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>12 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30, 20:00 - 23:00</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 140</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,350</div>
                      <div>EDC Fees - 3,680 + 5% VAT</div>
                      <div>Total Fees - 5,214</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Flexi</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Lumpsum</h3>
                    <p>AED 6,684.50</p>
                    {/* <div>(Licence Guaranteed)**</div> */}
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>Any 3 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 6 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>24 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 100</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,350</div>
                      <div>EDC Fees - 5,090 + 5% VAT</div>
                      <div>Total Fees - 6,684.50</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Lumpsum</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Heavy Bus Course</h2>
                  </div>
                  <div className="light-btn">
                  <button className="more-detail-btn" onClick={()=>this.onNavigate("Heavy Bus")}>More Details</button>
                  </div>
                </div>
                <p className="pera-news-detail mb30">
                  Gain theoretical knowledge before getting behind the wheel to
                  build a better match between your thoughts and predictions to
                  excel your practical understanding. Attending your theory
                  lessons is a must, without which you are not eligible to move
                  forward with your tests and practical training.
                </p>
                <p className="pera-news-detail mb30">
                  Want to learn from the comfort of your home? You can take
                  these driving lessons through our mobile app. All you need is
                  the Excellence Mobile App and an internet connection. All
                  course presentations will be available on the app for you to
                  refer to whilst studying for your theory test. Our lessons are
                  available in English, Arabic and Urdu for your better
                  understanding.
                </p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Regular</h3>
                    <p>AED 5,944</p>
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>Any 3 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 6 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>48 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 150</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,450</div>
                      <div>EDC Fees - 4,280 + 5% VAT</div>
                      <div>Total Fees - 5,944</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Regular</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Lumpsum</h3>
                    <p>AED 11,364.32</p>
                    {/* <div>(Licence Guaranteed)**</div> */}
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>Any 3 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 6 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>24 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 120</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,450</div>
                      <div>EDC Fees - 9,865 + 5% VAT</div>
                      <div>Total Fees - 11,364.32</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Lumpsum</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Heavy Truck Course</h2>
                  </div>
                  <div className="light-btn">
                  <button className="more-detail-btn" onClick={()=>this.onNavigate("Heavy Truck")}>More Details</button>
                  </div>
                </div>
                <p className="pera-news-detail mb30">
                  Gain theoretical knowledge before getting behind the wheel to
                  build a better match between your thoughts and predictions to
                  excel your practical understanding. Attending your theory
                  lessons is a must, without which you are not eligible to move
                  forward with your tests and practical training.
                </p>
                <p className="pera-news-detail mb30">
                  Want to learn from the comfort of your home? You can take
                  these driving lessons through our mobile app. All you need is
                  the Excellence Mobile App and an internet connection. All
                  course presentations will be available on the app for you to
                  refer to whilst studying for your theory test. Our lessons are
                  available in English, Arabic and Urdu for your better
                  understanding.
                </p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Regular</h3>
                    <p>AED 6,154</p>
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>Any 3 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 6 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>48 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 150</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,450</div>
                      <div>EDC Fees - 4,480 + 5% VAT</div>
                      <div>Total Fees - 6,154</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Regular</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Lumpsum</h3>
                    <p>AED 12,385.75</p>
                    {/* <div>(Licence Guaranteed)**</div> */}
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>Any 3 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 6 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>24 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 120</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,450</div>
                      <div>EDC Fees - 10,415 + 5% VAT</div>
                      <div>Total Fees - 12,385.75</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Lumpsum</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Heavy Forklift Course</h2>
                  </div>
                  <div className="light-btn">
                  <button className="more-detail-btn" onClick={()=>this.onNavigate("Heavy Forklift")}>More Details</button>
                  </div>
                </div>
                <p className="pera-news-detail mb30">
                  Gain theoretical knowledge before getting behind the wheel to
                  build a better match between your thoughts and predictions to
                  excel your practical understanding. Attending your theory
                  lessons is a must, without which you are not eligible to move
                  forward with your tests and practical training.
                </p>
                <p className="pera-news-detail mb30">
                  Want to learn from the comfort of your home? You can take
                  these driving lessons through our mobile app. All you need is
                  the Excellence Mobile App and an internet connection. All
                  course presentations will be available on the app for you to
                  refer to whilst studying for your theory test. Our lessons are
                  available in English, Arabic and Urdu for your better
                  understanding.
                </p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Regular</h3>
                    <p>AED 5,944</p>
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>Any 3 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 6 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>48 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 100</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,450</div>
                      <div>EDC Fees - 4.280 + 5% VAT</div>
                      <div>Total Fees - 5,944</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Regular</button>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="all-courses-cards">
                  <div className="course-rating-star">
                    <AiOutlineStar />
                  </div>
                  <div className="all-courses-header">
                    <h3>Lumpsum</h3>
                    <p>AED 11,808.25</p>
                    {/* <div>(Licence Guaranteed)**</div> */}
                    <span>(5% VAT applicable on Excellence Fees)</span>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Days * </h5>
                      <span>Any 3 days from Sun-Sat</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training Hours per week *</h5>
                      <span>Up to 6 hours</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Cancellation/Re-scheduling</h5>
                      <span>24 hours prior to the class</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Class Timings</h5>
                      <span>08:30 - 17:30</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Training fees per hour</h5>
                      <span>AED 120</span>
                    </div>
                  </div>
                  <div className="discription-boxes">
                    <div className="discription-box1">
                      <BsCheckCircleFill />
                    </div>
                    <div className="discription-box2">
                      <h5>Fee Details</h5>
                      <div>RTA Fees - 1,450</div>
                      <div>EDC Fees - 9,865 + 5% VAT</div>
                      <div>Total Fees - 11,808.25</div>
                    </div>
                  </div>
                  <button onClick={this.onNavigateToRegister}>Choose Lumpsum</button>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </CommonWrapper>
      //Merge Engine End DefaultContainer
    );
  }
}

export default withRouter(AllCourses);
// Customizable Area Start

const CommonWrapper = styled.main``;
const BannerSection = styled.section`
  background-size: cover;
  width: 100%;
  min-height: 650px;
  position: relative;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Container = styled.div`
  max-width: 80%;
  margin: auto;
`;

// Customizable Area End
