import React from "react";
// Customizable Area Start
import { Box, InputBase, Button } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Select from "react-select";
import * as Yup from "yup";
import { Formik } from "formik";
import Spinner from "../../../components/src/Spinner.web";
import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";
import WhatsappIcon from "../../../components/src/WhatsappIcon";
// Customizable Area End

const CustomInputBase = styled(InputBase)({
  borderBottom: "1px solid #BBBCBC",
  padding: "0px",
  fontFamily: "ITC Avant Garde Gothic Std",
});

const ResetButton = styled(Button)({
  backgroundColor: "black",
  margin: "70px 0px 20px 0px",
  color: "white",
  borderRadius: "20px",
  fontWeight: "bold",
  textTransform: "none",
  fontFamily: "ITC Avant Garde Gothic Std",
  "&:hover": {
    backgroundColor: "black",
  },
});

const phoneCodeData = [
  { label: "+91", value: "+91" },
  { label:"+971", value:"+971" },
  // { label:"+973", value:"+973" },
  // { label:"+974", value:"+974" },
  // { label:"+975", value:"+975" },
];

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  forgotPasswordSchema = () => {
    return Yup.object().shape({
      phoneValue: Yup.string()
        .required("*Phone Number is required")
        .min(9, "*Enter valid phone number"),
    });
  };

  render() {
    return (
      //Merge Engine Start DefaultContainer
      <div className="reset-password common common-form">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <div className="first-section">
          <div className="content">
            <h2 className="title">Reset Password</h2>
            <h5 className="sub-title">Eco Drive – The driving institute.</h5>
          </div>
          <Formik
            initialValues={{
              phoneValue: this.state.phoneValue,
            }}
            validationSchema={this.forgotPasswordSchema}
            onSubmit={(values) => {
              this.forgotPassword();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                className="form"
              >
                <div className="box-field">
                  <Select
                    classNamePrefix="react-select"
                    isSearchable={false}
                    options={phoneCodeData}
                    value={
                      phoneCodeData.filter(
                        (option: any) =>
                          option.value === this.state.countryCodeSelected
                      )[0]
                    }
                    onChange={(event: any) =>
                      this.setState({
                        countryCodeSelected: event.value,
                        phoneError: "",
                      })
                    }
                  />
                  <div style={{ marginLeft: "20px", width: "100%" }}>
                    <CustomInputBase
                      fullWidth
                      placeholder="Phone Number*"
                      value={this.state.phoneValue}
                      onChange={(event: any) => {
                        const re = /^[0-9\b]+$/;
                        if (
                          (event.target.value === "" ||
                            re.test(event.target.value)) &&
                          event.target.value.length <= 10
                        ) {
                          setFieldValue("phoneValue", event.target.value);
                          this.setState({
                            phoneValue: event.target.value,
                            phoneError: "",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                {touched.phoneValue && errors.phoneValue && (
                  <div className="text-danger">{errors.phoneValue}</div>
                )}
                {this.state.phoneError && (
                  <div className="text-danger">*{this.state.phoneError}</div>
                )}
                <ResetButton type="submit" fullWidth>
                  Reset Password
                </ResetButton>
              </form>
            )}
          </Formik>
          <Box position="absolute" right={{xs:"20px",sm:"30px",md:"50px"}} bottom={{xs:"20px",sm:"30px",md:"50px"}}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img"/>
          </Box>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
