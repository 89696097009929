import React from "react";

// Customizable Area Start
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { BsCheckCircleFill } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
// Customizable Area End

import CourseDetailController, {
  Props,
  configJSON,
} from "./CourseDetailController.web";
import WhatsappIcon from "../../../components/src/WhatsappIcon";

class CourseDetail extends CourseDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  onNavigateToRegister = () => {
    this.props.history.push("/EmailAccountRegistration");
  }

  fakeArray = [
    {
      name: "Car",
      course: [
        {
          courseName: "Regular",
          traningDays: "Any 3 days from Sun-Sat",
          trarningHourse: "Up to 6 hours",
          cancelHours: "48 hours prior to the class",
          ClassTimings: "08:30 - 17:30",
          Trainingfeesperhour: "AED 100",
          RTAFees: "1,350",
          EDCFees: "3,280",
          totalfees: "4,794",
        },
        {
          courseName: "Flexi",
          traningDays: "All 7 days from Sun-Sat",
          trarningHourse: "Up to 14 hours",
          cancelHours: "12 hours prior to the class",
          ClassTimings: "08:30 - 17:30, 20:00 - 23:00",
          Trainingfeesperhour: "AED 140",
          RTAFees: "1,350",
          EDCFees: "4,080",
          totalfees: "5,634",
        },
        {
          courseName: "Lumpsum",
          traningDays: "Any 3 days from Sun-Sat",
          trarningHourse: "Up to 6 hours",
          cancelHours: "24 hours prior to the class",
          ClassTimings: "08:30 - 17:30",
          Trainingfeesperhour: "NA",
          RTAFees: "1,350",
          EDCFees: "7,795",
          totalfees: "9,534.75",
        },
        {
          courseName: "VIP",
          traningDays: "All 7 days from San-Sat",
          trarningHourse: "Up to 56 hours",
          cancelHours: "48 hours prior to the class",
          ClassTimings: "08:30 - 17:30, 20:00 - 23:00",
          Trainingfeesperhour: "AED 200",
          RTAFees: "2,430",
          EDCFees: "13,000",
          totalfees: "15,559.95",
        },
      ],
    },
    {
      name: "Motor Bike",
      course: [
        {
          courseName: "Regular",
          traningDays: "Any 3 days from Sun-Sat",
          trarningHourse: "Up to 6 hours",
          cancelHours: "48 hours prior to the class",
          ClassTimings: "08:30 - 17:30",
          Trainingfeesperhour: "AED 100",
          RTAFees: "1,350",
          EDCFees: "3,280",
          totalfees: "4,584",
        },
        {
          courseName: "Flexi",
          traningDays: "All 7 days from Sun-Sat",
          trarningHourse: "Up to 14 hours",
          cancelHours: "12 hours prior to the class",
          ClassTimings: "08:30 - 17:30, 20:00 - 23:00",
          Trainingfeesperhour: "AED 140",
          RTAFees: "1,350",
          EDCFees: "3,680",
          totalfees: "5,214",
        },
        {
          courseName: "Lumpsum",
          traningDays: "Any 3 days from Sun-Sat",
          trarningHourse: "Up to 6 hours",
          cancelHours: "24 hours prior to the class",
          ClassTimings: "08:30 - 17:30",
          Trainingfeesperhour: "AED 100",
          RTAFees: "1,350",
          EDCFees: "5,090",
          totalfees: "6,684.50",
        },
      ],
    },
    {
      name: "Heavy Bus",
      course: [
        {
          courseName: "Regular",
          traningDays: "Any 3 days from Sun-Sat",
          trarningHourse: "Up to 6 hours",
          cancelHours: "48 hours prior to the class",
          ClassTimings: "08:30 - 17:30",
          Trainingfeesperhour: "AED 150",
          RTAFees: "1,450",
          EDCFees: "4,280",
          totalfees: "5,944",
        },
        {
          courseName: "Flexi",
          traningDays: "All 7 days from Sun-Sat",
          trarningHourse: "Up to 14 hours",
          cancelHours: "12 hours prior to the class",
          ClassTimings: "08:30 - 17:30, 20:00 - 23:00",
          Trainingfeesperhour: "AED 140",
          RTAFees: "1,350",
          EDCFees: "3,680",
          totalfees: "5,214",
        },
        {
          courseName: "Lumpsum",
          traningDays: "Any 3 days from Sun-Sat",
          trarningHourse: "Up to 6 hours",
          cancelHours: "24 hours prior to the class",
          ClassTimings: "08:30 - 17:30",
          Trainingfeesperhour: "AED 120",
          RTAFees: "1,450",
          EDCFees: "9,865",
          totalfees: "11,364.32",
        },
      ],
    },
    {
      name: "Heavy Truck",
      course: [
        {
          courseName: "Regular",
          traningDays: "Any 3 days from Sun-Sat",
          trarningHourse: "Up to 6 hours",
          cancelHours: "48 hours prior to the class",
          ClassTimings: "08:30 - 17:30",
          Trainingfeesperhour: "AED 150",
          RTAFees: "1,450",
          EDCFees: "4,280",
          totalfees: "6,154",
        },
        {
          courseName: "Lumpsum",
          traningDays: "Any 3 days from Sun-Sat",
          trarningHourse: "Up to 6 hours",
          cancelHours: "24 hours prior to the class",
          ClassTimings: "08:30 - 17:30",
          Trainingfeesperhour: "AED 120",
          RTAFees: "1,450",
          EDCFees: "9,865",
          totalfees: "12,385.75",
        },
      ],
    },
    {
      name: "Heavy Forklift",
      course: [
        {
          courseName: "Regular",
          traningDays: "Any 3 days from Sun-Sat",
          trarningHourse: "Up to 6 hours",
          cancelHours: "48 hours prior to the class",
          ClassTimings: "08:30 - 17:30",
          Trainingfeesperhour: "AED 150",
          RTAFees: "1,450",
          EDCFees: "4,280",
          totalfees: "5,944",
        },
        {
          courseName: "Lumpsum",
          traningDays: "Any 3 days from Sun-Sat",
          trarningHourse: "Up to 6 hours",
          cancelHours: "24 hours prior to the class",
          ClassTimings: "08:30 - 17:30",
          Trainingfeesperhour: "AED 120",
          RTAFees: "1,450",
          EDCFees: "9,865",
          totalfees: "11,808.25",
        },
      ],
    },
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <CommonWrapper className="document common">
        <div className="first-section" style={{ backgroundImage:`url("assets/images/vehicle_detail.png")` }}>
          <div>
            <div className="content">
              <h2 className="title">{this.state.course?.name}</h2>
              <h5 className="sub-title">Detailed information of {this.state.course?.name} driving course</h5>
            </div>
           </div>
           <Box position="absolute" right={{xs:"20px",sm:"30px",md:"50px"}} bottom={{xs:"20px",sm:"30px",md:"50px"}}>
            <img src={"assets/images/whatapp.png"} className="whatapp-img"/>
          </Box>
        </div>
        <section className="mt80 pb60">
          <Container>
            <div className="row">
              <div className="col-sm-12">
                <p className="pera-news-detail mb30">
                  Let’s get you started with our {this.state.course?.name} course, whether you
                  are looking to pick up a new hobby or a more flexible mode of
                  commute.
                </p>
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Lets Get Started: Your {this.state.course?.name} License</h2>
                  </div>
                </div>
                <p className="pera-news-detail mb30">
                  <b>1. </b> Students who complete this {this.state.course?.name} driving
                  course are permitted to drive automatic and manual
                  transmission motorcycles.
                </p>
                <p className="pera-news-detail mb30">
                  <b>2. </b> Learn balancing, control and positioning of your
                  vehicle on roads. Further hone your skills by learning turning
                  and cornering at high speed curves on highways and winding
                  roads.
                </p>
                <p className="pera-news-detail mb30">
                  <b>3. </b> Fresh learner? Enroll for 20 hours to be eligible
                  for the final test.
                </p>
                <p className="pera-news-detail mb30">
                  <b>4. </b> Students are required to be dressed appropriately
                  with safety shoes, full length sleeves shirt/t-shirt and full
                  trousers, while riding.
                </p>
                <p className="pera-news-detail mb30">
                  <b>5. </b> Have a valid two-year-old {this.state.course?.name} license from
                  your home country? You need to attend only 10 hours of lessons
                  provided that the bike license is attested from the home
                  country embassy/consulate if in handwritten and/or in book
                  format.
                </p>
              </div>
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>
                      Let’s get organized: documents & requirements for
                      {" "}{this.state.course?.name} driving license in dubai
                    </h2>
                  </div>
                </div>
                <p className="pera-news-detail mb30">
                  <b>1. </b> Original Emirates ID
                </p>
                <p className="pera-news-detail mb30">
                  <b>2. </b> Eye test must be completed before RTA file opening.
                </p>
                <p className="pera-news-detail mb30">
                  <b>3. </b> A valid visa issued in Dubai
                </p>
                <p className="pera-news-detail mb30">
                  <b>4. </b> Valid original other country motorbike driving
                  license of the same category (if any): License must be legally
                  translated only from Dubai if not printed in English/Arabic.
                  The {this.state.course?.name} license must be attested from the home country
                  embassy/consulate if in handwritten and/or in book format.
                </p>
              </div>
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>
                      Female learners wanting to take {this.state.course?.name} driving lessons
                      from a male instructor:
                    </h2>
                  </div>
                </div>
                <p className="pera-news-detail mb30">
                  If you are less than 21 Years of Age then you need to visit
                  our counter to issue No Objection Certificate.
                </p>
              </div>
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>Let’s talk money</h2>
                  </div>
                </div>
                <p className="pera-news-detail mb30">
                  Let’s go driving with our regular or a flexible plan designed
                  keeping you in mind!
                </p>
              </div>
              {this.fakeArray
                .filter((data:any) => data.name === this.state.course.name)
                .map((data: any) => data.course)
                .map((cards:any) =>
                  cards.map((card:any) => {
                    return (
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="all-courses-cards">
                          <div className="course-rating-star">
                            <AiOutlineStar />
                          </div>
                          <div className="all-courses-header">
                            <h3>{card.courseName}</h3>
                            <p>AED {card.totalfees}</p>
                            <span>(5% VAT applicable on Excellence Fees)</span>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Training Days * </h5>
                              <span>{card.traningDays}</span>
                            </div>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Training Hours per week *</h5>
                              <span>{card.trarningHourse}</span>
                            </div>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Cancellation/Re-scheduling</h5>
                              <span>{card.cancelHours}</span>
                            </div>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Class Timings</h5>
                              <span>{card.ClassTimings}</span>
                            </div>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Training fees per hour</h5>
                              <span>{card.Trainingfeesperhour}</span>
                            </div>
                          </div>
                          <div className="discription-boxes">
                            <div className="discription-box1">
                              <BsCheckCircleFill />
                            </div>
                            <div className="discription-box2">
                              <h5>Fee Details</h5>
                              <div>RTA Fees - {card.RTAFees}</div>
                              <div>EDC Fees - {card.EDCFees} + 5% VAT</div>
                              <div>Total Fees - {card.totalfees}</div>
                            </div>
                          </div>
                          <button onClick={this.onNavigateToRegister}>Choose {card.courseName}</button>
                        </div>
                      </div>
                    );
                  })
                )}
              <div className="col-sm-12">
                <div className="stretch-box mb30">
                  <div className="news-detail-box">
                    <h2>
                      Neque porro quisquam est qui dolorem ipsum quia dolor sit
                      amet
                    </h2>
                  </div>
                </div>
                <p className="pera-news-detail mb30">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                  sagittis arcu at orci ultricies, eu euismod ipsum semper. Nunc
                  malesuada tristique tellus a pretium. Pellentesque porttitor
                  tellus in molestie sagittis. Morbi pharetra nunc risus, vitae
                  fringilla eros euismod vel. Donec arcu risus, scelerisque
                  rutrum vehicula sit amet, luctus vel odio.
                </p>
              </div>
            </div>
          </Container>
        </section>
      </CommonWrapper>
      // Customizable Area End
    );
  }
}

export default withRouter(CourseDetail);

// Customizable Area Start

const CommonWrapper = styled.main``;
const BannerSection = styled.section`
  background-image: url("assets/images/vehicle_detail.png");
  background-size: cover;
  width: 100%;
  min-height: 650px;
  position: relative;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Container = styled.div`
  width: 80%;
  margin: auto;
`;

// Customizable Area End
