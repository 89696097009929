import React from "react";
// Customizable Area Start
import { 
  Button,
  Box 
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import OtpInput from "react-otp-input";
import ForgotPasswordOTPController, { Props } from "./ForgotPasswordOTPController.web";
import Spinner from "../../../components/src/Spinner.web";
// Customizable Area End

const ProceedButton = styled(Button)({
    backgroundColor: "black",
    margin: "70px 0px 20px 0px",
    color: "white",
    borderRadius: "20px",
    fontWeight: "bold",
    textTransform: "none",
    fontFamily: 'ITC Avant Garde Gothic Std',
    "&:hover": {
      backgroundColor: "black",
    },
});

export default class ForgotPasswordOTP extends ForgotPasswordOTPController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
       //Merge Engine DefaultContainer
       <div className="reset-password common common-form">
         {this.state.loading && <Spinner spinnerModal={this.state.loading} /> }
          <div className="first-section">
            <div className="content">
              <h2 className="title">OTP</h2>
              <h5 className="sub-title">Eco Drive – The driving institute.</h5>
            </div>
            <div className="form otp">
              <p>OTP*</p>
              <OtpInput
                containerStyle="otp-input-box"
                inputStyle="input-otp"
                className="input-otp-wrap"
                onChange={(otp:number)=>this.changeOtp(otp)}
                value={this.state.otp}
                numInputs={6}
                isInputNum
              />
              <span className="text-danger">{this.state.isButtonClicked && this.state.otpError}</span>
              <ProceedButton fullWidth onClick={()=> this.verifyOtp()}>Proceed</ProceedButton>
              {this.state.seconds === 0 ?
                <Box textAlign="end">
                  <span style={{color:"#B0B0B0",borderBottom:"1px solid #B0B0B0"}}>Didn't receive OTP?</span>
                  <span style={{cursor:"pointer",fontWeight:"bold"}} onClick={()=> this.resendOtp()}>{"  "}Resend</span>
                </Box>
                :<Box textAlign="end">
                  <span style={{color:"#B0B0B0",borderBottom:"1px solid #B0B0B0"}}>Resend OTP in</span> 
                  <span style={{fontWeight:"bold"}}>{"  "}00:{this.state.seconds < 10 ? "0" + this.state.seconds : this.state.seconds}</span>
                </Box>
              }
            </div>
          </div>
       </div>
     //Merge Engine End DefaultContainer
    );
  }
}
