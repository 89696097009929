import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
const  { baseURL } = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  location:any;
  history:any;
  match:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  CmsData: any;
  CmsDataDoorStep:any;
  specialOffers: any
  vehicleTypes: any
  countryCodeWeb:any;
  phoneNumber:any;
  enableSave:any;
  isLoading:boolean;
  iconToggle:any;
  details: any;
  err:any;
  err2:any;
  isSpecialOffers:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomeController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  serviceProviderDetailApiId:any;
  serviceProviderScheduleApiId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      specialOffers: [],
      vehicleTypes: [],
      enableSave:false,
      err:'',
      isSpecialOffers:true,
      countryCodeWeb:"+971",
      iconToggle:false,
      phoneNumber:'',
      err2:'',
      isLoading:false,
      details: {},
      CmsData:"",
      CmsDataDoorStep:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
  
        if (apiRequestCallId && responseJson) {
          
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };

  getCmsApi = () => {
    const fetchUrl: any = `${baseURL}/bx_block_dashboard/page_titles/:id?name=Home`
    fetch(fetchUrl)
      .then((response) => response.json())
      .then((ResponseJson) => {
        this.setState({
          CmsData: ResponseJson.data.attributes
        })
      }).catch((error) =>
        console.log(error, "AllCourses page")
      );
  }

  getCmsApiDoorStep = () => {
    const fetchUrl: any = `${baseURL}/bx_block_admin/section_two_listing?section=DoorStep`
    fetch(fetchUrl)
      .then((response) => response.json())
      .then((ResponseJson) => {
        if(ResponseJson && ResponseJson.data){
          this.setState({
            CmsDataDoorStep: ResponseJson?.data[0]?.attributes
          })
        }
      }).catch((error) =>
        console.log(error, "AllCourses page")
      );
  }

  // Customizable Area End
}