import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  InputBase,
  InputAdornment,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router";
import * as Yup from "yup";
import { Formik } from "formik";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Spinner from "../../../components/src/Spinner.web";
import "react-datepicker/dist/react-datepicker.css";

import BookAppointmentController, {
    Props,
    configJSON,
  } from "./BookAppointmentController.web";
// Customizable Area End

const CustomInputBase = styled(InputBase)({
    borderBottom: "1px solid #BBBCBC",
    padding: "0px",
    fontFamily: 'ITC Avant Garde Gothic Std'
});

const CustomButton = styled(Button)({
    borderRadius:"5px",
    textTransform:"none",
    border:"1px solid #979797",
    backgroundColor:"black",
    color:"white",
    marginRight:"10px",
    marginBottom:"10px",
    padding:"5px 15px",
    fontFamily: 'ITC Avant Garde Gothic Std'
});
 
const RegisterButton = styled(Button)({
    backgroundColor: "black",
    margin: "50px 0px 20px 0px",
    color: "white",
    borderRadius: "20px",
    fontWeight: "bold",
    textTransform:"none",
    fontFamily: 'ITC Avant Garde Gothic Std',
    "&:hover": {
      backgroundColor: "black",
    },
});

class BookAppointment extends BookAppointmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  appointmentSchema = () => {
    return Yup.object().shape({
      fullName: Yup.string().required("*Full Name is required"),
      phoneNumber: Yup.string().required("*Phone Number is required")
      .min(9,"*Enter valid phone number"),
      email: Yup.string()
      .email("*Enter valid email"),
      otherPurpose:Yup.string().when(
        "purpose",
        (value:any, schema:any) =>{ 
          if(value === "Other"){
            return schema.required("*Other purpose is required");
          }
      })
  });
  }
  // Customizable Area End
  
  render() {
    // Customizable Area Start
    const namePrefix = [
      { label: "MR.", value: "mr" },
      { label: "MRs.", value: "mrs" },
    ];
    
    const phoneCodeData =[
      { label: "+971", value: "+971" },
      { label: "+91", value: "+91" }
    ];

    const tabsList = [
      {
        key: 1,
        value: "File Open/Registration",
        label: "File Open/Registration",
      },
      {
        key: 2,
        value: "Test Booking",
        label: "Test Booking",
      },
      {
        key: 3,
        value: "Other",
        label: "Other",
      },
    ];
    
    return (
      <div className="appointment common common-form">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} /> }
        <div className="first-section" style={{ backgroundImage: this.state.detail?.image ? `url(${this.state.detail?.image})` : `url('assets/appointment.png')`}}>
          <div className="content">
            <h2 className="title">{this.state.detail?.title ?? "Book an Appointment"}</h2>
            <h5 className="sub-title">{this.state.detail?.sub_title ?? "Eco Drive – The driving institute."}</h5>
          </div>
          <Formik
            enableReinitialize={this.state.readData}
            initialValues={{
              fullName: this.state.fullName,
              phoneNumber: this.state.phoneNumber,
              email: this.state.email,
              purpose:this.state.purpose,
              otherPurpose: this.state.otherPurpose
            }}
            validationSchema={this.appointmentSchema}
            onSubmit={(values) => {
              this.submitHandler();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                className="form"
              >{this.state.readData && this.setState({ readData: false })}
                <Grid container className="center">
                  <Grid item xs={12} md={7}>
                    <Box paddingRight={{xs:"none",md:"80px"}}>
                    <Box mb={2}>
                    <div className="box-field">
                        <Select
                          classNamePrefix="react-select"
                          options={namePrefix}
                          isSearchable={false}
                          value={
                            namePrefix.filter(
                              (option: any) =>
                                option.value === this.state.namePrefix
                            )[0]
                          }
                          onChange={(event: any) =>
                            this.setState({ namePrefix: event.value })
                          }
                        />
                        <div style={{ marginLeft: "20px",width:"100%" }}>
                          <CustomInputBase
                            fullWidth
                            placeholder="Full Name*"
                            value={this.state.fullName}
                            onChange={(event: any) =>{
                              setFieldValue("fullName", event.target.value);
                              this.setState({ fullName: event.target.value })
                            }}
                          />
                        </div>
                      </div>
                      {touched.fullName && errors.fullName && 
                        <div className="text-danger">{errors.fullName}</div>
                      }
                    </Box>
                    <Box mb={3}>
                      <div className="box-field">
                        <Select
                          classNamePrefix="react-select"
                          options={phoneCodeData}
                          isSearchable={false}
                          value={
                            phoneCodeData.filter(
                              (option: any) =>
                                option.value === this.state.phoneCode
                            )[0]
                          }
                          onChange={(event: any) => {this.setState({ phoneCode: event.value })}}
                        />
                        <div style={{ marginLeft: "20px",width:"100%" }}>
                          <CustomInputBase
                            fullWidth
                            placeholder="Phone Number*"
                            value={this.state.phoneNumber}
                            onChange={(event: any) =>{
                              const re = /^[0-9\b]+$/;
                              if ((event.target.value === '' || re.test(event.target.value)) && event.target.value.length <=10) {
                                setFieldValue("phoneNumber", event.target.value);
                                this.setState({ phoneNumber: event.target.value });
                              }
                            }}
                          />
                        </div>
                      </div>
                      {touched.phoneNumber && errors.phoneNumber && 
                        <div className="text-danger">{errors.phoneNumber}</div>
                      }
                    </Box>
                    <Box mb={3}>
                      <CustomInputBase
                        fullWidth
                        startAdornment={<InputAdornment position="start" style={{marginRight:"45px"}}>Email</InputAdornment>}
                        placeholder="Email"
                        value={this.state.email}
                        onChange={(event: any) =>{
                          setFieldValue("email", event.target.value);
                          this.setState({ email: event.target.value });
                        }}
                      />
                      {touched.email && errors.email && 
                        <div className="text-danger">{errors.email}</div>
                      }
                    </Box>
                    </Box>
                      <Box mb="30px">
                        <p>Purpose</p>
                        <div>
                          {tabsList.map((item,i) => (
                              <CustomButton
                                key={i}
                                style={{
                                  backgroundColor: this.state.purpose === item.value ? "black":"white",
                                  color: this.state.purpose === item.value ? "white":"black"
                                }}
                                onClick={()=>{
                                  setFieldValue("purpose", item.value);
                                  this.setState({ purpose: item.value })
                                }}
                              >
                                <Typography>{item.label}</Typography>
                              </CustomButton>
                            ))}
                        </div>
                        {this.state.purpose === "Other" &&
                          <Box marginTop={1} paddingRight={{xs:"none",md:"80px"}}>
                            <CustomInputBase
                              fullWidth
                              placeholder="Other purpose*"
                              value={this.state.otherPurpose}
                              onChange={(event: any) =>{
                                setFieldValue("otherPurpose", event.target.value);
                                this.setState({ otherPurpose: event.target.value });
                              }}
                            />
                            {touched.otherPurpose && errors.otherPurpose && 
                              <div className="text-danger">{errors.otherPurpose}</div>
                            }
                          </Box>
                        }
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={5}>
                      <Box display="flex" justifyContent={{xs:"center",md:"end"}} mt={2}>
                      <DatePicker
                        selected={this.state.date}
                        minDate={new Date()}
                        onChange={(date:any)=>this.setState({ date : date })}
                        inline
                      />
                      </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RegisterButton type="submit" fullWidth>
                      Register Now
                    </RegisterButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

export default withRouter(BookAppointment);