import React, { Component } from 'react'
import { BlockComponent } from '../../../framework/src/BlockComponent';
import styled from "styled-components";
import { BsCheckCircle, BsClock } from "react-icons/bs"
import { RiVisaLine } from 'react-icons/ri'
import { VscCreditCard } from 'react-icons/vsc'
import { AiOutlineStar } from "react-icons/ai"
import { Button } from "@material-ui/core";
import { withRouter } from 'react-router';

import LecturesController, { Props } from './LecturesController';
import WhatsappIcon from '../../../components/src/WhatsappIcon';
import { Divider } from "@material-ui/core"
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from "@stripe/react-stripe-js"


// const stripePromis = loadStripe(
//     'pk_test_51KyFpyHdR4l61Wz0Chmlm4Q80ZG7tPcn7AU9OlFR3eG1MR0TotsDVtqUUEr3XZxMR2lE1vmApPCFGNTq9IP2dHqA00jJApUKvv'
// );
class Lectures extends LecturesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            <CommonWrapper>
                <BannerSection>
                    <Container>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="banner-heads">
                                    <h2>Lectures</h2>
                                    <p>Eco Drive - The driving institute</p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </Container>
                    <WhatsappIcon />
                </BannerSection>
                <section className="mt80 pb60">
                    <Container>
                        <div className="row">
                            <div className="col-sm-12">
                                <div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mainBlock">
                                                <div className="blackBox">
                                                    <div>
                                                        <div>
                                                            <h2 className="title">Therory Test 1</h2>
                                                        </div>
                                                        <span className='DivderDiv'>
                                                            <span>15 Feb 2022</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span>04:54 PM</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className='Paid'><BsCheckCircle /></div>
                                                    </div>
                                                </div>
                                                <div className="blackBox">
                                                    <div>
                                                        <div>
                                                            <h2 className="title">Therory Test 3</h2>
                                                        </div>
                                                        <span className='DivderDiv'>
                                                            <span>-</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span>-</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className='Paid'><BsClock /></div>
                                                    </div>
                                                </div>
                                                <div className="blackBox">
                                                    <div>
                                                        <div>
                                                            <h2 className="title">Therory Test 5</h2>
                                                        </div>
                                                        <span className='DivderDiv'>
                                                            <span>-</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span>-</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className='Paid'><BsClock /></div>
                                                    </div>
                                                </div>
                                                <div className="blackBox">
                                                    <div>
                                                        <div>
                                                            <h2 className="title">Therory Test 7</h2>
                                                        </div>
                                                        <span className='DivderDiv'>
                                                            <span>-</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span>-</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className='Paid'><BsClock /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mainBlock">
                                                <div className="blackBox">
                                                    <div>
                                                        <div>
                                                            <h2 className="title">Therory Test 2</h2>
                                                        </div>
                                                        <span className='DivderDiv'>
                                                            <span>-</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span>-</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className='Paid'><BsClock /></div>
                                                    </div>
                                                </div>
                                                <div className="blackBox">
                                                    <div>
                                                        <div>
                                                            <h2 className="title">Therory Test 4</h2>
                                                        </div>
                                                        <span className='DivderDiv'>
                                                            <span>-</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span>-</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className='Paid'><BsClock /></div>
                                                    </div>
                                                </div>
                                                <div className="blackBox">
                                                    <div>
                                                        <div>
                                                            <h2 className="title">Therory Test 6</h2>
                                                        </div>
                                                        <span className='DivderDiv'>
                                                            <span>-</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span>-</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className='Paid'><BsClock /></div>
                                                    </div>
                                                </div>
                                                <div className="blackBox">
                                                    <div>
                                                        <div>
                                                            <h2 className="title">Therory Test 8</h2>
                                                        </div>
                                                        <span className='DivderDiv'>
                                                            <span>-</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span>-</span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className='Paid'><BsClock /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section >
            </CommonWrapper >
        )
    }
}

export default Lectures;

const CommonWrapper = styled.main({
    '& .mainBlock': {
        borderRadius: '15px',
        color: '#fff',
        marginBottom: '30px',
    },
    '& .FristDiv': {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        padding: '5px 20px',
    },
    '& .blackBox': {
        padding: ' 30px 20px',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        background: '#161616',
        borderRadius: '15px',
        color: '#fff',
        marginBottom: '18px',
    },
    '& .Paid': {
        display: 'flex',
        alignItems: "center",
        svg: {
            fontSize: "28px"
        }
    },
    '& .Divider': {
        height: '100%',
        width: "1px",
        minHeight: "10px",
        backgroundColor: "#fffcfc",
    },
    '& .PaidText': {
        paddingLeft: "5px"
    },
    '& .MuiDivider-root': {
        backgroundColor: "lightgrey"
    },
    '& .DivderDiv': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: "12px",
        '& :first-child': {
            padding: "0 10px 0px 0",
        },
        '& :last-child': {
            padding: "0 0 0px 10px",
        },
        span: {
            fontSize: "14px",
            color: "lightgrey"
        }
    }
})
const BannerSection = styled.section`
background-image: url('assets/special-offer.png');
background-size: cover;
width: 100%;
min-height: 650px;
position: relative;
display:flex;
flex-flow: column;
flex-wrap:wrap;
justify-content:space-evenly;
`;

const Container = styled.div`
width: 80%;
margin: auto;
.title{
    font-size: 20px;
    color:white;
    font-weight: "500";
}
.PayBtn{
    width: 30%;
    background-color: #161616;
    border-radius: 25px;
    color:#fff;
    padding: 10px;
}
.PayBtn:hover{
    background-color: white;
    color: #161616;
    box-shadow: 0px 0px 0px 1px #161616;
    span{
        color:"#161616"
    }
}
@media only screen and (max-width: 430px) {
  .PayBtn{
      width: 100%;
  }
}
`;
