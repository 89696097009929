import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import DashboardController, { Props } from "./DashboardController";


// Customizable Area End

const CustomButton = styled(Button)({
  fontFamily: 'ITC Avant Garde Std',
  border:"1.5px solid black",
  borderRadius: "6px",
  color:"white",
  backgroundColor : "#333",
  width :"100px",
  fontWeight: "bold",
  textTransform: "none",
});

export default class MyBookings extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area 
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine Start DefaultContainer
       <div>
        <section className="special-offer common">
          <div className="first-section">
           <div className="content">
            <h3 className="title">My Bookings</h3>
            <h5 className="sub-title">Reference site lorem ipsum</h5>
           </div>
          </div>
        </section>
        <article>
          <section className="cards-button">
            <CustomButton>Scheduled</CustomButton>
            <CustomButton>Uncomplete</CustomButton>
            <CustomButton>Complete</CustomButton>
          </section>
          <section className="cards-container">
            <div className="box">
              <div className="box__inner">
                <h4 >Practical Training</h4>
                <h4>Stage - 1</h4>
                <p>Suggested hours : 2 hrs</p>
              </div>
              <div className="btn-bottom">
                <button className="schedule-btn">Schedule now </button>
              </div>
            </div>
            <div className="box">
            <div className="box__inner">
                <h4 >Practical Training Stage</h4>
                <h4> 2 to 3 </h4>
                <p>Suggested hours : 8 hrs</p>
              </div>
              <div className="btn-bottom">
                <button className="schedule-btn">Schedule now </button>
              </div>
            </div>
            <div className="box">
            <div className="box__inner">
                <h4 >Practical Training </h4>
                <h4> Night</h4>
                <p>Suggested hours : 2 hrs</p>
              </div>
              <div className="btn-bottom">
                <button className="schedule-btn">Schedule now </button>
              </div>
            </div>
            <div className="box">
            <div className="box__inner">
                <h4 >Initial Driving </h4>
                <h4> Test </h4>
                <p>Suggested hours : 4 hrs</p>
              </div>
              <div className="btn-bottom">
                <button className="schedule-btn">Schedule now </button>
              </div>
            </div>
            <div className="box">
            <div className="box__inner">
                <h4 > Practical Training </h4>
                <h4> Stage - 4 </h4>
                <p>Suggested hours : 4 hrs</p>
              </div >
              <div className="btn-bottom">
                <button className="schedule-btn">Schedule now </button>
              </div>
            </div>
            <div className="box">
            <div className="box__inner">
                <h4 > Assesment Yard Test</h4>
                <h4> Night</h4>
              </div>
              <div className="btn-bottom">
                <button className="schedule-btn">Schedule now </button>
              </div>
            </div>
          </section>
        </article>
       </div>
     
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
