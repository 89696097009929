import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface AddressDataType {
  latitude: number;
  longitude: number;
  address: string;
  address_type: string;
}

export interface AddressType {
  id: string;
  type: string;
  attributes: AddressDataType;
}

export interface AddressValueType {
  value: string;
}

export const AdressTypeData = [
  {
    value: "Home",
    label: "Home",
  },
  {
    value: "Work",
    label: "Work",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const licenceTypeList = [
  { label: "Car", value: "Car" },
  { label: "Motor Bike", value: "Motor Bike" },
  { label: "Heavy Bus", value: "Heavy Bus" },
  { label: "Heavy Truck", value: "Heavy Truck" },
  { label: "Heavy Forklift", value: "Heavy Forklift" },
];

export const lMELicenceTypeList = [
  { label: "Light Bus", value: "Light Bus"},
  { label: "Light Forklift", value: "Light Forklift"},
];
// Customizable Area End

interface S {
  // Customizable Area Start
  detail:any;
  vehicleTypeData: any[];
  allVehicleTypeData: any[];
  address: string;
  occupation: string;
  otherOccupation: string;
  visaProfession: string;
  otherVisaProfession: string;
  validDrivingLicence: any;
  licenceType: string;
  vehicleGear: string;
  validFrom: any;
  validTo: any;
  uaeLicencePicture: any;
  validLMVDrivingLicence: any;
  lvmLicenceType: string;
  lvmVehicleGear: string;
  validFrom2: any;
  validTo2: any;
  country: any;
  anotherLicencePicture: any;
  preview1: any;
  preview2: any;
  termAndConditionChecked: boolean;
  dataSource: any;
  validToErr:boolean;
  validTo2Err:boolean;
  submitClick: boolean;
  isEmiratePicture:boolean | null;
  readData: boolean;
  loading: boolean;

  txtInputAddressValue: string;
  txtInputLatValue: string;
  txtInputLngValue: string;
  token: any;
  addressTypeValue: string;
  addressList: Array<AddressType> | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddressManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiAddAddressCallId: string = "";
  apiGetAllAddressCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      detail:"",
      vehicleTypeData:[],
      allVehicleTypeData:[],
      address: "",
      occupation: "",
      otherOccupation: "",
      visaProfession: "",
      otherVisaProfession: "",
      validDrivingLicence: "",
      licenceType: "",
      vehicleGear:"",
      validFrom: "",
      validTo: "",
      uaeLicencePicture: "",
      preview1: "",
      preview2: "",
      validLMVDrivingLicence: "",
      lvmLicenceType: "",
      lvmVehicleGear:"",
      validFrom2: "",
      validTo2: "",
      country: "",
      anotherLicencePicture: "",
      termAndConditionChecked: false,
      dataSource: [],
      validToErr:false,
      validTo2Err:false,
      submitClick: false,
      isEmiratePicture: null,
      readData: false,
      loading: false,

      txtInputAddressValue: "",
      txtInputLatValue: "",
      txtInputLngValue: "",
      addressTypeValue: AdressTypeData[0].value,
      addressList: null,
      token: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.countryCodeApiCallId != null &&
      this.countryCodeApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.setState({
          dataSource: this.countryCodesToDropDown(responseJson.data),
        });
      }
    }else if(
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.vehicleTypeApiCallId != null &&
      this.vehicleTypeApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ){
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        let newArr:any[] = [];
        newArr = responseJson?.data.map((item:any)=>{
          return { label:item?.attributes?.name, value:item?.attributes?.name }
        });
        this.setState({
          vehicleTypeData: newArr
        },()=>this.setVehicleTypes());
      }
    }else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.uploadPictureCallID){
          this.setState({ loading: false });
          this.props.navigation.navigate("ItemAvailability");
        }
        else if (apiRequestCallId === this.imageUploadApiCallID){
          if(responseJson?.status === 500){
            if(this.state.isEmiratePicture){
              this.setState({ uaeLicencePicture: "" });
            }else{
              this.setState({ anotherLicencePicture: "" });
            }
            toast.warn("Please choose valid image.");
          }else{
            let from = new Date(responseJson?.issue_date.split("/").reverse().join("-"));
            let to = new Date(responseJson?.expiry_date.split("/").reverse().join("-"));
           
            if(this.state.isEmiratePicture){
              this.setState({
                validFrom: from,
                validTo: to,
                readData: true
              });
            }else{
              this.setState({ 
                validFrom2: from,
                validTo2: to,
                country: responseJson?.nationality,
                readData: true
              });
            }
          }
        }
        else if (apiRequestCallId === this.apiGetAllAddressCallId) {
          this.setState({ addressList: responseJson.data });
        }
        else if (apiRequestCallId === this.apiAddAddressCallId) {
            if(responseJson?.errors && responseJson?.errors[0]?.token){
              toast.error(responseJson?.errors[0]?.token);
            } else if (responseJson?.status === 500){
              toast.error("Something went wrong");
            }
            else {
              localStorage.removeItem("occupation");
              this.props.navigation.navigate("ItemAvailability");   
            }
            this.setState({ loading: false });
        } else if(apiRequestCallId === this.getDetailApiCallId){
          if (responseJson) {
            this.setState({ detail: responseJson?.data?.attributes });
          }
        }
        this.setState({ loading: false });
      }else if (responseJson && responseJson.errors) {
          if(responseJson.errors[0].token){
            toast.error(responseJson.errors[0].token);
          }
          if (apiRequestCallId === this.apiGetAllAddressCallId) {
            responseJson.errors.forEach((error: any) => {
              if (error["address"]) {
                this.showAlert(
                  configJSON.errorTitle,
                  error["address"].join(".")
                );
              }
            });
          }
          this.setState({ loading: false });
        }else if(responseJson?.status === 500){
          this.setState({ loading: false });
          toast.error("Something went wrong");
        }
        this.setState({ loading: false });
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      // load addresses
      this.loadAddresses(token);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  countryCodeApiCallId: string = "";
  uploadPictureCallID: string = "";
  imageUploadApiCallID: string = "";
  vehicleTypeApiCallId: string = "";
  getDetailApiCallId: string = "";
 
  vehicleGearData = [{ label: "Manual", value: "Manual" },{ label: "Automatic", value: "Automatic" }];
  manualOption = [{ label: "Manual", value: "Manual" }];
  automaticOption = [{ label: "Automatic", value: "Automatic" }];
  carGearOption:any[] = [];

  countryCodesToDropDown = (data: any) => {
    return (
      data &&
      data.map((item: any) => ({
        id: item.id,
        label: `${item.attributes.name}`,
        value: item.attributes.name,
      }))
    );
  };

  setVehicleTypes = () => {
    let data = localStorage.getItem("vehicleData");
    let obj = JSON.parse(data||"{}");
    let newArr = [...licenceTypeList, ...lMELicenceTypeList];
    this.carGearOption = this.vehicleGearData;
    if(obj?.type !== "Car"){
      newArr = newArr.filter(licenceType => licenceType.value !== obj?.type);
    }
    if(obj?.gear){
      this.carGearOption = obj?.gear === "Manual" ? this.automaticOption : this.manualOption;
    }
    this.setState({ vehicleTypeData: newArr, allVehicleTypeData:  licenceTypeList });
  }
  
  getDetails = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_dashboard/page_titles/:id?name=Add_Address"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCountryList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryApiEndPoint
    );

    const header = {
      "Content-Type": configJSON.getCountryApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCountryApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVehicleType = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    this.vehicleTypeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/vehicle_types"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  uploadPhoto = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadPictureCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_contact_detail/contact_details/image_upload"
    );
    
    let formData = new FormData();

    if(this.state.uaeLicencePicture){
      formData.append("avatar",this.state.uaeLicencePicture);
    }

    if(this.state.anotherLicencePicture){
      formData.append("uae_licence_avatar",this.state.anotherLicencePicture);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  image_upload_Api = async () => {
    
    this.setState({ loading: true });

    const header = {
      token: ""
    };

    var formData = new FormData();
    if(this.state.isEmiratePicture)
      formData.append("doc", this.state.uaeLicencePicture);
    else
      formData.append("doc", this.state.anotherLicencePicture);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.imageUploadApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/text_extract"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  submitHandler = async () => {
    if(this.state.validDrivingLicence && this.state.validToErr)
      return false;
    if(this.state.validLMVDrivingLicence && this.state.validTo2Err)
      return false;

    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("authToken"),
    };

    let formData = new FormData();
    formData.append("contact_detail[address]",this.state.address);
    formData.append("contact_detail[occupation]",this.state.occupation);
    formData.append("contact_detail[visa_profession]",this.state.visaProfession);
    formData.append("contact_detail[have_valid_light_motor_licence]","false");
    formData.append("contact_detail[second_have_valid_uae_licence]","false");
    formData.append("contact_detail[have_valid_dubai_driving_licence]",this.state.validDrivingLicence === "yes" ? "true" : "false");
    formData.append("contact_detail[have_valid_license_from_other_country]",this.state.validLMVDrivingLicence === "yes" ? "true" : "false");
    
    if(this.state.occupation === "Other"){
      formData.append("contact_detail[other_occupation]",this.state.otherOccupation);
    }

    if(this.state.visaProfession === "Other"){
      formData.append("contact_detail[other_visa_profession]",this.state.otherVisaProfession);
    }

    if(this.state.validDrivingLicence === "no" && this.state.validLMVDrivingLicence === "no"){
      formData.append("contact_detail[have_valid_license_from_other_country]", "true");
      formData.append("contact_detail[license_from_other_country_valid_from]", "2021-02-01");
      formData.append("contact_detail[license_from_other_country_valid_to]", "2022-07-30");
      formData.append("contact_detail[other_country_license_issuer_country]", "india");
      formData.append("contact_detail[other_country_licence_type]", "Car");
      formData.append("contact_detail[gear_type]","Manual");
    }

    if(this.state.validDrivingLicence === "yes"){
      formData.append("contact_detail[dubai_driving_licence_types]", this.state.licenceType);
      formData.append("contact_detail[dubai_driving_licence_valid_from]", moment(this.state.validFrom).format("DD-MM-YYYY"));
      formData.append("contact_detail[dubai_driving_licence_valid_to]", moment(this.state.validTo).format("DD-MM-YYYY"));
      formData.append("contact_detail[gear_type]", this.state.vehicleGear);
      
      if(this.state.uaeLicencePicture){
        formData.append("contact_detail[dubai_licence_image]",this.state.uaeLicencePicture);
      }
    }

    if(this.state.validLMVDrivingLicence === "yes"){
      formData.append("contact_detail[other_country_license_issuer_country]",this.state.country);
      formData.append("contact_detail[other_country_licence_type]",this.state.lvmLicenceType);
      formData.append("contact_detail[license_from_other_country_valid_from]",moment(this.state.validFrom2).format("DD-MM-YYYY"));
      formData.append("contact_detail[license_from_other_country_valid_to]",moment(this.state.validTo2).format("DD-MM-YYYY"));
      formData.append("contact_detail[gear_type]", this.state.lvmVehicleGear);

      if(this.state.anotherLicencePicture){
        formData.append("contact_detail[other_country_licence_image]",this.state.anotherLicencePicture);
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddAddressCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // JSON.stringify(httpBody)
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  loadAddresses = (token: string) => {
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllAddressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddressApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  txtInputLatWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputLatValue: text });
    },
    secureTextEntry: false,
  };

  txtInputLatMobileProps = {
    ...this.txtInputLatWebProps,
  };

  txtInputLatProps = this.isPlatformWeb()
    ? this.txtInputLatWebProps
    : this.txtInputLatMobileProps;

  txtInputLngWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputLngValue: text });
    },
    secureTextEntry: false,
  };

  txtInputLngMobileProps = {
    ...this.txtInputLngWebProps,
  };

  txtInputLngProps = this.isPlatformWeb()
    ? this.txtInputLngWebProps
    : this.txtInputLngMobileProps;

  txtInputAddressWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputAddressValue: text });
    },
    secureTextEntry: false,
  };

  txtInputAddressMobileProps = {
    ...this.txtInputAddressWebProps,
  };

  txtInputAddressProps = this.isPlatformWeb()
    ? this.txtInputAddressWebProps
    : this.txtInputAddressMobileProps;

  dropdownAddressTypeWebProps = {
    onChangeText: (value: string) => {
      console.log("--------addresstype value------=", value);
      this.setState({ addressTypeValue: value });
    },
  };

  dropdownAddressTypeMobileProps = {
    ...this.dropdownAddressTypeWebProps,
  };

  dropdownAddressTypeProps = this.isPlatformWeb()
    ? this.dropdownAddressTypeWebProps
    : this.dropdownAddressTypeMobileProps;

  handlePressAdd = () => {
    this.props.navigation.navigate("AddAddress");
  };

  handleSavePressed() {
    const header = {
      "Content-Type": configJSON.addAddressApiContentType,
      token: this.state.token,
    };

    const attrs = {
      latitude: this.state.txtInputLatValue,
      longitude: this.state.txtInputLngValue,
      address: this.state.txtInputAddressValue,
      address_type: this.state.addressTypeValue,
    };

    const httpBody = {
      address: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddAddressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAddressAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
