import React from "react";
// Customizable Area Start
import { Box, Container, Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
const officeImg = require("../assets/office.png");
const mapImg = require("../assets/map.png");
const officeBuildingImg = require("../assets/office-building.png");
// Customizable Area End

import ContactusController, { Props } from "./ContactusController";
// Customizable Area End

const itemBox = {
  boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
  borderRadius: "15px",
  backgroundColor: "#FFF",
  padding: "30px",
  // display:"flex",
} as const;

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getCmsApi()
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    return (
      //Merge Engine Start DefaultContainer
      <div className="contact-us common">
        <div className="first-section pb70" style={{ backgroundImage: this.state.CmsData.image ? `url(${this.state.CmsData.image})` : "url('./assets/contact-us.png')" }} >
          <div className="content">
            <h2 className="title"> {this.state.CmsData.title ? this.state.CmsData.title : "Contact us"}</h2>
            <h5 className="sub-title">
              {this.state.CmsData.sub_title ? this.state.CmsData.sub_title : "Get in touch and Let us know how we can help you"}
            </h5>
          </div>
          <Box
            position="absolute"
            right={{ xs: "20px", sm: "30px", md: "50px" }}
            bottom={{ xs: "20px", sm: "30px", md: "50px" }}
          >
            <img src={"assets/images/whatapp.png"} className="whatapp-img" />
          </Box>
        </div>
        <div className="second-section">
          <Box width="80%" margin="auto">
            <Box width="100%">
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Box style={itemBox}>
                    <Box
                      display={{ xl: "block", lg: "flex" }}
                      justifyContent={{ md: "space-between" }}
                    >
                      <div>
                        <Box display="flex">
                          <img
                            src={officeBuildingImg}
                            style={{ height: "65px", marginRight: "15px" }}
                          />
                          <div>
                            <h4 style={{ fontWeight: "bold" }}>
                              Head Office
                            </h4>
                            <p style={{ fontWeight: "bold" }}>Eco Driving</p>
                          </div>
                        </Box>
                        <Box mx={{ sm: 0, md: "82px" }}>
                          <p>
                            51 Business Tower, Business Bay 120864 Dubai,
                            United Arab Emirates
                          </p>
                          <p>Contact: +971 4 874 7580</p>
                          <p>info@ecodriving.ae</p>
                        </Box>
                      </div>
                      <Box width={{ xs: "100%", lg: "36%" }} px={{ sm: 0, md: 10, lg: "0px !important" }}>
                        <a
                          href="https://goo.gl/maps/fotg19tmhVFjKSdz5"
                          target="_blank"
                        >
                          <img
                            src={mapImg}
                            style={{ height: "260px", width: "100%" }}
                          />
                        </a>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box style={itemBox}>
                    <Box display="flex">
                      <img
                        src={officeImg}
                        style={{ height: "65px", marginRight: "15px" }}
                      />
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>Branch Office</h4>
                        <p style={{ fontWeight: "bold" }}>Eco Driving</p>
                      </div>
                    </Box>
                    <Box mx={{ sm: 0, md: 8 }}>
                      <p>Inside Lulu Hypermarket, Sheikh Rashid Road</p>
                      <p>Opposite Zabeel Park, Karama</p>
                      <p>Contact: 121345678</p>
                      <a
                        href="https://goo.gl/maps/SBvm6XrhyeTMVgaY6"
                        target="_blank"
                      >
                        <img
                          src={mapImg}
                          style={{ width: "100%", height: "260px" }}
                        />
                      </a>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box style={itemBox}>
                    <Box display="flex">
                      <img
                        src={officeImg}
                        style={{ height: "65px", marginRight: "15px" }}
                      />
                      <div>
                        <h4 style={{ fontWeight: "bold" }}>Branch Office</h4>
                        <p style={{ fontWeight: "bold" }}>Eco Driving</p>
                      </div>
                    </Box>
                    <Box mx={{ sm: 0, md: 8 }}>
                      <p>Inside Lulu Hypermarket, Sheikh Rashid Road</p>
                      <p>Opposite Zabeel Park, Karama</p>
                      <p>Contact: 121345678</p>
                      <a
                        href="https://goo.gl/maps/SBvm6XrhyeTMVgaY6"
                        target="_blank"
                      >
                        <img
                          src={mapImg}
                          style={{ width: "100%", height: "260px" }}
                        />
                      </a>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* </Container> */}
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
// Customizable Area End
