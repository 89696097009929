import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  phone: any;
  data: any[];
  CmsData: any,
  CmsDataTwo: any,
  CmsDataTwoOur_vision: any,
  CmsDataTwoOurMission: any,
  CmsDataTwoOurValues: any,
  CmsDataTwoWeCare: any,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AboutController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      phone: "",
      data: [],
      CmsData: [],
      CmsDataTwo: [],
      CmsDataTwoOur_vision: [],
      CmsDataTwoOurMission: [],
      CmsDataTwoOurValues: [],
      CmsDataTwoWeCare: [],
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.CmsDataApiText) {
          this.setState({
            CmsData: responseJson.data.attributes
          })
        }
        else if (apiRequestCallId === this.CmsDataApiDataText) {
          this.setState({
            CmsDataTwo: responseJson.data[0].attributes
          })
        }
        else if (apiRequestCallId === this.CmsDataApiDataOur_vision) {
          this.setState({
            CmsDataTwoOur_vision: responseJson.data[0].attributes
          })
        }
        else if (apiRequestCallId === this.CmsDataApiDataOur_Mission) {
          this.setState({
            CmsDataTwoOurMission: responseJson.data[0].attributes
          })
        }
        else if (apiRequestCallId === this.CmsDataApiDataOurValues) {
          this.setState({
            CmsDataTwoOurValues: responseJson.data[0].attributes
          })
        }
        else if (apiRequestCallId === this.CmsDataApiDataWeCare) {
          this.setState({
            CmsDataTwoWeCare: responseJson.data[0].attributes
          })
        }
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    this.parseApiCatchErrorResponse(errorReponse);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getCmsApi()
    this.getCmsDataApiOurvision()
    this.getCmsDataApi()
    this.getCmsDataApiOurMission()
    this.getCmsDataApiOurValuesFun()
    this.getCmsDataApiWeCareFun()
  }

  CmsDataApiText: any = null;
  CmsDataApiDataText: any = null;
  CmsDataApiDataOur_vision: any = null;
  CmsDataApiDataOur_Mission: any = null;
  CmsDataApiDataOurValues: any = null;
  CmsDataApiDataWeCare: any = null;

  getCmsApi = () => {
    const localToken = localStorage.getItem("authToken") as string
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CmsDataApiText = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_dashboard/page_titles/:id?name=About_us"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCmsDataApi = () => {
    const localToken = localStorage.getItem("authToken") as string
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CmsDataApiDataText = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_admin/section_two_listing?section=aboutDetails"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCmsDataApiOurvision = () => {
    const localToken = localStorage.getItem("authToken") as string
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CmsDataApiDataOur_vision = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_admin/section_two_listing?section=Our_vision"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCmsDataApiOurMission = () => {
    const localToken = localStorage.getItem("authToken") as string
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CmsDataApiDataOur_Mission = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_admin/section_two_listing?section=Our_Mission"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCmsDataApiOurValuesFun = () => {
    const localToken = localStorage.getItem("authToken") as string
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CmsDataApiDataOurValues = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_admin/section_two_listing?section=OurValues"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCmsDataApiWeCareFun = () => {
    const localToken = localStorage.getItem("authToken") as string
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CmsDataApiDataWeCare = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_admin/section_two_listing?section=WeCare"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
