import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  location:any;
  history:any;
  match:any;
}


export interface type{
  label: string,
  value:number
}

export interface S {
  // Customizable Area Start
  detail:any;
  namePrefix:any;
  fullName: string;
  phoneCode:any;
  phoneNumber: string;
  language: string;
  minute:any;
  hour:any;
  vehicleType:any;
  loading:boolean;
  readData: boolean;
  vehicleTypeData:Array<type>;
  languageOptions:any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class RequestCallbackController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  requestCallbackApiCallId:string = "";
  vehicleTypeApiCallId:string = "";
  getDetailApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      detail:"",
      namePrefix:"mr",
      fullName: "",
      phoneCode: "+971",
      phoneNumber: "",
      language: "",
      minute:"",
      hour:"",
      vehicleType:"",
      loading:false,
      readData:false,
      vehicleTypeData:[],
      languageOptions:[]
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.requestCallbackApiCallId) {
          if (!responseJson.errors && responseJson.status !== 500) {
            toast.info("Thank you contacting Eco drive. We have your details and we will get back to you!");
          }else {
            toast.error("Something went wrong");
            this.parseApiErrorResponse(responseJson);
          }
          this.setState({ loading: false });
          this.parseApiCatchErrorResponse(errorReponse);
        }else if (apiRequestCallId === this.vehicleTypeApiCallId) {
          if (!responseJson.errors) {
            let newArr:Array<type> = [];
            newArr = responseJson?.data.map((item:any,index:number)=>{
              return { label:item?.attributes?.name, value: index+1 }
            });
            this.setState({
              vehicleTypeData: newArr
            });
          }
        }else if(apiRequestCallId === this.getDetailApiCallId){
          if (responseJson) {
            this.setState({ detail: responseJson?.data?.attributes });
          }
          this.setState({ loading: false });      
        }
      }else {
        this.setState({ loading: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0,0);
    this.getVehicleType();
    this.getDetails();
    let data = this.props.location.state;
    if(data) {
      this.setState({ phoneCode: data?.code, phoneNumber: data?.phoneNumber, readData:true });
    }
  }
  
  getVehicleType = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    this.vehicleTypeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/vehicle_types"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  submitHandler = (): boolean => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      full_name: this.state.namePrefix + ". " + this.state.fullName,
      full_phone_number: this.state.phoneCode + this.state.phoneNumber,
      preferred_language: this.state.language,
      preferred_time: this.state.hour + ":" + this.state.minute,
      vehicle_type: this.state.vehicleType,
      status: 1
    };

    const data = {
      type:"email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestCallbackApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_request_callback/request_callbacks"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getDetails = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_dashboard/page_titles/:id?name=Request_callback"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}