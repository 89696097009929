Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.appointmentApiContentType = 'application/json'

exports.appointmentAPiEndPoint = 'appointment_management/availabilities'

exports.addAppointmentAPiMethod = 'POST'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentListAPiMethod = 'GET'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'

exports.bookAppointmentApiContentType = "application/json";
exports.bookAppointmentAPiEndPoint = "/book_appointment";
exports.bookAppointmentAPiMethod = "POST";
// Customizable Area End
