import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { SiTwitter } from "react-icons/si";
import { SiInstagram } from "react-icons/si";
import { SiFacebook } from "react-icons/si";
import { SiLinkedin } from "react-icons/si";
import { MdPhoneInTalk } from "react-icons/md";
import { GrChatOption } from "react-icons/gr";
import { AiOutlineMenu } from "react-icons/ai";
import { VscChromeClose } from "react-icons/vsc";
import RightNabar from "./RightNav";

function TopNav() {
  const [mobileMenu, setMobileMenu] = useState(false);
  // let codeList = [
  //   {value:"+971",label:"50"},
  //   {value:"+972",label:"52"},
  //   {value:"+973",label:"54"},
  //   {value:"+974",label:"55"},
  //   {value:"+975",label:"79"},
  // ]
  // const [countryCode,setCountryCode] = useState(codeList);
  // const [selectedCode,setSelectedCode] = useState("+971");
  // const [phoneNumber,setPhoneNumber] = useState("")
  // const [enableSave,setEnableSave] = useState(false);

  // const handleChange = (event) => {
  //   let val = event.target.value;
  //   setPhoneNumber(val)
  //   console.log(phoneNumber.split(''));
  //   let length=val.split('').length
  //   if(length === 9)
  //   {
  //       setEnableSave(true);
  //   }
  //   else{
  //     setEnableSave(false);
  //   }
  // }
  // const handleSelect = (event) => {
  //   let val = event.target.value;
  //   setSelectedCode(event.target.value);
  //   console.log(selectedCode)
  // }

  return (
    <>
      <HeaderWrapper className="header-wrapper">
        <HeaderTop>
          <div className="custom-container">
            <div className="row">
              <div className="col-12">
                <div className="headertop">
                  <div className="left-content">
                    <ul className="nav">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <SiTwitter />
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <SiLinkedin />
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <SiFacebook />
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <SiInstagram />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="right-content">
                    <ul className="nav">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <GrChatOption />
                          <span className="text">LIVE CHAT</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <MdPhoneInTalk />
                          <span className="text">600 595 959</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HeaderTop>
        <HeaderBottom>
          <div className="custom-container">
            <div className="row">
              <div className="col-12">
                <div className="bottomHeader">
                  <div className="bottomHeaderLeft">
                    <Link to="/" className="no-underline">
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                        className="logoSection"
                      >
                        <img
                          src="assets/logo/logo.svg"
                          className="Ecologo"
                          alt=""
                        />
                        {/* <h4>eco drive</h4> */}
                      </div>
                    </Link>
                    <div className="navs">
                      <ul>
                        <li>
                          <Link to="/AllCourses">All courses</Link>
                        </li>
                        <li>
                          <Link to="/EmailAccountLoginBlock">
                            Student Login
                          </Link>
                        </li>
                        <li>
                          <Link to="/SpecialOffer">Special Offers</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <RightNabar />
                </div>
              </div>
            </div>
          </div>
        </HeaderBottom>
      </HeaderWrapper>
      <MobileMenu className="mobile-menu">
        {/* {!mobileMenu ? */}
        <MobileHeader className="mobile-header">
          <Link to="/" onClick={() => setMobileMenu(false)}>
            {" "}
            <img src="assets/logo/logo.svg" alt="" />
          </Link>
          {!mobileMenu && (
            <AiOutlineMenu
              onClick={() => setMobileMenu(true)}
              style={{ cursor: "pointer" }}
            />
          )}
        </MobileHeader>
        {/* : */}
        {/* {mobileMenu && */}
        <MobilePanel
          className="side-panel"
          style={{ width: mobileMenu ? "215px" : "0px" }}
        >
          <div className="close-panel-wrapper">
            <VscChromeClose
              className="close-panel"
              style={{ display: mobileMenu ? "block" : "none" }}
              onClick={() => setMobileMenu(false)}
            />
          </div>
          <ul className="nav-panel-items">
            <li>
              <Link onClick={() => setMobileMenu(false)} to="/AllCourses">
                All courses
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setMobileMenu(false)}
                to="/EmailAccountLoginBlock"
              >
                Student Login
              </Link>
            </li>
            <li>
              <Link onClick={() => setMobileMenu(false)} to="/SpecialOffer">
                Special Offers
              </Link>
            </li>
            <li>
              <Link onClick={() => setMobileMenu(false)} to="/RequestCallback">
                Request Callback
              </Link>
            </li>
          </ul>
          {/* <RightNabar /> */}
        </MobilePanel>
        {/* }  */}
      </MobileMenu>
    </>
  );
}

// styles

const HeaderWrapper = styled.div``;
const MobileMenu = styled.div``;
const MobileHeader = styled.div``;

const HeaderTop = styled.section`
  height: 60px;
`;

const HeaderBottom = styled.header`
  position: absolute;
  top: 100px;
  width: 100%;
  z-index: 999;
  .Ecologo {
    height: 60px;
    max-width: 196px;
    width: 100%;
    max-height: 80px;
  }
`;

const MobilePanel = styled.div``;

export default TopNav;
